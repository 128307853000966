import classNames from 'classnames/bind';
import styles from './faq.module.scss';
import { Accordion, Container, Title } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation, useLocalization } from '@planity/localization';

export function Faq({ translationKey = 'landing.faq', pageKey = 'landing' }) {
	useStyles(styles);
	const { t } = useTranslation();
	const { locale } = useLocalization();

	const data = getData({ pageKey, translationKey, locale, t });

	const classes = classNames.bind(styles)({
		faq: true,
		hasGreyBackground: locale !== 'nl-BE' ? true : false
	});

	return (
		<div className={classes}>
			<Container size='small' noStupidMarginBottom>
				<Title
					label={t(`${pageKey}.subtitle.faq`)}
					title={t(`${pageKey}.headline.faq`)}
					align='center'
				/>

				<div className={styles.accordions}>
					{data &&
						Object.values(data).map(({ question, answer }, i) => (
							<Accordion key={i} title={question}>
								{Object.values(answer || {}).map((item, index) => (
									<p
										className={styles.answer}
										dangerouslySetInnerHTML={{ __html: item }}
										key={index}
									/>
								))}
							</Accordion>
						))}
				</div>
			</Container>
		</div>
	);
}

function getData({ pageKey, translationKey, locale, t }) {
	const localeKey = locale === 'fr-BE' ? 'BE' : 'FR';
	switch (pageKey) {
		case 'landing':
			return t(translationKey, {
				tel: t(
					`${pageKey}.FaqTel${
						locale === 'fr-BE' || locale === 'nl-BE' ? 'BE' : 'FR'
					}`
				),
				url1: t(`${pageKey}.FaqUrl1${localeKey}`),
				url2: t(`${pageKey}.FaqUrl2${localeKey}`),
				url3: t(`${pageKey}.FaqUrl3${localeKey}`),
				url4: t(`${pageKey}.FaqUrl4${localeKey}`),
				url5: t(`${pageKey}.FaqUrl5${localeKey}`)
			});
		default:
			return t(translationKey);
	}
}
