import React, { useMemo } from 'react';
import {
	businessSortedWebVisibleServiceSets,
	safeRead
} from '@planity/helpers';
import ServiceSet from './service_set';
import { BookAppointmentTitle } from '@planity/components';
import { USER_CHOSE_STEP_SERVICE } from '../events';

export default function AppointmentStepsServices({
	business,
	dispatch,
	redirectTo,
	highlightedServices,
	globalSearch,
	isDark,
	serviceSetsWhitelist,
	servicesWhitelist,
	servicesNotCollapsed
}) {
	const rawServices = businessSortedWebVisibleServiceSets(business);
	const services = useMemo(
		() => filterServices(rawServices, serviceSetsWhitelist, servicesWhitelist),
		[rawServices, serviceSetsWhitelist, servicesWhitelist]
	);
	const nServicesToDisplay = useMemo(() => {
		const servicesToDisplay =
			services?.filter(it => it?.children?.length > 0) || [];
		return servicesToDisplay.length;
	}, [services]);
	//Check if search was about a category
	const categoryName = safeRead(globalSearch, ['category', 'name']);

	return (
		<div>
			<div
				itemProp={'hasOfferCatalog'}
				itemScope
				itemType={'https://schema.org/OfferCatalog'}
			>
				{/* highlightedServices: services corresponding to the search are highlighted */}
				{highlightedServices.length ? (
					<BookAppointmentTitle
						isHighlighted={true}
						text={'business.searchResult'}
					>
						&nbsp;{categoryName}
					</BookAppointmentTitle>
				) : null}
				{highlightedServices &&
					highlightedServices.map((serviceSet, i) => (
						<ServiceSet
							business={business}
							dispatch={({ id }) =>
								dispatch(USER_CHOSE_STEP_SERVICE, { serviceId: id })
							}
							initCollapseMobile={false}
							key={serviceSet.id}
							redirectTo={redirectTo}
							serviceSet={serviceSet}
							setIndex={i}
						/>
					))}

				{/* All services */}
				{highlightedServices.length ? (
					<BookAppointmentTitle text={'business.allSearch'} />
				) : null}
				{services.map((serviceSet, i) => (
					<ServiceSet
						business={business}
						dispatch={({ id }) =>
							dispatch(USER_CHOSE_STEP_SERVICE, {
								serviceId: id
							})
						}
						initCollapseMobile={nServicesToDisplay > 1}
						isDark={isDark}
						key={serviceSet.id}
						redirectTo={redirectTo}
						serviceSet={serviceSet}
						servicesNotCollapsed={servicesNotCollapsed}
						setIndex={i}
					/>
				))}
			</div>
		</div>
	);
}

function filterServices(rawServices, serviceSetsWhitelist, servicesWhitelist) {
	const serviceSetWithoutHidden = rawServices.filter(
		set => set.id !== 'hiddenServices'
	);
	if (
		!serviceSetWithoutHidden ||
		(!serviceSetsWhitelist && !servicesWhitelist)
	) {
		return serviceSetWithoutHidden;
	}
	let filtered = serviceSetWithoutHidden.map(set => ({
		...set,
		children: (set.children || []).filter(service => {
			return !servicesWhitelist || servicesWhitelist.includes(service.id);
		})
	}));
	if (serviceSetsWhitelist) {
		filtered = filtered.filter(set => serviceSetsWhitelist.includes(set.id));
	}
	return filtered.filter(set => !!set.children.length);
}
