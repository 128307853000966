import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
	useLocalizedRoutes,
	useTranslation,
	useLocalization
} from '@planity/localization';
import { AlgoliaSearch } from 'planity-website/app/components/data';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './business_tags.module.scss';
import { Card } from '../../../uikit';
import { buildSEOTags, getSEOTagsScores } from './helpers';
import credentials from '@planity/credentials';

const BusinessTagsContent = ({
	business,
	crumbs,
	parentPlace,
	place,
	tags
}) => {
	const { t } = useTranslation();
	const { routes } = useLocalizedRoutes();
	const { language } = useLocalization();
	useStyles(styles);

	const seoTagsScores = useMemo(() => {
		return getSEOTagsScores({
			brand: business.brand,
			countryCode: business.countryCode,
			services: business.services,
			tags
		});
	}, [business.countryCode, business.services, tags]);

	if (!seoTagsScores) {
		return null;
	}

	return (
		<AlgoliaSearch
			attributesToHighlight={[]}
			filters={[
				'depth:1',
				...seoTagsScores.map(({ tagId }) => `objectID:${tagId}`)
			].join(' OR ')}
			hitsPerPage={200}
			index={credentials.CATEGORIES_INDEX}
			localizeResults
		>
			{({ data: categories }) => {
				if (!categories) {
					return null;
				}
				const seoTags = buildSEOTags({
					categories,
					countryCode: business.countryCode,
					crumbs,
					language,
					parentPlace,
					place,
					routes,
					seoTagsScores
				});

				if (seoTags.length === 0) {
					return null;
				}

				return (
					<div className={styles.container}>
						<Card title={t('business.labels.tagsDescription')}>
							<div className={styles.tags}>
								{seoTags.map(tag => (
									<Link key={tag.tagId} to={tag.path} className={styles.tag}>
										<span>{tag.name}</span>
									</Link>
								))}
							</div>
						</Card>
					</div>
				);
			}}
		</AlgoliaSearch>
	);
};

export const BusinessTags = ({ business, crumbs, parentPlace, place }) => {
	return (
		<AlgoliaSearch
			attributesToHighlight={[]}
			hitsPerPage={200}
			index={credentials.TAGS_INDEX}
			localizeResults
		>
			{({ data: tags }) => {
				if (!tags) {
					return null;
				}

				return (
					<BusinessTagsContent
						business={business}
						crumbs={crumbs}
						parentPlace={parentPlace}
						place={place}
						tags={tags}
					/>
				);
			}}
		</AlgoliaSearch>
	);
};
