import { Component } from 'react';
import Media from 'react-media';

export default function () {
	if (process.env.WIDGET) {
		return class Media extends Component {
			state = {
				matches: false,
				query: this.props.query
			};

			debouncedEventListener(fn, delay) {
				let time;
				return function (...args) {
					if (time) {
						clearTimeout(time);
					}
					time = setTimeout(() => {
						fn(...args);
						time = null;
					}, delay);
				};
			}

			componentDidMount() {
				this.setQuery();
				const setQueryDebounce = this.debouncedEventListener(
					() => this.setQuery(),
					200
				);
				window.addEventListener('resize', setQueryDebounce);
			}

			setQuery() {
				let [{ offsetWidth: container }] = document.querySelectorAll(
					'#planity, #planity-account, #planity-appointment, #planity-gift-voucher'
				);
				const { query } = this.state;
				const mqNumber = query.match(/\d+/);
				if (
					(query.includes('max') && container <= mqNumber) ||
					(query.includes('min') && container >= mqNumber)
				) {
					this.setState({ matches: true });
				} else {
					this.setState({ matches: false });
				}
			}

			render() {
				const { matches } = this.state;
				return this.props.children(matches);
			}
		};
	} else {
		return Media;
	}
}
