import React from 'react';
import { Localize } from '@planity/components';
import { isOriginalQuantityZeroOrUndefined } from '@planity/helpers';

export const EligibleCuresList = ({ eligibleCures, styles }) => {
	return (
		<>
			{eligibleCures.map(cure => (
				<div className={styles.cureItem} key={cure.id}>
					<span className={styles.cureName}>{cure.cureName}</span>
					<span className={styles.remainingQuantityTitle}>
						{'-'} <Localize text='myAccount.cures.remainingQuantity.title' />
					</span>
					<span className={styles.remainingQuantityLabel}>
						{isOriginalQuantityZeroOrUndefined(cure.originalQuantity) ? (
							cure.quantity
						) : (
							<Localize
								args={{
									originalQuantity: cure.originalQuantity,
									quantity: cure.quantity
								}}
								text={'myAccount.cures.remainingQuantity.label'}
							/>
						)}
					</span>
				</div>
			))}
		</>
	);
};
