import { useEffect, useReducer } from 'react';
import { availabilitiesShards, masterShard, publicShard } from './firebase';
import hash from 'object-hash';

export function useFirebaseSubscription(params) {
	const [state, dispatch] = useReducer(subscriptionReducer, {
		data: undefined,
		error: null,
		isLoading: false
	});
	useEffect(() => {
		if (!params || !params.path) {
			dispatch({
				type: 'RESET_STATE'
			});
		}
		if (params.path) {
			dispatch({
				type: 'DATA_LOADING'
			});
			const ref = refFromParams(params);
			const dataListener = snapshot =>
				dispatch({
					type: 'DATA_CHANGE',
					data: snapshot.val()
				});
			const errorListener = error => {
				console.error(error);
				dispatch({ type: 'ERROR', error });
			};
			ref.on('value', dataListener, errorListener);
			return () => {
				dispatch({ type: 'QUERY_CHANGE' });
				ref.off('value', dataListener);
			};
		}
	}, [hash(params)]);
	return [state.data, state.error, state.isLoading];
}

function subscriptionReducer(state, action) {
	switch (action.type) {
		case 'DATA_LOADING':
			return {
				...state,
				error: null,
				isLoading: true
			};
		case 'QUERY_CHANGE':
			return {
				...state,
				error: null,
				isLoading: false
			};
		case 'DATA_CHANGE':
			return {
				...state,
				data: action.data,
				error: null,
				isLoading: false
			};
		case 'ERROR':
			return {
				...state,
				error: action.error,
				isLoading: false
			};
		case 'RESET_STATE':
			return {
				data: undefined,
				error: null,
				isLoading: false
			};
		default:
			throw 'UNKNOWN_ACTION_ERROR';
	}
}

function refFromParams({
	path,
	orderByKey,
	orderByChild,
	orderByValue,
	startAt,
	equalTo,
	endAt,
	limitToFirst,
	limitToLast,
	shard
}) {
	const firebase =
		shard === 'availabilities1'
			? availabilitiesShards['1']
			: shard === 'availabilities2'
			? availabilitiesShards['2']
			: shard === 'public'
			? publicShard
			: masterShard;
	let ref = firebase.database().ref(path);
	if (orderByKey) {
		ref = ref.orderByKey();
	} else if (orderByChild) {
		ref = ref.orderByChild(orderByChild);
	} else if (orderByValue) {
		ref = ref.orderByValue(orderByValue);
	}
	if (isValidLimit(startAt)) ref = ref.startAt(startAt);
	if (isValidLimit(equalTo)) ref = ref.equalTo(equalTo);
	if (isValidLimit(endAt))
		ref = ref.endAt(typeof endAt === 'string' ? `${endAt}\uf8ff` : endAt);
	if (limitToFirst) ref = ref.limitToFirst(limitToFirst);
	if (limitToLast) ref = ref.limitToLast(limitToLast);
	return ref;
}

function isValidLimit(limit) {
	return limit || limit === 0 || limit === '';
}
