import { parseISO } from 'date-fns';

/**
 *
 * @param appointment
 * @param {Object} sequence appointment sequence if appointment's sequence has been changed for an array
 * @param createdAt creation of payment
 * @param totalPrice
 * @param hasWaitingListActivated
 * @param fromWaitingListMail
 * @return {{createdAt, veventId: string, sequence, last4: (*|null), totalPrice, chargeId: (*|null), paymentMethodId: null, start: Date, isPrePayment}}
 */
export const userVeventToDeleteConstructor = ({
	appointment,
	sequence,
	totalPrice,
	paymentCreatedAt,
	hasWaitingListActivated = false,
	fromWaitingListMail = false
}) => ({
	bm: appointment.bm,
	bookedBy: appointment.bookedBy,
	bookedFor: appointment.bookedFor,
	veventId: Object.keys(sequence || appointment.sequence || {}).join(','),
	start: parseISO(appointment.start),
	sequence: sequence || appointment.sequence,
	isPrePayment: appointment.isPrePayment,
	chargeId: (appointment && appointment.chargeId) ?? null,
	paymentMethodId: appointment.paymentMethod ?? null,
	last4: appointment.last4 ?? null,
	paymentCreatedAt,
	totalPrice,
	hasWaitingListActivated,
	fromWaitingListMail
});
