import React from 'react';
import styles from './cure_header.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useLocalization, useTranslation } from '@planity/localization';
import { isOriginalQuantityZeroOrUndefined } from '@planity/helpers';
import { format as formatDate } from 'date-fns';
import { CloudinaryPicture, Localize } from '@planity/components';

export function CureHeader({
	boughtAt,
	business,
	linkToBusiness,
	expires,
	quantity,
	originalQuantity
}) {
	useStyles(styles);
	const { t } = useTranslation();
	const { dateLocale } = useLocalization();

	const formattedBoughtAt = formatDate(boughtAt, 'd MMMM yyyy', {
		locale: dateLocale
	});
	const formattedExpiresAt = expires
		? formatDate(expires, 'd MMMM yyyy', { locale: dateLocale })
		: null;

	return (
		<div className={styles.cureHeader}>
			<div
				className={styles.image}
				id='cure-product-image'
				onClick={() => linkToBusiness(business)}
			>
				{business?.pictures?.[0] ? (
					<CloudinaryPicture
						alt={`image-${business.name}`}
						src={business?.pictures?.at(0)}
						transformation='t_m_main'
					/>
				) : (
					<div className={styles.noPicture}></div>
				)}
			</div>
			<div className={styles.cureLeftInfo}>
				<div>
					<div className={styles.label}>
						<Localize text='myAccount.cures.purchaseDate' />
					</div>
					<div className={styles.value}>
						<span id='cure-header-boughtAt'>{formattedBoughtAt}</span>
					</div>
				</div>
				<div>
					<div className={styles.label}>
						<Localize text='myAccount.orders.expirationDate' />
					</div>
					<div className={styles.value}>
						<span id='cure-expiresAt'>{`${
							formattedExpiresAt ? formattedExpiresAt : '-'
						}`}</span>
					</div>
				</div>
				<div>
					<div className={styles.label}>
						<Localize text='myAccount.cures.remainingQuantity.header.title' />
					</div>
					<div className={styles.value}>
						<span id='cure-expiresAt'>
							{isOriginalQuantityZeroOrUndefined(originalQuantity) ? (
								quantity
							) : (
								<Localize
									args={{
										originalQuantity: originalQuantity,
										quantity: quantity
									}}
									text={'myAccount.cures.remainingQuantity.label'}
								/>
							)}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
}
