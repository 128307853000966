export const IS_PREPAYMENT = 'isPrepayment';
export const IS_DEPOSIT = 'isDeposit';
export const IS_ONLINE_PAYMENT = 'isOnlinePayment';
export const NO_ONLINE_PAYMENT = 'noOnlinePayment';

/**
 * Give the kind of online payment options the business uses: prepayment, deposit...
 * @param business {Object} the business, as it is in firebase
 * @return {"isPrepayment"|"isDeposit"|"isOnlinePayment"|"noOnlinePayment"}
 */
export const businessOnlinePaymentStatus = business => {
	if (!business?.modules?.onlinePayment) return NO_ONLINE_PAYMENT;

	const serviceCategories = business?.services ?? {};
	const services = Object.values(serviceCategories).reduce((acc, category) => {
		const children = category?.children ?? {};
		return acc.concat(Object.values(children));
	}, []);
	const oneServiceHasOnlinePayment = services.some(x => x.onlinePayment);
	if (!oneServiceHasOnlinePayment) return NO_ONLINE_PAYMENT;
	const { prePayment, isDeposit } = business.settings.onlinePayment ?? {};

	if (prePayment && isDeposit) return IS_DEPOSIT;
	if (prePayment) return IS_PREPAYMENT;
	return IS_ONLINE_PAYMENT;
};
