import React, { useCallback, useMemo } from 'react';
import Helmet from 'react-helmet';
import { buildCompactPages } from './build_compact_pages';
import config from '@planity/credentials';
import classNames from 'classnames/bind';
import styles from './filter.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Link, useHistory } from 'react-router-dom';
import { Localize } from '@planity/components';
import { Button, Icon } from '@planity/ui';
import { scrollTo } from '@planity/helpers';

const classes = classNames.bind(styles);

export function Pagination({
	currentPage,
	pagesCount,
	getPageURL,
	setPageSearch
}) {
	useStyles(styles);
	if (pagesCount < 2) {
		return null;
	}
	const history = useHistory();

	const pages = useMemo(
		() => buildCompactPages(currentPage, pagesCount),
		[currentPage, pagesCount]
	);
	const hasPrevPage = currentPage > 1;
	const hasNextPage = currentPage < pagesCount;

	/**
	 * @param n {number}
	 */
	const navigateTo = useCallback(
		n => {
			const pageUrl = getPageURL(currentPage + n);
			history.push(pageUrl);
			scrollTo({ top: 0 });
			setPageSearch(currentPage + n);
		},
		[currentPage, getPageURL, setPageSearch]
	);

	return (
		<div className={classes('filter')}>
			<ol className={styles.pagination}>
				<Helmet>
					{hasPrevPage && (
						<link
							href={`https://www.${config.HOST}${getPageURL(currentPage - 1)}`}
							rel={'prev'}
						/>
					)}
					{hasNextPage && (
						<link
							href={`https://www.${config.HOST}${getPageURL(currentPage + 1)}`}
							rel={'next'}
						/>
					)}
				</Helmet>
				<div className={styles.links}>
					<div
						className={classes({ pointer: hasPrevPage })}
						onClick={() => hasPrevPage && navigateTo(-1)}
					>
						<Icon icon='ArrowLeft' size={'medium'} />
					</div>
					{pages?.map(({ page, isSeparator }, i) => (
						<PaginationLink
							content={isSeparator ? '…' : page}
							isCurrent={currentPage === page}
							isSeparator={isSeparator}
							key={i}
							url={currentPage !== page && !isSeparator && getPageURL(page)}
						/>
					))}
					<div
						className={classes({ pointer: hasNextPage })}
						onClick={() => hasNextPage && navigateTo(1)}
					>
						<Icon icon='ArrowRight' size={'medium'} />
					</div>
				</div>

				<div className={styles.buttonContainer}>
					<Button
						iconLeft={'ArrowLeft'}
						isDisabled={!hasPrevPage}
						label={<Localize text={'search.pagination.prevPage'} />}
						type='linked'
						onClick={() => hasPrevPage && navigateTo(-1)}
					/>
					<Button
						iconRight={'ArrowRight'}
						isDisabled={!hasNextPage}
						label={<Localize text={'search.pagination.nextPage'} />}
						type='linked'
						onClick={() => hasNextPage && navigateTo(1)}
					/>
				</div>
			</ol>
		</div>
	);
}

function PaginationLink({ url, content, isCurrent, isSeparator }) {
	return (
		<li className={classes({ separator: isSeparator, currentPage: isCurrent })}>
			{url ? <Link to={url}>{content}</Link> : content}
		</li>
	);
}
