import React from 'react';
import { Button } from '@planity/ui';
import classNames from 'classnames/bind';
// TODO Really ?
import styles from '../authentication/authentication.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useFormHooks, Localize } from '@planity/components';
import { useTranslation } from '@planity/localization';
import { isNativeApp } from '@planity/webview';
import { useTheme } from '@planity/context';

export function MemberConnection({
	to,
	title,
	label,
	navigateLogState,
	id,
	isDark
}) {
	const { formValues } = useFormHooks();
	const { authBooking } = formValues;
	const classes = classNames.bind(styles)({
		auth: true
	});
	const { t } = useTranslation();
	const { isDarkThemeWidget } = useTheme();

	useStyles(styles);
	return (
		<div id='member-connect-comp' className={classes}>
			<span className={styles.or}>
				<Localize text={'myAccount.or'} />
			</span>

			{!authBooking && <h2 className={styles.title}>{title}</h2>}
			<Button
				id={id}
				isFullMobile
				type={`${isDarkThemeWidget ? 'tertiaryDark' : 'tertiary'}`}
				size='large'
				label={label}
				onClick={navigateLogState}
				isDark={isDark}
			/>
			{!authBooking && isNativeApp && (
				<Button
					className={styles.cookies}
					id='manage-cookies-auth-button'
					isFullMobile
					isFullWidth
					label={t('footer.cookiesPolicy')}
					type='secondary'
					onClick={() => {
						// Didomi is loaded in html <script> tag
						// eslint-disable-next-line no-undef
						Didomi.preferences.show();
					}}
				/>
			)}
		</div>
	);
}
