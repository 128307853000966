import React, { Component, createRef, forwardRef } from 'react';
import Downshift from 'downshift';
import { colors, fontWeight } from '@planity/theme';
import { IconWrapper, Localize } from '@planity/components';
import { withFormFactor } from '../provider';
import {
	HEIGHT_HEADER_SEARCH_MOBILE,
	HEIGHT_INPUT_SEARCH_MOBILE
} from 'planity-website/app/style';

export default forwardRef(function AutocompleteWithRef(props, ref) {
	return <Autocomplete {...props} forwardedRef={ref} />;
});

const Autocomplete = withFormFactor(
	class RawAutocomplete extends Component {
		downshift = createRef();

		componentDidMount() {
			const { autoFocus, suggestOnFocus, keepOpen } = this.props;

			if (keepOpen || (autoFocus && suggestOnFocus)) {
				/**
				 * some browsers do not call the onFocus event when the input
				 * is focused with the autofocus attribute
				 */
				this.open();
			}
		}

		render() {
			const {
				query,
				suggestions,
				onSelect,
				onQueryChange,
				className,
				getSuggestionKey,
				getSuggestionName,
				renderSuggestion,
				inputId,
				placeholder,
				selectedItem,
				autoFocus,
				onPress,
				isDisabled,
				suggestOnFocus,
				forwardedRef,
				renderIcon,
				iconBlank,
				iconFilled,
				overChargedSuggestionWrapper,
				overChargedIcon,
				formFactor,
				setDownshiftIsOpen,
				displayEmptySuggestionsHeader,
				keepOpen,
				textFilled,
				userQueries
			} = this.props;
			const isDesktop = !(formFactor === 'phone' || formFactor === 'tablet');
			return (
				<Downshift
					inputValue={query}
					itemToString={suggestion =>
						suggestion ? getSuggestionName(suggestion) : ''
					}
					ref={this.downshift}
					render={({
						getInputProps,
						getItemProps,
						highlightedIndex,
						isOpen
					}) => {
						if (typeof setDownshiftIsOpen === 'function') {
							setDownshiftIsOpen(
								keepOpen || (isOpen && (suggestions || []).length > 0)
							);
						}
						return (
							<div
								css={[styles.autocomplete, className]}
								onClick={
									onPress
										? e => {
												if (e && e.preventDefault) e.preventDefault();
												if (e && e.stopPropagation) e.stopPropagation();
												onPress();
										  }
										: undefined
								}
							>
								<div css={[styles.icon, overChargedIcon]}>
									{!!renderIcon && renderIcon()}
								</div>
								{iconBlank && iconFilled && (
									<div
										style={{
											position: 'absolute',
											zIndex: 999,
											top: 16,
											right: 0,
											left: 'auto',
											width: 'auto',
											height: 24,
											padding: '0 16px'
										}}
									>
										{!isOpen || !query ? (
											iconBlank ? (
												<IconWrapper
													code={iconBlank.code || null}
													color={iconBlank.color || 'black'}
													onClick={iconBlank.onClick || null}
												/>
											) : (
												iconFilled && (
													<IconWrapper
														code={iconFilled.code || null}
														color={iconFilled.color || 'black'}
														onClick={iconFilled.onClick || null}
													/>
												)
											)
										) : (
											textFilled && (
												<span
													css={styles.textFilled}
													onClick={textFilled.onClick || null}
												>
													<Localize text={'search.erase'} />
												</span>
											)
										)}
									</div>
								)}
								<input
									css={[
										styles.input,
										typeof renderIcon === 'function' &&
											!!renderIcon() && { paddingLeft: 48 }
									]}
									{...getInputProps(
										Object.assign({
											onChange: e => onQueryChange(e.currentTarget.value)
										})
									)}
									autoCapitalize={'none'}
									autoComplete={'new-password'}
									autoCorrect={'off'}
									autoFocus={autoFocus}
									disabled={isDisabled}
									id={inputId}
									placeholder={placeholder}
									ref={forwardedRef}
									onFocus={suggestOnFocus ? this.open : undefined}
									onKeyDown={e => this.onKeyDown(e, suggestions)}
								/>
								{!query && userQueries}
								{(keepOpen || (isOpen && (suggestions || []).length !== 0)) && (
									<div
										css={[
											styles.dropdown,
											overChargedSuggestionWrapper,
											isDesktop ? styles.dropdownDesktop : null,
											displayEmptySuggestionsHeader &&
												query !== '' &&
												styles.dropdownQuery,
											!userQueries && styles.noHeight
										]}
									>
										{displayEmptySuggestionsHeader && query === '' && (
											<div css={styles.suggestionTextMobile}>
												<Localize text={'category.frequentSearches'} />
											</div>
										)}
										{(suggestions || []).map((suggestion, i) => {
											const renderItem = renderSuggestion({
												suggestion,
												isHighlighted: highlightedIndex === i,
												query,
												suggestions
											});
											return renderItem ? (
												<div
													key={getSuggestionKey(suggestion)}
													{...getItemProps({ item: suggestion })}
													css={styles.suggestion}
												>
													{renderItem}
												</div>
											) : null;
										})}
									</div>
								)}
							</div>
						);
					}}
					selectedItem={selectedItem}
					onChange={suggestion => onSelect(suggestion)}
				/>
			);
		}
		onKeyDown = (event, data) => {
			event.stopPropagation();
			const { onSelect } = this.props;
			switch (event.key) {
				case 'Enter':
					if (
						Array.isArray(data) &&
						data.length &&
						typeof onSelect === 'function'
					)
						onSelect(data[0]);
			}
		};

		open = () => {
			const node = this.downshift.current;
			if (node && node.openMenu) {
				node.openMenu();
			}
		};
	}
);

const styles = {
	autocomplete: {
		position: 'relative'
	},
	dropdown: {
		position: 'absolute',
		left: 0,
		width: '100%',
		zIndex: 1001,
		backgroundColor: 'var(--grey-100)'
	},
	noHeight: {
		height: `calc(100vh - (${HEIGHT_HEADER_SEARCH_MOBILE}px + ${HEIGHT_INPUT_SEARCH_MOBILE}px))`
	},
	dropdownQuery: {
		top: 54
	},
	dropdownDesktop: {
		marginTop: '0px !important',
		maxHeight: '420px !important', // base : 42 * number suggestion
		overflowY: 'scroll !important'
	},
	icon: {
		zIndex: 4,
		position: 'absolute',
		left: 14,
		top: 12
	},
	input: {
		'fontFamily': 'inherit',
		'color': 'inherit',
		'fontWeight': fontWeight.inherit,
		':disabled': {
			backgroundColor: 'var(--white) !important',
			opacity: '1 !important'
		}
	},
	suggestion: {
		top: 48,
		minHeight: 42,
		display: 'flex',
		alignItems: 'center'
	},
	suggestionTextMobile: {
		padding: '12px 16px 4px',
		display: 'flex',
		alignItems: 'center',
		font: 'var(--medium) var(--body-4)',
		color: 'var(--grey-600)'
	},
	suggestionTextDesktop: {
		height: 42,
		minHeight: 42,
		padding: '0 14px',
		display: 'flex',
		alignItems: 'center',
		backgroundColor: 'var(--grey-100)',
		fontSize: 13,
		fontWeight: 'bold',
		color: colors.black.text,
		borderBottomColor: colors.grey.lightPeach,
		borderBottomWidth: 1,
		borderBottomStyle: 'solid'
	},
	textFilled: {
		font: 'var(--medium) var(--body-4)',
		color: 'var(--grey-600)'
	}
};
