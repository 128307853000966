import React from 'react';
import { Link, Localize } from '@planity/components';
import { useTranslation } from '@planity/localization';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './policies.module.scss';
import { isNativeApp, sendToUserApp } from '@planity/webview';

export function Policies({ locale, isEshop, isGiftVoucher }) {
	useStyles(styles);
	const { t } = useTranslation();

	const links = {
		SGV: t('links.eshopCGV'),
		terms: t(
			locale === 'fr-BE'
				? 'onlinePayment.paymentPolicy.terms.linkBE'
				: 'onlinePayment.paymentPolicy.terms.link'
		),
		privacy: t(
			locale === 'fr-BE'
				? 'onlinePayment.paymentPolicy.privacy.linkBE'
				: 'onlinePayment.paymentPolicy.privacy.link'
		)
	};

	const handleClickLink = (e, link) => {
		e.preventDefault();
		sendToUserApp({
			type: 'SHOW_MODAL',
			payload: link
		});
	};

	return (
		<p className={styles.text}>
			{(isEshop || isGiftVoucher) && (
				<>
					<Localize text={'eshop.consent.iHerebyDeclare'}></Localize>
					<Link
						href={isNativeApp ? null : links.SGV}
						localizedText={'eshop.consent.termsAndConditions'}
						target='_blank'
						onClick={isNativeApp ? e => handleClickLink(e, links.SGV) : null}
					/>
					&nbsp;
				</>
			)}
			<Localize text='onlinePayment.paymentPolicy.text.part1' />
			<Link
				href={isNativeApp ? null : links.terms}
				localizedText='onlinePayment.paymentPolicy.terms.text'
				target='_blank'
				onClick={isNativeApp ? e => handleClickLink(e, links.terms) : null}
			/>
			<Localize text='onlinePayment.paymentPolicy.text.part2' />
			<Link
				href={isNativeApp ? null : links.privacy}
				localizedText='onlinePayment.paymentPolicy.privacy.text'
				target='_blank'
				onClick={isNativeApp ? e => handleClickLink(e, links.privacy) : null}
			/>
		</p>
	);
}
