import React from 'react';
import classNames from 'classnames/bind';
import styles from './account_appointment.module.scss';
import {
	AppointmentDate,
	Button,
	AddToCalendar,
	Icon,
	Image,
	StepDescription,
	AppointmentSequence,
	useModal,
	WaitingList,
	Badge
} from '@planity/ui';
import {
	computeFees,
	formatPhoneNumber,
	getFinalFees,
	invokeLambda,
	PAYMENT_TYPE_CARD,
	noop,
	sequenceWithPaymentCanBeCancelled,
	userVeventToDeleteConstructor
} from '@planity/helpers';
import { breakpoints } from '@planity/theme';
import credentials from '@planity/credentials';
import { FeesDescription, PriceDescription } from '@planity/ui';
import { uglyPriceComputation } from '@planity/ui/shared/utils';
import { isNativeApp, sendToUserApp } from '@planity/webview';
import {
	useTranslation,
	useLocalization,
	getLocalizedBasePath,
	DEFAULT_COUNTRY_CODE,
	getBusinessWebsiteCountryCode
} from '@planity/localization';
import {
	Localize,
	useAppointmentSource,
	useErrorMessages
} from '@planity/components';
import { ReviewBlock } from '@planity/components/my_account_page/appointment_preview/review';
import { differenceInMinutes } from 'date-fns';
import APPOINTMENT_SOURCE from '@planity/components/satisfaction_measurement/appointment_source';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useStripeFees } from '@planity/components/app_settings/stripeFeesProvider';
import {
	CountryCodeAlertModalContent,
	CountryCodeAlertModalButtons
} from '../../country_code_alert_modal';
import colors from '@planity/theme/colors';

const NOT_BOOKABLE_ERROR = 'myAccount.appointment.errors.NOT_BOOKABLE_ERROR';
const NOT_MOVABLE_ERROR = 'myAccount.appointment.errors.NOT_MOVABLE_ERROR';
const NOT_MOVABLE_ERROR_WITH_PHONE =
	'myAccount.appointment.errors.NOT_MOVABLE_ERROR_WITH_PHONE';
const NOT_MOVABLE_PREPAYMENT_ERROR =
	'myAccount.appointment.errors.NOT_MOVABLE_PREPAYMENT_ERROR';
const BOOKED_BY_PARENT_ONLINE_PAYMENT =
	'myAccount.appointment.errors.BOOKED_BY_PARENT_ONLINE_PAYMENT';

const transformations = {
	default: 'c_thumb,g_center,h_128,w_128',
	breakpoints: [
		{
			query: breakpoints.phoneQuery,
			transformation: 'c_thumb,g_center,h_128,w_128'
		},
		{
			query: breakpoints.tabletQuery,
			transformation: 'c_thumb,g_center,h_256,w_256'
		}
	]
};

export function AccountAppointment({
	isCompact,
	linkToBusiness,
	business,
	appointment,
	googleMapURL,
	isCancelled,
	wasJustCancelled,
	appointmentId,
	appointmentStart,
	myAccountLink,
	cancellationIsPending,
	cancelAppointment,
	canBeCancelled,
	isBookable,
	linkToBookAppointment,
	sequence,
	paidAmount,
	feesAmount,
	paymentCreatedAt,
	depositType,
	fromModalAddCard = false,
	isManyAppointment,
	isMobileDate,
	collapseAppointmentSteps,
	appointmentIndex,
	disableRating,
	disableOnlinePaymentInfo,
	bookingMethods
}) {
	useStyles(styles);
	const { pushMessage } = useErrorMessages();
	const { changeSource } = useAppointmentSource();
	const { t } = useTranslation();
	const { language, countryCode: localeCountryCode } = useLocalization();
	const hasLocation = googleMapURL || business?.address;
	const isPrePayment = appointment.isPrePayment;
	const totalPrice = uglyPriceComputation(appointment, business, t);
	const { allStripeFees } = useStripeFees();
	const { setModal } = useModal();
	const { bookedBy, bookedFor } = appointment;

	const { ENABLE_USER_PAYS_FEES } = credentials;

	const userPaysFees = !!(ENABLE_USER_PAYS_FEES && appointment.userPaysFees);
	/** if fromModalAddCard props is true it means this appointment preview is rendered via ModalAddCard component
	 * this appointment preview applies for appointments taken with imprint in the case we want to
	 * delete the credit card linked to these appointments we can calculate fees from current website
	 * localisation because cards displayed in my account page are linked by website locale (stripe
	 * account)
	 */
	const stripeFeesForImprint = getFinalFees(
		allStripeFees,
		localeCountryCode,
		PAYMENT_TYPE_CARD
	);

	const finalFees = fromModalAddCard
		? computeFees({
				amount: totalPrice || 0,
				stripeFees: stripeFeesForImprint
		  })
		: feesAmount;

	// userPaysFees is an amount in case the business has charged the user fees because of no show or late cancellation
	const fees = userPaysFees
		? userPaysFees === true
			? finalFees
			: userPaysFees
		: 0;

	const finalPaidAmount = fromModalAddCard ? totalPrice + fees : paidAmount;
	const getBookingMethod = () => {
		const hasBookingMethod = Object.keys(bookingMethods ?? {}).length > 0;
		if (!hasBookingMethod)
			return { bookingMethodName: null, bookingMethodSize: 0 };
		/**
		 * bookingMethodType: "giftVouchers" | "cures"
		 */
		const bookingMethodType = Object.keys(bookingMethods)[0];
		const bookingMethodSize = Object.keys(
			bookingMethods[bookingMethodType] ?? {}
		).length;
		const bookingMethodName =
			bookingMethodType?.indexOf('gift') > -1 ? 'Gift' : 'Cure';
		return { bookingMethodName, bookingMethodSize };
	};
	const handleOnBookAgainClick = async () => {
		let cancelOK;
		const businessCancellationDelay = business?.settings?.delays?.cancellation;
		const businessAllowsCancellation = businessCancellationDelay
			? differenceInMinutes(appointmentStart, new Date()) >
			  businessCancellationDelay
			: true;
		const isBookedByParent = appointment?.bookedBy;
		if (isBookedByParent && appointment?.paymentMethod && canBeCancelled) {
			return pushMessage({
				message: BOOKED_BY_PARENT_ONLINE_PAYMENT,
				type: 'error',
				createdAt: Date.now()
			});
		}

		if (isBookable) {
			try {
				if (!canBeCancelled) {
					// check if appointment is future and can be cancelled
					// appointment is past
					cancelOK = true;
				} else {
					if (appointment.paymentMethod) {
						// if business has onlinePayment, c'est dead on empêche de déplacer le RDV
						cancelOK =
							sequenceWithPaymentCanBeCancelled({
								sequence,
								start: appointmentStart
							}) && businessAllowsCancellation;
					} else {
						cancelOK = await invokeLambda('cancelAppointment', {
							checkOnly: true,
							start: appointmentStart,
							business
						});
					}
				}
				if (cancelOK) {
					changeSource(APPOINTMENT_SOURCE.BOOK_AGAIN.PROFILE);
					linkToBookAppointment({
						business: { ...business, isBooking: true },
						sequence: Object.keys(sequence || {}).map(id => sequence[id]),
						isDeposit: depositType,
						veventToDelete:
							canBeCancelled &&
							userVeventToDeleteConstructor({
								appointment,
								sequence,
								paymentCreatedAt,
								totalPrice: paidAmount || totalPrice + fees,
								hasWaitingListActivated: appointment.hasWaitingListActivated
							})
					});
				} else {
					if (appointment.isPrePayment && businessAllowsCancellation) {
						return pushMessage({
							message: NOT_MOVABLE_PREPAYMENT_ERROR,
							type: 'error',
							createdAt: Date.now()
						});
					}
					return pushMessage({
						message: !!business.phoneNumber
							? NOT_MOVABLE_ERROR_WITH_PHONE
							: NOT_MOVABLE_ERROR,
						args: {
							withLinkToPhoneNumber: !!business.phoneNumber,
							phoneNumber: formatPhoneNumber(business.phoneNumber),
							rawPhoneNumber: business.phoneNumber
						},
						type: 'error',
						createdAt: Date.now()
					});
				}
			} catch (e) {
				console.log(e);
			}
		} else {
			if (!canBeCancelled) {
				return pushMessage({
					message: NOT_BOOKABLE_ERROR,
					type: 'error',
					createdAt: Date.now()
				});
			}
			if (appointment.isPrePayment && businessAllowsCancellation) {
				return pushMessage({
					message: NOT_MOVABLE_PREPAYMENT_ERROR,
					type: 'error',
					createdAt: Date.now()
				});
			}
			return pushMessage({
				message: !!business.phoneNumber
					? NOT_MOVABLE_ERROR_WITH_PHONE
					: NOT_MOVABLE_ERROR,
				args: {
					phoneNumber: formatPhoneNumber(business.phoneNumber),
					rawPhoneNumber: business.phoneNumber,
					withLinkToPhoneNumber: !!business.phoneNumber
				},
				type: 'error',
				createdAt: Date.now()
			});
		}
	};

	const enableReviews =
		credentials.DISPLAY_REVIEWS &&
		!business?.settings?.desactivateReviews &&
		appointment?.key;

	const classes = classNames.bind(styles);

	const chargeId = appointment && appointment.chargeId;

	const redirectToBusinessLocale = business => {
		const localePath = getLocalizedBasePath({
			language: business.language,
			countryCode: business.countryCode
		});
		const validLocalePath = localePath === 'fr-FR' ? '' : `/${localePath}`;

		const newUrl = `${window.location.origin}${validLocalePath}/${business.slug}`;
		// Side effect should not happen in render. But this one seems safe
		if (isNativeApp)
			return sendToUserApp({
				type: '300_REDIRECTION',
				payload: {
					to: newUrl
				}
			});
		window.location.href = newUrl;
	};

	const handleClick = business => {
		const shouldSuggestRedirection =
			localeCountryCode !== getBusinessWebsiteCountryCode(business);
		if (shouldSuggestRedirection) {
			setModal({
				content: (
					<CountryCodeAlertModalContent
						businessCountryCode={business.countryCode || DEFAULT_COUNTRY_CODE}
						localeCountryCode={localeCountryCode}
					/>
				),
				footerButtons: (
					<CountryCodeAlertModalButtons
						onCancelClick={noop}
						onSubmitClick={() => redirectToBusinessLocale(business)}
					/>
				),
				hasCloseBtn: false
			});

			return;
		}

		linkToBusiness(business, appointmentId, appointment.businessId);
	};
	/**
	 * bookingMethodName: 'Gift' | 'Cure' | null
	 * bookingMethodSize: number
	 * */
	const { bookingMethodName, bookingMethodSize } = getBookingMethod();
	return (
		<div
			className={classes({
				appointment: true,
				isCompact,
				appointments: isManyAppointment,
				haveBookingBadge: bookedBy || bookedFor
			})}
		>
			<div
				className={styles.containerInfo}
				id={chargeId ? `charge-id-${chargeId}` : ''}
			>
				{!isCancelled && !wasJustCancelled && (
					<div
						className={classes({
							mobileDateContainer: true,
							mobile: isMobileDate
						})}
					>
						<AppointmentDate
							appointmentStart={appointmentStart}
							className={styles.date}
						/>
						{(bookedBy || bookedFor) && (
							<Badge
								id={`appointment-badge-booked-${bookedBy ? 'by' : 'for'}-name`}
								iconLeft='Person'
								label={`${t(
									`myAccount.appointment.${bookedBy ? 'bookedBy' : 'bookedFor'}`
								)} : ${bookedBy ? bookedBy.name : bookedFor.name}`}
								className={styles.bookingBadgeMobile}
							/>
						)}
					</div>
				)}
				{!isCompact && (
					<div className={styles.images} id='appointment-image'>
						{business?.pictures?.at(0) ? (
							<div className={styles.appointmentImage}>
								<Image
									alt={business.name}
									url={business.pictures.at(0)}
									transformations={transformations}
									onClick={() =>
										process.env.WIDGET ? noop() : handleClick(business)
									}
								/>
							</div>
						) : (
							<div
								className={styles.noPicture}
								onClick={() =>
									process.env.WIDGET ? noop() : handleClick(business)
								}
							>
								<Icon icon='Store' className={styles.storeIcon} />
							</div>
						)}
						<span
							className={styles.name}
							onClick={() =>
								process.env.WIDGET ? noop() : handleClick(business)
							}
							id='appointment-business-name-mobile'
						>
							{business.name}
						</span>
					</div>
				)}

				<div
					className={classes({
						content: true
					})}
				>
					{!!isCancelled && (
						<span style={{ color: colors.error }}>
							<Localize text={'myAccount.appointment.wasPreviouslyCancelled'} />
						</span>
					)}
					{!!wasJustCancelled && (
						<span style={{ color: colors.error }}>
							<Localize text={'myAccount.appointment.hasBeenCancelled'} />
						</span>
					)}
					{!isCancelled && !wasJustCancelled && (
						<AppointmentDate
							appointmentStart={appointmentStart}
							className={styles.date}
						/>
					)}
					{(bookedBy || bookedFor) && (
						<Badge
							id={`appointment-badge-booked-${bookedBy ? 'by' : 'for'}-name`}
							iconLeft='Person'
							label={`${t(
								`myAccount.appointment.${bookedBy ? 'bookedBy' : 'bookedFor'}`
							)} : ${bookedBy ? bookedBy.name : bookedFor.name}`}
							className={styles.bookingBadge}
						/>
					)}
					{!isCompact && (
						<>
							<span
								className={styles.name}
								onClick={() =>
									process.env.WIDGET ? noop() : handleClick(business)
								}
								id='appointment-business-name'
							>
								{business.name}
							</span>

							{hasLocation && (
								<a
									href={googleMapURL}
									onClick={e => {
										if (!isNativeApp) return;
										e.preventDefault();
										sendToUserApp({
											type: 'OPEN_MAP',
											payload: {
												longitude: business?.location?.lng,
												latitude: business?.location?.lat,
												title: business.name
											}
										});
									}}
									target='_blank'
									className={styles.address}
									rel='noreferrer'
									id='appointment-address'
								>
									<Icon icon='Pin' />
									<p>
										<span>
											{`${business?.address?.street?.replace(',', '') || ''}, ${
												business?.address?.postalCode || ''
											} ${business?.address?.locality || ''}`}
										</span>
									</p>
								</a>
							)}
						</>
					)}

					<ul
						className={classes({
							items: true
						})}
					>
						<div
							className={classes({
								stepsDescription: true
							})}
						>
							<AppointmentSequence
								collapseSteps={collapseAppointmentSteps}
								appointment={appointment}
								isVisible
							>
								{Object.keys(appointment?.sequence || {})?.map(
									(stepId, index) => (
										<StepDescription
											canBeCancelled={canBeCancelled}
											business={business}
											key={stepId}
											step={appointment.sequence[stepId]}
											index={index}
											twoRows
										/>
									)
								)}
							</AppointmentSequence>
						</div>
					</ul>
					{!disableOnlinePaymentInfo && (
						<div
							className={classes({
								userPaysFees: !!userPaysFees || appointment.paymentMethod,
								pricesContent: appointment.paymentMethod,
								total: isManyAppointment
							})}
						>
							{!!userPaysFees && <FeesDescription fees={fees} />}

							{appointment.paymentMethod && (
								<PriceDescription
									appointment={appointment}
									isPrePayment={isPrePayment}
									paidAmount={finalPaidAmount}
									totalPrice={totalPrice}
									feesAmount={fees}
									className={styles.priceDesc}
								/>
							)}
						</div>
					)}
					{disableOnlinePaymentInfo && (
						<div className={styles.bookingMethods}>
							<div className={styles.bookingMethods__container}>
								<Icon
									icon={bookingMethodName}
									className={styles.bookingMethods__container__icon}
									size='small'
								/>
								<span
									id={'myAccount-appointment-booking-method'}
									className={styles.bookingMethods__container__text}
								>
									<Localize
										args={{ count: bookingMethodSize }}
										text={`myAccount.appointment.wasBookedWith${bookingMethodName}`}
									/>
								</span>
							</div>
						</div>
					)}
				</div>
			</div>

			<div className={classes({ actions: true })}>
				{!!linkToBookAppointment && (
					<div className={classes({ bookAgainContainer: true })}>
						<Button
							label={
								canBeCancelled
									? t('myAccount.appointment.moveAppointment')
									: t('myAccount.appointment.bookAgain')
							}
							isFullMobile
							onClick={handleOnBookAgainClick}
							isDisabled={!isBookable}
							id={'appointment-book-again-or-move-button'}
						/>
					</div>
				)}

				{!process.env.WIDGET &&
					!canBeCancelled &&
					enableReviews &&
					!disableRating && (
						<ReviewBlock
							appointment={appointment}
							appointmentId={appointmentId}
						/>
					)}
				{!wasJustCancelled && canBeCancelled && (
					<div
						className={classes({
							twoButtonsActions: true,
							frTwoButtons: language === 'fr',
							deTwoButtons: language === 'de',
							nlTwoButtons: language === 'nl'
						})}
					>
						<AddToCalendar
							myAccountLink={myAccountLink}
							business={business}
							appointment={appointment}
						/>
						<Button
							className={'planity_ui_action_cancel-appointment'}
							iconLeft={'Trash'}
							label={t('myAccount.appointment.cancel')}
							type='linked'
							onClick={cancelAppointment}
							id={`appointment-cancel-button-${appointmentIndex}`}
							isLoading={cancellationIsPending}
						/>
					</div>
				)}
			</div>
			<div className={classes({ waitingList: true })}>
				{!process.env.WIDGET && !fromModalAddCard && (
					<WaitingList
						appointment={appointment}
						appointmentId={appointmentId}
						appointmentStart={appointmentStart}
						fees={fees}
						finalPaidAmount={finalPaidAmount}
						paymentCreatedAt={paymentCreatedAt}
						canBeCancelled={canBeCancelled}
					/>
				)}
			</div>
		</div>
	);
}
