import React, { Component } from 'react';
import { firebase } from '@planity/datastores';
import { ErrorMessage } from '../error_message';
import {
	EMAIL_IN_USE_ERROR,
	Localize,
	NETWORK_ERROR,
	WRONG_PASSWORD_ERROR
} from '@planity/components';
import { CreateAPasswordForm } from './create_a_password';
import { INVALID_PASSWORD_ERROR, MISSING_PASSWORD_ERROR } from './constants';
import { InputPassword, Button, Card, Icon } from '@planity/ui';
import { withLocalization, withTranslation } from '@planity/localization';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { colors } from '@planity/theme';
import { withAuth } from '../authentication';

const MISSING_NEW_PASSWORD_ERROR = 'MISSING_NEW_PASSWORD_ERROR';
const INVALID_NEW_PASSWORD_ERROR = 'INVALID_NEW_PASSWORD_ERROR';
const WRONG_CONFIRMATION_PASSWORD = 'WRONG_CONFIRMATION_PASSWORD';

const WHITELISTED_ERRORS = [
	MISSING_PASSWORD_ERROR,
	INVALID_PASSWORD_ERROR,
	WRONG_PASSWORD_ERROR,
	NETWORK_ERROR,
	EMAIL_IN_USE_ERROR,
	MISSING_NEW_PASSWORD_ERROR,
	INVALID_NEW_PASSWORD_ERROR,
	WRONG_CONFIRMATION_PASSWORD
].map(e => `passwordChangeForm.errors.${e}`);

export default withLocalization(
	withTranslation()(
		withStyles(styles)(
			withAuth(
				class PasswordChangeForm extends Component {
					state = {
						password: '',
						newPassword: '',
						confirmationPassword: '',
						isPending: false,
						error: null,
						isEditing: false,
						success: null,
						passwordContainsLetter: false,
						passwordContainsNumberOrSpecialChar: false,
						passwordIsMinLength: false
					};

					render() {
						const {
							password,
							newPassword,
							confirmationPassword,
							error,
							isPending,
							success,
							passwordContainsLetter,
							passwordContainsNumberOrSpecialChar,
							passwordIsMinLength
						} = this.state;
						const {
							user,
							providers,
							setProviders,
							t,
							toggle,
							toggleIcon,
							isPro
						} = this.props;

						const isTypingPassword = !!password;

						const isPasswordValid = !isPro
							? true
							: passwordContainsLetter &&
							  passwordContainsNumberOrSpecialChar &&
							  passwordIsMinLength;

						const hasAPassword = providers.includes('password');
						const setHasAPassword = () => {
							if (!providers.includes('password')) {
								// Firebase being too slow to add the new provider 'password' to the list,
								// we bluff here to go to the next step and to show a success message
								setProviders(providers.concat('password'));
								this.setState({ success: 'passwordCreated' });
							}
						};

						const cx = classNames.bind(styles);

						if (!hasAPassword)
							return (
								<CreateAPasswordForm callback={setHasAPassword} user={user} />
							);
						return (
							<form id='pwd-change-form' onSubmit={this.submit}>
								<ErrorMessage
									defaultMessage={'passwordChangeForm.errors.defaultError'}
									error={error && `passwordChangeForm.errors.${error}`}
									whitelist={WHITELISTED_ERRORS}
								/>

								<ErrorMessage
									error={success && `userProfileForm.errors.${success}`}
									errorType={'success'}
								/>
								<Card
									hasTitleInside
									id='profile-password-title'
									title={t('myAccount.myProfile.title.PASSWORD')}
								>
									<div className={'planity_my-account-profile-form-wrapper'}>
										<div className={styles.info}>
											<Localize text={'myAccount.myProfile.passwordText'} />
										</div>
										<div className={styles.inputs}>
											{this.state.isEditing ? (
												<div>
													<div
														className={cx({
															fullWidth: true,
															passwords: true
														})}
													>
														<InputPassword
															id={'pwd-change-newPassword-input'}
															isRequired
															label={t(
																'passwordChangeForm.placeholders.newPassword'
															)}
															name={'newPassword'}
															placeholder={t(
																'passwordChangeForm.placeholders.newPassword'
															)}
															value={newPassword}
															onChange={password => {
																this.setNewPassword(password);
																this.validatePassword(password);
															}}
														/>
														<div className={styles.separator} />
														<InputPassword
															id={'pwd-change-confirmationPassword-input'}
															isRequired
															label={t(
																'passwordChangeForm.labels.confirmPassword'
															)}
															name={'confirmationPassword'}
															placeholder={t(
																'passwordChangeForm.placeholders.confirmationPassword'
															)}
															value={confirmationPassword}
															onChange={this.setConfirmationPassword}
														/>
													</div>
													{isPro && (
														<ul className={styles.passwordValidationContainer}>
															<li className={styles.passwordValidationItem}>
																<Icon
																	className={styles.passwordValidationIcon}
																	color={
																		passwordContainsLetter
																			? colors.success
																			: colors.error
																	}
																	icon={
																		passwordContainsLetter
																			? 'CheckCircle'
																			: 'CloseCircle'
																	}
																	size={16}
																/>
																{t(
																	'auth.passwordValidation.passwordContainsLetterMessage'
																)}
															</li>
															<li className={styles.passwordValidationItem}>
																<Icon
																	className={styles.passwordValidationIcon}
																	color={
																		passwordContainsNumberOrSpecialChar
																			? colors.success
																			: colors.error
																	}
																	icon={
																		passwordContainsNumberOrSpecialChar
																			? 'CheckCircle'
																			: 'CloseCircle'
																	}
																	size={16}
																/>
																{t(
																	'auth.passwordValidation.passwordContainsNumberOrSpecialCharMessage'
																)}
															</li>
															<li className={styles.passwordValidationItem}>
																<Icon
																	className={styles.passwordValidationIcon}
																	color={
																		passwordIsMinLength
																			? colors.success
																			: colors.error
																	}
																	icon={
																		passwordIsMinLength
																			? 'CheckCircle'
																			: 'CloseCircle'
																	}
																	size={16}
																/>
																{t(
																	'auth.passwordValidation.passwordIsMinLengthMessage'
																)}
															</li>
														</ul>
													)}
												</div>
											) : (
												<div className={styles.password}>
													<InputPassword
														icon={toggle ? 'Eye' : 'EyeOff'}
														id={'pwd-change-password-input'}
														isRequired
														label={t('myAccount.myProfile.title.PASSWORD')}
														name={'password'}
														placeholder={t(
															'passwordChangeForm.placeholders.password'
														)}
														toggle={toggle}
														value={password}
														onChange={this.setPassword}
														onToggleIcon={toggleIcon}
													/>
												</div>
											)}
										</div>
										<div className={styles.actions}>
											{this.state.isEditing ? (
												<div
													className={cx({
														fullWidth: true,
														buttons: true
													})}
												>
													<Button
														id={'pwd-change-cancel-button'}
														isFullMobile
														label={t('actions.cancel')}
														type={'tertiary'}
														onClick={e => {
															e.preventDefault();
															this.setState({
																isEditing: false,
																newPassword: '',
																confirmationPassword: ''
															});
														}}
													/>
													<Button
														id={'pwd-change-submit-button'}
														isDisabled={!!isPending || !isPasswordValid}
														isFullMobile
														label={
															isPending
																? t('actions.submitting')
																: t('actions.submit')
														}
														type={'primary'}
													/>
												</div>
											) : (
												<Button
													id={'pwd-change-noedit-button'}
													isDisabled={!isTypingPassword}
													isFullMobile
													label={t('actions.edit')}
													type={'primary'}
												/>
											)}
										</div>
									</div>
								</Card>
							</form>
						);
					}
					setPassword = password => {
						this.setState(({ error, password: passwordWas }) => ({
							password,
							error:
								(!!password && error === MISSING_PASSWORD_ERROR) ||
								(password !== passwordWas && error === WRONG_PASSWORD_ERROR)
									? null
									: error
						}));
					};
					setNewPassword = newPassword => {
						this.setState(({ error }) => ({
							newPassword,
							error:
								(!!newPassword && error === MISSING_NEW_PASSWORD_ERROR) ||
								(!!newPassword &&
									newPassword.length >= 6 &&
									error === INVALID_NEW_PASSWORD_ERROR)
									? null
									: error
						}));
					};
					setConfirmationPassword = confirmationPassword => {
						this.setState(({ error }) => ({
							confirmationPassword,
							error:
								(!!confirmationPassword &&
									error === MISSING_NEW_PASSWORD_ERROR) ||
								(!!confirmationPassword &&
									confirmationPassword.length >= 6 &&
									error === INVALID_NEW_PASSWORD_ERROR)
									? null
									: error
						}));
					};

					validatePassword = password => {
						const containsLetter = /[a-zA-Z]/.test(password);
						const containsNumberOrSpecialChar = /[^a-zA-Z]/.test(password);
						const isMinLength = password.length >= 8;
						this.setState({ passwordContainsLetter: containsLetter });
						this.setState({
							passwordContainsNumberOrSpecialChar: containsNumberOrSpecialChar
						});
						this.setState({ passwordIsMinLength: isMinLength });
					};

					async checkCredentials(user, password) {
						const credential = firebase.auth.EmailAuthProvider.credential(
							user.email,
							password
						);

						const { currentUser } = firebase.auth();

						try {
							await currentUser.reauthenticateWithCredential(credential);
							return true;
						} catch (error) {
							return false;
						}
					}
					submit = async e => {
						if (e && e.preventDefault) e.preventDefault();
						const { password, newPassword, confirmationPassword } = this.state;
						const { user } = this.props;

						if (!!password && !newPassword) {
							const goodPassword = await this.checkCredentials(user, password);
							if (goodPassword) {
								this.setState({
									isEditing: true,
									passwordContainsLetter: false,
									passwordContainsNumberOrSpecialChar: false,
									passwordIsMinLength: false
								});
							} else {
								this.setState({ error: WRONG_PASSWORD_ERROR });
							}
						} else if (password && newPassword && confirmationPassword) {
							this.setState({ isPending: true });
							if (newPassword !== confirmationPassword) {
								this.setState({
									error: WRONG_CONFIRMATION_PASSWORD,
									isPending: false
								});
								return;
							}
							const { currentUser } = firebase.auth();
							const credential = firebase.auth.EmailAuthProvider.credential(
								user.email,
								password
							);
							try {
								await currentUser.reauthenticateWithCredential(credential);
								await currentUser.updatePassword(newPassword);
								this.setState({
									password: '',
									newPassword: '',
									confirmationPassword: '',
									isPending: false,
									error: null,
									isEditing: false,
									success: 'updatePasswordSuccess'
								});
							} catch (error) {
								console.error(error);
								this.setState({
									error: error.code || error
								});
							}
						} else if (!password) {
							this.setState({ error: MISSING_PASSWORD_ERROR });
						} else if (!newPassword) {
							this.setState({ error: MISSING_NEW_PASSWORD_ERROR });
						}

						this.setState({ isPending: false, success: null });
					};
				}
			)
		)
	)
);
