import React, { createRef, useState } from 'react';
import { colors } from '@planity/theme';
import { EyeOffIcon, EyeOnIcon, TextInput } from '@planity/components';

const PasswordInput = ({
	value,
	autofocus,
	onChange,
	localizedPlaceholder,
	placeholder,
	isDisabled
}) => {
	const [isPasswordVisible, setPasswordVisibility] = useState(false);
	const inputRef = createRef();

	const switchPasswordVisibility = e => {
		e.preventDefault();
		inputRef.current.focus();
		setPasswordVisibility(!isPasswordVisible);
	};

	return (
		<div css={[styles.row]}>
			{isPasswordVisible ? (
				<EyeOnIcon
					color={colors.grey.brownGrey}
					css={styles.icon}
					onClick={switchPasswordVisibility}
				/>
			) : (
				<EyeOffIcon
					color={colors.grey.brownGrey}
					css={styles.icon}
					onClick={switchPasswordVisibility}
				/>
			)}
			<TextInput
				autoCapitalize={'none'}
				autoFocus={autofocus}
				css={styles.input}
				isDisabled={isDisabled}
				localizedPlaceholder={localizedPlaceholder}
				placeholder={placeholder}
				ref={inputRef}
				type={isPasswordVisible ? 'text' : 'password'}
				value={value}
				onChange={onChange}
			/>
		</div>
	);
};

const styles = {
	row: {
		position: 'relative',
		width: '100%'
	},
	icon: {
		position: 'absolute',
		top: 9,
		width: 24,
		height: 24,
		bottom: 9,
		right: 9,
		border: 'none',
		outline: 'none',
		backgroundColor: 'transparent',
		WebkitAppearance: 'initial !important',
		cursor: 'pointer'
	},
	input: {
		padding: '12px 58px 12px 12px !important'
	}
};

export default PasswordInput;
