import React, { useState } from 'react';
import { Button, Textarea } from '@planity/ui';
import { useTranslation } from '@planity/localization';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './comment.module.scss';
import { scrollTo } from '@planity/helpers';
import { useTheme } from '@planity/context';

const CHARACTER_LIMIT = 300;

export default function Comment({ onChange, isPending }) {
	const { t } = useTranslation();
	useStyles(styles);

	const [isOpened, setIsOpened] = useState(false);

	const classes = classNames.bind(styles)({
		comment: true,
		isOpened
	});

	const scrollToField = () => {
		scrollTo({
			node: document.getElementById('comment-container'),
			animated: true,
			offset: 250
		});
	};
	const { isDarkThemeWidget } = useTheme();

	return (
		<div className={classes}>
			<Button
				className={styles.moreInfo}
				iconLeft={isOpened ? 'Minus' : 'Add'}
				label={t('comment.commentToBusiness')}
				type={`${isDarkThemeWidget ? 'linkedDark' : 'linked'}`}
				onClick={() => {
					const _isOpened = isOpened;
					setIsOpened(!_isOpened);
					_isOpened && scrollToField();
				}}
			/>
			<div className={styles.transition}>
				<div className={styles.card} id='comment-container'>
					<Textarea
						autoResize
						characterLimit={CHARACTER_LIMIT}
						className={`${styles.field} comment-textarea`}
						id={'comment-form-textarea'}
						isDisabled={isPending}
						maxLength={CHARACTER_LIMIT}
						placeholder={t('comment.commentToBusiness')}
						onChange={onChange}
					/>
				</div>
			</div>
		</div>
	);
}
