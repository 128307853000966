import { useLocalization, useTranslation } from '@planity/localization';
import { getCitiesSelection } from '@planity/helpers';
import { useMemo } from 'react';

/**
 * @typedef {Object} CitiesSelectionObject
 * @property {string} link - The URL link for the city.
 * @property {string} label - The localized label for the city.
 * /
/**
 * This hook retrieves the list of cities based on the current locale,
 * and generates a list of city links with localized labels based on the
 * provided `baseSearch` object.
 *
 * @param {Object} baseSearch - The search context containing category information.
 * @returns {CitiesSelectionObject[]} - List of cities based on the current locale,
 */

export const useCitiesSelection = baseSearch => {
	const { locale, localizedBasePath: basePath } = useLocalization();
	const { t } = useTranslation();

	const parentCategoryName = baseSearch.parentCategory.name;
	const parentCategorySlug = baseSearch.parentCategory.slug;
	const currentCitySlug = baseSearch.parentPlace.slug;
	const citiesSelection = getCitiesSelection(locale);

	return useMemo(() => {
		return citiesSelection.map(({ label: cityLabel, slug: citySlug }) => ({
			link: `${basePath}/${parentCategorySlug}/${citySlug}`,
			label: t('category.relatedSearchesCity.label', {
				categoryName: parentCategoryName,
				cityLabel: cityLabel
			})
		}));
	}, [
		basePath,
		parentCategorySlug,
		parentCategoryName,
		citiesSelection,
		currentCitySlug
	]);
};
