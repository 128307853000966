import { formatPrice } from '@planity/helpers';
import { useTranslation } from '@planity/localization';
import React from 'react';
import { QuantitySelect } from './quantity_select';
import { computeFirstLineText } from './computeTexts';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './product_article.module.scss';
import capitalize from 'lodash/capitalize';
import { Button } from '@planity/ui';

export function ProductArticleMinimized({
	product,
	updateArticleQuantity,
	onQuantityError,
	id,
	index,
	isPending
}) {
	const { t } = useTranslation();
	const { name, brand, price, quantity, stock, range } = product;
	const rangeAndSoldOutText = computeFirstLineText(brand, range, false, t);

	useStyles(styles);
	const classes = classNames.bind(styles)({
		productArticle: true
	});

	/**
	 * Updates the quantity selected.
	 * Checks if it is possible based on the stock.
	 * @param {number} quantity quantity wanted
	 */
	const onUpdateQuantity = quantity => {
		const finalStock = stock - quantity;
		if (finalStock < 0) {
			onQuantityError();
		} else {
			updateArticleQuantity(product, quantity);
		}
	};

	return (
		<div className={classes}>
			<div className={styles.textContainer}>
				<div className={styles.textDescription}>
					<div className={styles.titleContainer}>
						<div className={styles.title}>
							<span className={styles.brand}>
								{brand ? brand : capitalize(name)}
							</span>
							<span>{rangeAndSoldOutText}</span>
						</div>
						<div className={styles.price}>
							<span id={`cart-item-price-article-${index}`}>
								{formatPrice(price)}
							</span>
						</div>
					</div>
				</div>
				<div className={styles.actionsContainer}>
					<div className={styles.quantitySelect}>
						{!isPending && (
							<QuantitySelect
								height={36}
								id={id}
								quantity={quantity}
								setQuantity={qty => onUpdateQuantity(qty)}
								width={100}
							/>
						)}
					</div>
					<Button
						className={
							'planity_ui_action_button_root planity_ui_action_button_icon-remove'
						}
						isLoading={isPending}
						label={t('clickAndCollect.delete')}
						type={'underlined'}
						onClick={() => updateArticleQuantity(product, 0)}
					/>
				</div>
			</div>
		</div>
	);
}
