import React, { useCallback, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import { useTranslation } from '@planity/localization';
import { Button, useModal } from '@planity/ui';
import { Localize } from '@planity/components';
import styles from './payment_warning_modal.scss';

const paymentType = {
	klarna: 'Klarna',
	bancontact: 'Bancontact'
};

const ModalText = ({ appName, paymentTypeName, styles }) => {
	const isBancontactPaymentMethod = paymentTypeName === paymentType.bancontact;

	return (
		<>
			{isBancontactPaymentMethod && (
				<div className={styles.warning}>
					<Localize text={'warning.bancontact.payment.planity'} />
				</div>
			)}
			<Localize
				args={{ paymentType: paymentTypeName }}
				text={
					isBancontactPaymentMethod
						? `message.bancontact.payment.${appName}`
						: `message.exotic.payments.${appName}`
				}
			/>
		</>
	);
};

export const PaymentWarningModal = ({ paymentMethodType, onClick }) => {
	useStyles(styles);
	const { t } = useTranslation();
	const classes = classNames.bind(styles)({
		paymentWarningModal: true
	});

	const paymentTypeName = paymentType[paymentMethodType];
	const isBancontactPaymentMethod = paymentTypeName === paymentType.bancontact;
	const appName = process.env.WEBVIEW
		? 'app'
		: process.env.WIDGET
		? 'whiteLabel'
		: 'planity';

	const { closeModal } = useModal();
	const [isLoading, setIsLoading] = useState(false);

	const onModalClick = useCallback(async () => {
		if (onClick) {
			setIsLoading(true);
			try {
				await onClick();
			} finally {
				setIsLoading(false);
			}
		}
		closeModal();
	}, [onClick, closeModal]);

	return (
		<div className={classes}>
			<div className={styles.title}>{paymentTypeName}</div>
			<div className={styles.message}>
				<ModalText
					appName={appName}
					paymentTypeName={paymentTypeName}
					styles={styles}
				/>
			</div>
			<div>
				<Button
					id={'payment-warning-dismiss-button'}
					isFullMobile
					isFullWidth
					isLoading={isLoading}
					label={t('myAccount.iUnderstood')}
					type={isBancontactPaymentMethod ? 'danger' : 'primary'}
					onClick={onModalClick}
				/>
			</div>
		</div>
	);
};
