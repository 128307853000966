import React from 'react';
import { Localize } from '@planity/components';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './title.module.scss';

export function BusinessTitle({
	path,
	text,
	style,
	itemProp,
	args,
	Component,
	isDark,
	isDarkThemeWidget
}) {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		title: true,
		isDark,
		isDarkThemeWidget
	});
	const Hn = Component || 'h1';
	return (
		<div className={classes}>
			<Hn className={styles.title} css={style} itemProp={itemProp || ''}>
				{text || <Localize args={args} text={path} />}
			</Hn>
		</div>
	);
}
