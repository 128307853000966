import React, { Component, createRef } from 'react';
import isEqual from 'lodash/isEqual';
import { breakpoints, withScreenDimensions } from '@planity/theme';
import {
	ClickAndCollect,
	GiftVoucher,
	HowTo,
	OpeningHours,
	Tabs,
	WithBusinessGiftVouchers,
	withFormFactor,
	withLastSuccessfulAppointment
} from '@planity/components';
import {
	businessSortedWebVisibleServiceSets,
	getServicesWithGiftVouchersEnabled,
	invokeLambda,
	parseOpeningHours,
	scrollTo,
	shouldDisplayClickAndCollect,
	shouldDisplayClickAndCollectSync,
	showGiftButton
} from '@planity/helpers';
import { PageSection, SplitPage } from '../../layout';
import credentials from '@planity/credentials';
import { withGoalEvents } from '@planity/helpers/analytics';
import { BusinessInfo } from './business_info';
import { withRouter } from 'react-router-dom';
import { withLocalizedRoutes, withTranslation } from '@planity/localization';
import { About, BusinessReviewsCard, Container } from '@planity/ui';
import { BusinessTopInfo } from './business_top_info';
import styles from './customer.module.scss';
import withStyles from 'isomorphic-style-loader/withStyles';
import classNames from 'classnames/bind';

export default withStyles(styles)(
	withRouter(
		withGoalEvents(
			withLocalizedRoutes(
				withLastSuccessfulAppointment(
					withScreenDimensions(
						withFormFactor(
							withTranslation()(
								class BusinessPageMainCustomer extends Component {
									reviewsContainer = createRef();
									bookAppointmentRef = createRef();
									about = createRef();
									state = {
										isMounted: false,
										currentTabId: 'book',
										tabIsSticky: false,
										...getProductsInitialState(this.props)
									};
									popupGiftService = () => {
										const { giftVoucher } = this.state;

										this.setState({
											giftVoucher: !giftVoucher
										});
									};

									popupGiftServiceSubmit = giftData => {
										const { business, history } = this.props;
										this.setState({ currentTabId: 'book' });
										this.popupGiftService();

										history.push({
											pathname: `${business.slug}/reservation_gift_voucher`,
											state: {
												giftData
											}
										});
									};

									componentDidMount() {
										try {
											const { publishBusinessPageEvent, business, businessId } =
												this.props;
											this.setState({ isMounted: true });
											if (publishBusinessPageEvent) {
												publishBusinessPageEvent();
											}
											// invalidate availabilities for customers with very short delays
											const soonestDelay = business?.settings?.delays?.soonest;
											if (soonestDelay < 7 * 24 * 60) {
												invokeLambda('invalidateAvailabilities', {
													businessId: businessId
												});
											}
										} catch (e) {
											console.error(e);
										}
									}

									async componentDidUpdate(prevProps) {
										// invalidate availabilities for customers with very short delays
										const soonestDelay =
											this.props.business?.settings?.delays?.soonest;
										const soonestDelayWas =
											prevProps.business?.settings?.delays?.soonest;
										if (
											soonestDelay !== soonestDelayWas &&
											soonestDelay < 7 * 24 * 60
										) {
											invokeLambda('invalidateAvailabilities', {
												businessId: this.props.businessId
											});
										}

										const {
											business,
											businessId,
											hasStartedBooking,
											windowWidth
										} = this.props;
										if (hasStartedBooking !== prevProps.hasStartedBooking) {
											requestAnimationFrame(() => {
												scrollTo({ top: 0 });
											});
										}

										const hasModulesBeenUpdated =
											prevProps.business.modules || business.modules
												? isEqual(prevProps.business.modules, business.modules)
												: false;
										const hasSettingsBeenUpdated =
											prevProps.business.settings || business.settings
												? isEqual(
														prevProps.business.settings,
														business.settings
												  )
												: false;
										if (
											this.state.displayClickAndCollect === undefined ||
											this.state.displayClickAndShop === undefined ||
											hasModulesBeenUpdated ||
											hasSettingsBeenUpdated
										) {
											const {
												displayClickAndCollect,
												displayClickAndShop,
												clickAndCollectProducts,
												clickAndShopProducts
											} = await shouldDisplayClickAndCollect(
												business,
												businessId
											);

											if (
												displayClickAndCollect !==
													this.state.displayClickAndCollect ||
												displayClickAndShop !== this.state.displayClickAndShop
											) {
												this.setState({
													displayClickAndCollect,
													displayClickAndShop,
													clickAndCollectProducts,
													clickAndShopProducts
												});
											}
										}
										// Those tab do not exist on desktop. If, by any mean (like resizing you
										// window or changing your tablet orientation), you end up on those
										// tabs on desktop, get redirected to booking
										if (
											windowWidth >= breakpoints.desktop &&
											['about', 'reviews'].includes(this.state.currentTabId)
										) {
											this.setState({
												currentTabId: 'book'
											});
										}
									}

									shouldComponentUpdate(nextProps) {
										return !(
											this.props.windowHeight !== nextProps.windowHeight &&
											this.props.windowWidth === nextProps.windowWidth
										);
									}

									onTabChanged = ({ id }) => {
										this.setState({
											currentTabId: id
										});
									};
									moveToBookAppointmentNode = () => {
										const node = this.bookAppointmentRef.current;
										if (node) {
											scrollTo({ node, animated: true });
										}
									};

									navigateToTab(id) {
										if (this.state.currentTabId === id)
											this.moveToBookAppointmentNode();
										else
											this.setState({
												currentTabId: id
											});
									}

									render() {
										const {
											business,
											businessId,
											crumbs,
											hasStartedBooking,
											routes,
											hasStartedBookingGiftVoucher,
											giftCards,
											t,
											history,
											location,
											parentPlace,
											place
										} = this.props;
										const {
											currentTabId,
											displayClickAndCollect,
											displayClickAndShop
										} = this.state;
										const classes = classNames.bind(styles);

										const _showRating =
											credentials.DISPLAY_REVIEWS &&
											business &&
											((!business.desactivateReviews &&
												business.ratingsCount) ||
												(!business?.settings?.desactivateReviews &&
													!!business?.rating?.globalRating));
										const businessOnlinePaymentSetup =
											business?.settings?.onlinePayment?.status === 'setup' &&
											business?.modules?.pos;

										const businessOnlinePaymentEnabled =
											business?.modules?.onlinePayment;

										const services =
											businessSortedWebVisibleServiceSets(business);
										const servicesGiftVouchersEnabled =
											getServicesWithGiftVouchersEnabled(services);
										const _giftVoucherOptionEnabled = showGiftButton(
											servicesGiftVouchersEnabled,
											businessOnlinePaymentEnabled,
											businessOnlinePaymentSetup,
											this.props.giftCards
										);
										const businessIsFullyLoaded =
											!this.props.isLoading &&
											!this.props.businessGiftVouchersIsLoading &&
											displayClickAndCollect !== undefined &&
											displayClickAndShop !== undefined;

										const tabs = [
											{
												text: t('business.labels.book'),
												id: 'book'
											},
											_showRating && {
												text: t('business.labels.reviews'),
												id: 'reviews'
											},
											{
												text: t('business.labels.about'),
												id: 'about'
											},
											_giftVoucherOptionEnabled && {
												text: t('business.labels.giftVoucher'),
												id: 'giftVoucher'
											},
											(displayClickAndCollect || displayClickAndShop) && {
												text: t('business.labels.clickAndCollect'),
												id: 'clickAndCollect'
											}
										].filter(x => x);

										const openingHours = parseOpeningHours(
											business?.openingHours,
											{
												convertToMinutes: false
											}
										);
										const isBooking =
											hasStartedBooking || hasStartedBookingGiftVoucher;
										return (
											<Tabs.Wrapper currentId={currentTabId}>
												<div
													className={classes({
														business: !isBooking,
														booking: isBooking
													})}
												>
													<div className={classes({ hideOnDesktop: true })}>
														{!hasStartedBooking && (
															<Tabs.List
																className={classes({ tabsList: true })}
																tabs={tabs}
																onTabChanged={this.onTabChanged}
															/>
														)}
													</div>
													<Container
														noStupidMarginBottom={currentTabId === 'book'}
														size={isBooking ? 'medium' : 'large'}
													>
														{[
															'book',
															'clickAndCollect',
															'giftVoucher',
															'reviews',
															'about'
														].includes(currentTabId) && (
															<BusinessTopInfo
																business={business}
																businessIsFullyLoaded={businessIsFullyLoaded}
																displayBusinessInfoAndCTAOnMobile={
																	currentTabId === 'book'
																}
																displayBusinessInfoOnTablet
																displayClickAndCollect={
																	this.state.displayClickAndCollect
																}
																displayClickAndShop={
																	this.state.displayClickAndShop
																}
																giftVoucherOptionEnabled={
																	_giftVoucherOptionEnabled
																}
																hasStartedBooking={isBooking}
																isMoveAppointment={
																	!!location?.state?.veventToDelete
																}
																key={currentTabId}
																showRating={_showRating}
																showSlideshow={
																	!['reviews', 'about'].includes(currentTabId)
																}
																onBookClick={() => this.navigateToTab('book')}
																onClickAndCollectClick={() =>
																	this.navigateToTab('clickAndCollect')
																}
																onGiftVoucherClick={() =>
																	this.navigateToTab('giftVoucher')
																}
															/>
														)}
														<div ref={this.bookAppointmentRef}>
															<WithBusinessGiftVouchers businessId={businessId}>
																{({ businessGiftVouchers: giftCards }) => (
																	<BusinessInfo
																		{...this.props}
																		businessIsFullyLoaded={
																			businessIsFullyLoaded
																		}
																		clickAndCollectProducts={
																			this.state.clickAndCollectProducts
																		}
																		clickAndShopProducts={
																			this.state.clickAndShopProducts
																		}
																		currentTabId={currentTabId}
																		displayClickAndCollect={
																			this.state.displayClickAndCollect
																		}
																		displayClickAndShop={
																			this.state.displayClickAndShop
																		}
																		giftCards={giftCards}
																		giftVoucherOptionEnabled={
																			_giftVoucherOptionEnabled
																		}
																		hasStartedBookingGiftVoucher={
																			hasStartedBookingGiftVoucher
																		}
																		menuTabs={tabs.filter(
																			({ id }) =>
																				!['about', 'reviews'].includes(id)
																		)}
																		popupGiftService={this.popupGiftService}
																		popupGiftServiceSubmit={
																			this.popupGiftServiceSubmit
																		}
																		servicesGiftVouchersEnabled={
																			servicesGiftVouchersEnabled
																		}
																		showRating={_showRating}
																		onTabChanged={this.onTabChanged}
																	/>
																)}
															</WithBusinessGiftVouchers>
															{!!(
																businessIsFullyLoaded &&
																(this.state.displayClickAndCollect ||
																	this.state.displayClickAndShop)
															) && (
																<Tabs.Panel id={'clickAndCollect'}>
																	<SplitPage.Wrapper className={styles.split}>
																		<div className={styles.columns}>
																			<SplitPage.Main>
																				<ClickAndCollect
																					business={business}
																					businessId={businessId}
																					clickAndCollectProducts={
																						this.state.clickAndCollectProducts
																					}
																					clickAndShopProducts={
																						this.state.clickAndShopProducts
																					}
																					displayClickAndCollect={
																						this.state.displayClickAndCollect
																					}
																					displayClickAndShop={
																						this.state.displayClickAndShop
																					}
																					needToFetch
																					onSubmit={({
																						isClickAndCollect,
																						isClickAndShop,
																						shippingExplanationSentence
																					}) => {
																						history.push(
																							routes.catchAll({
																								onlineShop: { business },
																								state: {
																									businessId: businessId,
																									isClickAndCollect,
																									isClickAndShop,
																									shippingExplanationSentence
																								}
																							})
																						);
																					}}
																				/>
																			</SplitPage.Main>
																			<SplitPage.Aside className={styles.aside}>
																				<HowTo
																					countryCode={business.countryCode}
																					displayClickAndCollect={
																						this.state.displayClickAndCollect
																					}
																					displayClickAndShop={
																						this.state.displayClickAndShop
																					}
																				/>
																				{!!openingHours && (
																					<OpeningHours
																						business={business}
																						openingHours={openingHours}
																					/>
																				)}
																			</SplitPage.Aside>
																		</div>
																	</SplitPage.Wrapper>
																</Tabs.Panel>
															)}
															{!hasStartedBooking && (
																<Tabs.Panel id={'giftVoucher'}>
																	<SplitPage.Wrapper className={styles.split}>
																		<div className={styles.columns}>
																			<SplitPage.Main className={styles.main}>
																				<GiftVoucher
																					business={business}
																					businessId={businessId}
																					giftCards={giftCards}
																					history={this.props.history}
																					services={servicesGiftVouchersEnabled}
																					type={_giftVoucherOptionEnabled}
																					onSubmit={this.popupGiftServiceSubmit}
																				/>
																			</SplitPage.Main>
																		</div>
																	</SplitPage.Wrapper>
																</Tabs.Panel>
															)}
															{!hasStartedBooking &&
																!!_showRating &&
																business.rating && (
																	<Tabs.Panel id={'reviews'}>
																		<PageSection>
																			<div
																				className={classes({ reviews: true })}
																				ref={this.reviewsContainer}
																			>
																				<BusinessReviewsCard
																					data={{
																						rating: business.rating,
																						businessName: business.name,
																						businessId,
																						business
																					}}
																				/>
																			</div>
																		</PageSection>
																	</Tabs.Panel>
																)}
															{!hasStartedBooking && (
																<Tabs.Panel id={'about'}>
																	<div ref={this.about}>
																		<About
																			business={business}
																			crumbs={crumbs}
																			parentPlace={parentPlace}
																			place={place}
																		/>
																	</div>
																</Tabs.Panel>
															)}
														</div>
													</Container>
												</div>
											</Tabs.Wrapper>
										);
									}
								}
							)
						)
					)
				)
			)
		)
	)
);

function getProductsInitialState(props) {
	// Do not initialize to false
	// That way, we know when we need to compute if the business has click &
	// collect enabled
	// unless this is initial render in direct access
	if (
		props &&
		props.business &&
		(!process.env.BROWSER ||
			(typeof window !== 'undefined' && !!window._planity_isHydrating))
	) {
		return shouldDisplayClickAndCollectSync(props.business);
	}
	return {
		displayClickAndCollect: undefined,
		displayClickAndShop: undefined,
		clickAndCollectProducts: {},
		clickAndShopProducts: {}
	};
}
