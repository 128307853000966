/* eslint-disable no-undef */
import { Component } from 'react';

const TOP_MARKER_ZINDEX = 20;

export default class MapUserMarker extends Component {
	marker = null;
	svg = null;
	componentDidMount() {
		this.createMarker();
	}
	componentDidUpdate(prevProps) {
		if (!this.marker) {
			this.createMarker();
		}
		const { latitude, longitude } = this.props;
		const { latitude: latitudeWas, longitude: longitudeWas } = prevProps;
		if (
			this.marker &&
			(latitude !== latitudeWas || longitude !== longitudeWas)
		) {
			this.marker.setLngLat([this.props.longitude, this.props.latitude]);
			if (this.svg) {
				this.applySvgStyle();
			}
		}
	}
	componentWillUnmount() {
		if (this.marker) {
			this.marker.remove();
		}
		if (this.svg) {
			this.svg.remove();
		}
	}
	render() {
		return null;
	}
	createMarker() {
		if (this.props.map) {
			this.marker = new mapboxgl.Marker({
				element: this.getElement(),
				anchor: 'center'
			});
			this.marker.setLngLat([this.props.longitude, this.props.latitude]);
			this.marker.addTo(this.props.map);
		}
	}
	applySvgStyle() {
		this.svg.style.zIndex = TOP_MARKER_ZINDEX;
		this.svg.setAttribute('viewBox', '0 0 28 28');
		this.svg.setAttribute('height', 28);
	}
	getElement() {
		this.svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
		this.svg.innerHTML = `
        <g filter="url(#filter0_d_7226_28240)">
<circle cx="14" cy="10" r="7" fill="#625DF5" stroke="white" stroke-width="2"/>
</g>
<defs>
<filter id="filter0_d_7226_28240" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect1_dropShadow_7226_28240"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="4"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.101961 0 0 0 0 0.105882 0 0 0 0 0.121569 0 0 0 0.08 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7226_28240"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7226_28240" result="shape"/>
</filter>
</defs>`;
		this.applySvgStyle();
		return this.svg;
	}
}
