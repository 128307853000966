import { getSEOCatToPushFromCountryCode } from '../config/seoTags';

const updateTagsScoresMap = ({ tagsScoresMap, tagIds, seoCatIds }) => {
	tagIds.forEach(tagId => {
		if (!seoCatIds.includes(tagId)) {
			return;
		}

		if (tagsScoresMap[tagId] === undefined) {
			tagsScoresMap[tagId] = 1;
		} else {
			tagsScoresMap[tagId] = tagsScoresMap[tagId] + 1;
		}
	});
};

const getTagsByChildId = (services, sequenceServiceId) => {
	const service = Object.values(services).find(
		service => service.children?.[sequenceServiceId]
	);
	return service?.children[sequenceServiceId]?.tags || null;
};

export const getSEOTagsScores = ({ brand, countryCode, services, tags }) => {
	const seoCatIds = getSEOCatToPushFromCountryCode(countryCode);
	if (!seoCatIds || !services) {
		//seoCat could be undefined if the business is not FR, DE or BE
		return [];
	}

	const tagsById = tags.reduce((acc, tag) => {
		acc[tag.objectID] = tag;
		return acc;
	}, {});

	// get service tags
	const getServiceTags = service =>
		typeof service.tags === 'string' ? service.tags.split(',') : [];

	// get valid sequence tags from a service
	const getValidSequenceTags = service =>
		(service.sequence || [])
			.flatMap(
				sequence =>
					getTagsByChildId(services, sequence.serviceId)?.split(',') || []
			)
			.filter(Boolean);

	// get all relevant tags (sequence tags + service tags)
	const getAllTagsForService = service => [
		...getServiceTags(service),
		...getValidSequenceTags(service)
	];

	// Process each service and collect the tags
	const tagsScoresMap = Object.values(services)
		.flatMap(serviceSet => Object.values(serviceSet.children || []))
		.filter(service => service.bookable && !service.deletedAt)
		.reduce((tagsScoresMap, service) => {
			const allTags = getAllTagsForService(service);
			allTags.forEach(tagId => {
				const tag = tagsById[tagId];
				if (tag) {
					const parentTagsIds = tag.parentIds || [];
					updateTagsScoresMap({
						tagsScoresMap,
						tagIds: [tagId, ...parentTagsIds],
						seoCatIds
					});
				}
			});
			return tagsScoresMap;
		}, {});

	// Add brand tags
	updateTagsScoresMap({
		tagsScoresMap,
		tagIds: [brand],
		seoCatIds
	});

	// Return the final tag scores
	return Object.entries(tagsScoresMap).map(([tagId, score]) => ({
		tagId,
		score
	}));
};
