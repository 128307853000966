/**
 *
 * @param {{
 * 	category: {
 * 		slug: string
 * 	},
 * 	parentCategory: {
 * 		slug: string
 * 	},
 * 	parentPlace: {
 * 		slug: string
 * 	},
 * 	place: {
 * 		slug: string
 * 	},
 * 	googlePlace: {
 * 		place_id: string
 * 	},
 * page: number
 * bounds: {
 * 	ne: {
 * 		lat: number,
 * 		lng: number
 * 	},
 * 	sw: {
 * 		lat: number,
 * 		lng: number
 * 	}
 * }
 * userLocation: {
 * 	lat: number,
 * 	lng: number
 * }
 * }} search
 * @param {string[]} slugs
 * @returns {boolean}
 */
export const isValidUrlOrder = (search = {}, slugs = []) => {
	const parentCategory = search.parentCategory?.slug;
	const category = search.category?.slug;
	const parentPlace = search.parentPlace?.slug;
	const place = search.place?.slug;
	const googlePlace = search.googlePlace?.place_id
		? `gp_${search.googlePlace.place_id}`
		: null;
	const page = search.page ? `page-${search.page}` : null;
	const bounds = search.bounds ? slugs.find(slug => isBounds(slug)) : null;
	const aroundMe = search.userLocation
		? slugs.find(slug => isUserLocation(slug))
		: null;

	const validPatterns = [
		// /parentCategory
		{
			length: 1,
			validate: segs => segs[0] === parentCategory
		},
		// /parentCategory/category
		{
			length: 2,
			validate: segs => segs[0] === parentCategory && segs[1] === category
		},
		// /parentCategory/category/page-n
		{
			length: 3,
			validate: segs =>
				segs[0] === parentCategory && segs[1] === category && segs[2] === page
		},
		// /parentCategory/category/bounds
		{
			length: 3,
			validate: segs =>
				segs[0] === parentCategory && segs[1] === category && segs[2] === bounds
		},
		// /parentCategory/category/bounds/page-n
		{
			length: 4,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === category &&
				segs[2] === bounds &&
				segs[3] === page
		},
		// /parentCategory/parentPlace
		{
			length: 2,
			validate: segs => segs[0] === parentCategory && segs[1] === parentPlace
		},
		// /parentCategory/parentPlace/page-n
		{
			length: 3,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === parentPlace &&
				segs[2] === page
		},
		// /parentCategory/parentPlace/bounds
		{
			length: 3,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === parentPlace &&
				segs[2] === bounds
		},
		// /parentCategory/parentPlace/bounds/page-n
		{
			length: 4,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === parentPlace &&
				segs[2] === bounds &&
				segs[3] === page
		},
		// /parentCategory/googlePlace
		{
			length: 2,
			validate: segs => segs[0] === parentCategory && segs[1] === googlePlace
		},
		// /parentCategory/googlePlace/page-n
		{
			length: 3,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === googlePlace &&
				segs[2] === page
		},
		// /parentCategory/googlePlace/bounds
		{
			length: 3,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === googlePlace &&
				segs[2] === bounds
		},
		// /parentCategory/googlePlace/bounds/page-n
		{
			length: 4,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === googlePlace &&
				segs[2] === bounds &&
				segs[3] === page
		},
		// /parentCategory/category/googlePlace
		{
			length: 3,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === category &&
				segs[2] === googlePlace
		},
		// /parentCategory/category/googlePlace/page-n
		{
			length: 4,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === category &&
				segs[2] === googlePlace &&
				segs[3] === page
		},
		// /parentCategory/category/googlePlace/bounds
		{
			length: 4,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === category &&
				segs[2] === googlePlace &&
				segs[3] === bounds
		},
		// /parentCategory/category/googlePlace/bounds/page-n
		{
			length: 5,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === category &&
				segs[2] === googlePlace &&
				segs[3] === bounds &&
				segs[4] === page
		},
		// /parentCategory/aroundMe
		{
			length: 2,
			validate: segs => segs[0] === parentCategory && segs[1] === aroundMe
		},
		// /parentCategory/page-n/aroundMe
		{
			length: 3,
			validate: segs =>
				segs[0] === parentCategory && segs[1] === page && segs[2] === aroundMe
		},
		// /parentCategory/bounds/aroundMe
		{
			length: 3,
			validate: segs =>
				segs[0] === parentCategory && segs[1] === bounds && segs[2] === aroundMe
		},
		// /parentCategory/bounds/page-n/aroundMe
		{
			length: 4,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === bounds &&
				segs[2] === page &&
				segs[3] === aroundMe
		},
		// /parentCategory/category/aroundMe
		{
			length: 3,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === category &&
				segs[2] === aroundMe
		},
		// /parentCategory/category/page-n/aroundMe
		{
			length: 4,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === category &&
				segs[2] === page &&
				segs[3] === aroundMe
		},
		// /parentCategory/category/bounds/aroundMe
		{
			length: 4,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === category &&
				segs[2] === bounds &&
				segs[3] === aroundMe
		},
		// /parentCategory/category/bounds/page-n/aroundMe
		{
			length: 5,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === category &&
				segs[2] === bounds &&
				segs[3] === page &&
				segs[4] === aroundMe
		},
		// /parentCategory/category/parentPlace
		{
			length: 3,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === category &&
				segs[2] === parentPlace
		},
		// /parentCategory/category/parentPlace/page-n
		{
			length: 4,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === category &&
				segs[2] === parentPlace &&
				segs[3] === page
		},
		// /parentCategory/parentPlace/place
		{
			length: 3,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === parentPlace &&
				segs[2] === place
		},
		// /parentCategory/parentPlace/place/page-n
		{
			length: 4,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === parentPlace &&
				segs[2] === place &&
				segs[3] === page
		},
		// /parentCategory/parentPlace/place/bounds
		{
			length: 4,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === parentPlace &&
				segs[2] === place &&
				segs[3] === bounds
		},
		// /parentCategory/parentPlace/place/bounds/page-n
		{
			length: 5,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === parentPlace &&
				segs[2] === place &&
				segs[3] === bounds &&
				segs[4] === page
		},
		// /parentCategory/category/parentPlace/place
		{
			length: 4,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === category &&
				segs[2] === parentPlace &&
				segs[3] === place
		},
		// /parentCategory/category/parentPlace/place/page-n
		{
			length: 5,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === category &&
				segs[2] === parentPlace &&
				segs[3] === place &&
				segs[4] === page
		},
		// /parentCategory/category/parentPlace/bounds
		{
			length: 4,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === category &&
				segs[2] === parentPlace &&
				segs[3] === bounds
		},
		// /parentCategory/category/parentPlace/bounds/page-n
		{
			length: 5,
			validate: segs =>
				segs[0] === parentCategory &&
				segs[1] === category &&
				segs[2] === parentPlace &&
				segs[3] === bounds &&
				segs[4] === page
		}
	].filter(pattern => pattern.length === slugs.length);

	return validPatterns.some(pattern => pattern.validate(slugs));
};

/**
 *
 * @param {string[]} slugs
 * @param {{
 * 	category: {
 * 		slug: string
 * 	},
 * 	parentCategory: {
 * 		slug: string
 * 	},
 * 	parentPlace: {
 * 		slug: string
 * 	},
 * 	place: {
 * 		slug: string
 * 	},
 * 	googlePlace: {
 * 		place_id: string
 * 	},
 * page: number
 * bounds: {
 * 	ne: {
 * 		lat: number,
 * 		lng: number
 * 	},
 * 	sw: {
 * 		lat: number,
 * 		lng: number
 * 	}
 * }
 * userLocation: {
 * 	lat: number,
 * 	lng: number
 * }
 * }} search
 * @param {{
 *	slug: string
 * }} category
 * @returns {boolean}
 */
export const matchesAllowedPattern = (
	slugs = [],
	search = {},
	category,
	wholeCountrySlug
) => {
	if (category) {
		const isCategoryPage = category?.slug === slugs[0] && slugs.length === 1;
		const isCategoryCountryPage =
			category?.slug === slugs[0] &&
			slugs.length === 2 &&
			slugs[1] === wholeCountrySlug;

		return isCategoryPage || isCategoryCountryPage;
	}

	if (!search) return false;

	const isValidSlug = slugs.every(
		slug =>
			slug === search.category?.slug ||
			slug === search.parentCategory?.slug ||
			slug === search.parentPlace?.slug ||
			slug === search.place?.slug ||
			slug === `gp_${search.googlePlace?.place_id}` ||
			slug === `page-${search.page}` ||
			isBounds(slug) ||
			isUserLocation(slug)
	);
	if (!isValidSlug) return false;

	return isValidUrlOrder(search, slugs);
};

/**
 * @param {string} slug
 * @returns {string[] | null}
 */
export const isBounds = slug => {
	return slug.match(/^@/);
};

/**
 * @param {string} slug
 * @returns {string[] | null}
 */
export const isUserLocation = slug => {
	return slug.match(/^around_me@/);
};
