import { noop } from '@planity/helpers';
import { PhoneVerificationForm } from '../phone_verification_form';
import { AuthErrors } from './auth_errors';
import React, { useEffect, useRef } from 'react';
import { useAuth } from '@planity/components';
import { useAuthHooks } from './useAuthHooks';
import { FormComponent } from './forms/form_component';
import { AskingMissingDataForm } from './forms/asking_missing_data_form';
import { ContextForm } from './context_form';
import { MissingPhoneNumberForm } from './forms/missing_phone_number_form';
import { withForm } from './withForm';
import { Modal, Spinner } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './authentication.module.scss';
import { isNativeApp } from '@planity/webview';
import { useHistory } from 'react-router-dom';

/** Sign in authentication type */
export const SIGN_IN = 'SIGN_IN';
/** Sign up authentication type */
export const SIGN_UP = 'SIGN_UP';
/** Forgotten password authentication type */
export const FORGOTTEN_PASSWORD = 'FORGOTTEN_PASSWORD';

export const BACK_TO_SIGN_IN = 'BACK_TO_SIGN_IN';

export const AuthForm = withForm(props => {
	const history = useHistory();
	const phoneInput = useRef(null);
	const { fromAccount, children, hideWarnings, authBooking } = props;
	const { user, isVerified, isLoading } = useAuth();
	const { formValues, context, machineState, dispatch, imageUrl } =
		useAuthHooks(props);

	const { pending, defaultCountry, setAuthType, authType } = formValues;
	useStyles(styles);
	const classes = classNames.bind(styles)({
		authentication: true
	});
	const { email, phoneNumber, fromSocial, error, success, warning } = context;

	useEffect(() => {
		// On pop, recover from history state. See PLAN-8863
		if (isNativeApp && !authBooking) {
			const listener = () =>
				setAuthType(history.location?.state?.authType || SIGN_IN);
			window.addEventListener('popstate', listener);
			return () => window.removeEventListener('popstate', listener);
		}
	}, [history, isNativeApp, authBooking]);

	useEffect(() => {
		if (isNativeApp && !authBooking) {
			if (
				history.location?.state?.authType !== authType &&
				authType !== SIGN_IN
			) {
				// Saving auth type in history state, so we can override popstate
				history.push({
					...history.location,
					state: { authType }
				});
			}
		}
	}, [authType, history, authBooking, isNativeApp]);

	const renderSwitch = () => {
		switch (machineState) {
			case 'askingMissingData':
			case 'checkingUserDataEdition':
			case 'checkingPhoneNumberConfirmation':
				if (user && !fromSocial && phoneNumber && !isVerified) {
					return (
						<PhoneVerificationForm
							dispatch={dispatch}
							navigateLogState={noop}
							phoneNumber={phoneNumber}
						/>
					);
				}
				if (fromAccount) {
					return (
						<React.Fragment>
							<AuthErrors
								error={error}
								hideWarnings={hideWarnings}
								success={success}
								warning={warning}
							/>
							{authBooking ? (
								<Modal hasCloseBtn={false} isOpen>
									<AskingMissingDataForm>{children}</AskingMissingDataForm>
								</Modal>
							) : (
								<AskingMissingDataForm>{children}</AskingMissingDataForm>
							)}
						</React.Fragment>
					);
				}
				return AskingMissingDataForm({
					children,
					context,
					pending,
					dispatch,
					defaultCountry,
					phoneInput,
					email
				});

			case 'checkingPhoneNumberInput':
			case 'missingPhoneNumber':
				return (
					<React.Fragment>
						<AuthErrors
							error={error}
							hideWarnings={hideWarnings}
							success={success}
							warning={warning}
						/>
						<Modal isOpen>
							<MissingPhoneNumberForm
								defaultCountry={defaultCountry}
								dispatch={dispatch}
								hideWarnings={hideWarnings}
								pending={pending}
								phoneInput={phoneInput}
								phoneNumber={phoneNumber}
							/>
						</Modal>
					</React.Fragment>
				);

			case 'setTemporaryUserInDatabase':
			case 'creatingUserInDatabase':
			case 'updatingUserInDatabase':
				return (
					<div className={styles.spinner}>
						<Spinner />
					</div>
				);

			default:
				return (
					<React.Fragment>
						<AuthErrors
							error={error}
							hideWarnings={hideWarnings}
							success={success}
							warning={warning}
						/>
						<FormComponent />
					</React.Fragment>
				);
		}
	};

	return (
		<ContextForm.Provider value={{ ...formValues, imageUrl }}>
			<div className={classes}>
				{isLoading ? (
					<div className={styles.spinner}>
						<Spinner />
					</div>
				) : (
					renderSwitch()
				)}
			</div>
		</ContextForm.Provider>
	);
});
