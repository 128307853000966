import useStyles from 'isomorphic-style-loader/useStyles';
import { useRef, useEffect, forwardRef } from 'react';
import classNames from 'classnames/bind';
import styles from './input.module.scss';
import { Icon } from '@planity/ui';
import { useUikit } from '@planity/ui/shared/useUikit';
import { noop } from '@planity/helpers';

export const InputWithRef = forwardRef((props, ref) => (
	<Input forwardedRef={ref} {...props} />
));

export function Input({
	autoFocus = false,
	autoComplete = 'off',
	label,
	placeholder,
	defaultValue,
	name,
	icon,
	isDisabled = false,
	isRequired = false,
	type = 'text',
	error = false,
	errorMessage = 'Invalid field',
	className,
	onChange = noop,
	value,
	forwardedRef,
	id,
	onFocus,
	onBlur,
	suffix,
	children,
	inputStyle,
	...props
}) {
	useStyles(styles);
	const input = useRef();

	// States

	const { dispatchError, isFocused, hasError, setIsFocused } = useUikit();
	// Classes

	const classes = classNames.bind(styles)({
		input: true
	});

	// useEffect
	useEffect(() => {
		dispatchError(error);
	}, [dispatchError, error]);
	// Render

	const renderLabel = label && (
		<span id='label-input-field' className={styles.label}>
			{label} {isRequired && '*'}
		</span>
	);

	const renderField = (
		<div
			id='input-field'
			className={classNames.bind(styles)(
				{
					container: true,
					isDisabled: isDisabled,
					isFocused: isFocused,
					isInvalid: hasError,
					[className]: true
				},
				'planity-inputs'
			)}
		>
			{icon && <Icon className={styles.icon} icon={icon} />}
			<input
				style={inputStyle}
				id={id || 'input'}
				type={type}
				ref={forwardedRef}
				name={name}
				onFocus={onFocus ? onFocus : () => setIsFocused(true)} // condition used for phone input onFocus
				onBlur={onBlur ? onBlur : () => setIsFocused(false)} // condition used for phone input onBlur
				disabled={isDisabled}
				required={isRequired}
				className={styles.field}
				placeholder={placeholder}
				onChange={e => onChange(e.currentTarget.value)}
				value={value || ''}
				{...props}
				autoFocus={autoFocus}
				autoComplete={autoComplete}
			/>
			{!!children && <div className={styles.innerMessage}>{children}</div>}
			{suffix && <span className={styles.suffix}>{suffix}</span>}
		</div>
	);
	const renderError = !isDisabled &&
		hasError &&
		errorMessage && ( // !isFocused &&
			<span id='error-input-field' className={styles.error}>
				{errorMessage}
			</span>
		);

	return (
		<div className={classes} id='input-field-comp'>
			<label>{renderLabel}</label>
			{renderField}
			{renderError}
		</div>
	);
}
