import React, { useState } from 'react';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import {
	isValidEmail,
	checkIfChildAlreadyExists,
	useHotjarTag
} from '@planity/helpers';
import { useTranslation } from '@planity/localization';
import { useModal } from '@planity/ui/context';
import { Button, Checkbox, Input } from '@planity/ui';
import { PhoneInput, Localize, useErrorMessages } from '@planity/components';
import { INVALID_FORMAT_ERROR } from '../../authentication';

import styles from './set_child_modal.module.scss';

const hasErrors = errors => {
	return Object.values(errors).some(value => value);
};

/**
 * @component modal to add or edit a child. If no child is passed, it means we are adding a new child
 *
 * @param {Object} initialChild - if no child is passed, it means we are adding a new child
 * @param {Object} parent - parent information
 * @param {Function} onConfirmClick - function to create or update a child
 * @param {Array} userChildren - list of children
 * @returns {React.Component}
 */
export const SetChildModal = ({
	initialChild = null,
	parent,
	onConfirmClick,
	userChildren
}) => {
	useStyles(styles);
	const { t } = useTranslation();
	const { pushMessage } = useErrorMessages();
	const { closeModal } = useModal();
	const classes = classNames.bind(styles)({
		addChildren: true
	});

	const [isLoading, setIsLoading] = useState(false);
	const [child, setChild] = useState({
		...initialChild,
		firstName: initialChild?.firstName || '',
		lastName: initialChild?.lastName || '',
		email: initialChild?.email || '',
		phone: initialChild?.phone || ''
	});

	const [inputError, setInputError] = useState({
		authorized: false,
		firstName: false,
		lastName: false,
		email: false,
		phone: false
	});
	const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
	const [isAuthorizedToBookForChild, setIsAuthorizedToBookForChild] =
		useState(false);

	// add hotjar tag for child creation from Book appointment or My children pages
	useHotjarTag(!initialChild ? 'bookForSomeone-AddChild' : '');

	const confirmButtonLabel = t(
		initialChild ? 'children.modal.actions.edit' : 'children.modal.actions.add'
	);

	/**
	 *  Validates input fields and sets input errors.
	 *
	 * @returns {Object} For each input return true if there is an error
	 */
	const validateInput = () => {
		const errors = {
			authorized: !initialChild && !isAuthorizedToBookForChild,
			firstName: !child.firstName.trim(),
			lastName: !child.lastName.trim(),
			email: !!child.email && !isValidEmail(child.email),
			phone: !isValidPhoneNumber
		};
		setInputError(errors);
		return errors;
	};

	const handleConfirmClick = async () => {
		if (hasErrors(validateInput())) return;
		const newOrUpdatedChild = {
			...child,
			firstName: child.firstName.trim(),
			lastName: child.lastName.trim(),
			name: `${child.firstName.trim()} ${child.lastName.trim()}`,
			email: child.email?.toLowerCase()?.trim() || parent.email,
			phone: child.phone || parent.phone,
			parentId: parent.parentId
		};

		const userChildrenToCheck = initialChild
			? userChildren.filter(child => child.childId !== initialChild.childId)
			: userChildren;

		const isChildAlreadyExisting = await checkIfChildAlreadyExists({
			userChildren: userChildrenToCheck,
			newChild: newOrUpdatedChild,
			parent
		});

		if (isChildAlreadyExisting) {
			pushMessage({
				message: t(`children.alreadyExistError.${isChildAlreadyExisting}`),
				type: 'error',
				createdAt: Date.now()
			});
			return;
		}
		setIsLoading(true);

		await onConfirmClick(newOrUpdatedChild);
		closeModal();

		setIsLoading(false);
	};

	return (
		<div className={classes}>
			<div>
				<div className={styles.title}>
					<Localize
						text={
							initialChild
								? 'children.modal.editTitle'
								: 'children.actions.addTitle'
						}
					/>
				</div>
				<p className={styles.subtitle}>{t('children.modal.subtitle')}</p>
				<div className={styles.row}>
					<Input
						autoFocus={true}
						error={inputError.firstName}
						errorMessage={
							inputError.firstName && t('children.modal.errors.firstname')
						}
						id={'manage-children-firstname'}
						label={t('children.modal.inputLabel.firstname')}
						value={child.firstName}
						onChange={firstName =>
							setChild(prevState => ({ ...prevState, firstName }))
						}
					/>
					<Input
						error={inputError.lastName}
						errorMessage={
							inputError.lastName && t('children.modal.errors.lastname')
						}
						id={'manage-children-lastname'}
						label={t('children.modal.inputLabel.lastname')}
						value={child.lastName}
						onChange={lastName =>
							setChild(prevState => ({ ...prevState, lastName }))
						}
					/>
				</div>
				<p className={styles.explication}>{t('children.modal.explication')}</p>
				<div className={styles.row}>
					<Input
						error={inputError.email}
						errorMessage={inputError.email && t('children.modal.errors.email')}
						id={'manage-children-email'}
						label={t('children.modal.inputLabel.email')}
						value={child.email}
						onChange={email => setChild(prevState => ({ ...prevState, email }))}
					/>

					<PhoneInput
						data={{
							errors: inputError.phone && [
								{ name: 'phoneNumber', code: INVALID_FORMAT_ERROR }
							]
						}}
						dropdownPlacement={'top'}
						id='manage-children-phone'
						isRequired={false}
						label={t('children.modal.inputLabel.phone')}
						styleError={inputError.phone}
						value={child.phone}
						onChange={(phoneNumber, isValidPhoneNumber) => {
							setIsValidPhoneNumber(isValidPhoneNumber);
							setChild(prevState => ({ ...prevState, phone: phoneNumber }));
						}}
					/>
				</div>
				{!initialChild && (
					<div className={styles.authorized}>
						<Checkbox
							checked={isAuthorizedToBookForChild}
							errorMessage={
								inputError.authorized &&
								t('children.modal.errors.authorization')
							}
							id={'manage-children-checkbox'}
							label={<>{t('children.modal.authorization')}</>}
							onChange={() =>
								setIsAuthorizedToBookForChild(!isAuthorizedToBookForChild)
							}
						/>
					</div>
				)}
			</div>
			<div className={styles.actions}>
				<Button
					id='manage-children-cancel'
					isFullMobile
					label={t('actions.cancel')}
					type='secondary'
					onClick={closeModal}
				/>
				<Button
					id='manage-children-confirm'
					isFullMobile
					isLoading={isLoading}
					label={confirmButtonLabel}
					onClick={handleConfirmClick}
				/>
			</div>
		</div>
	);
};
