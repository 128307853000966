import {
	useLocalization,
	useLocalizedRoutes,
	useTranslation
} from '@planity/localization';
import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import classNames from 'classnames/bind';
import styles from './home_growth.module.scss';
import { NavButton, Container, Title, Button, useSwiper } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';

export function HomeGrowth() {
	useStyles(styles);
	const [progress, setProgress] = useState(false);
	const [current, setCurrent] = useState(0);
	const { routes } = useLocalizedRoutes();
	const { t } = useTranslation();
	const { onTouchEnd, onTouchMove, onTouchStart } = useSwiper();

	const data = t('landing.pros');

	const { locale } = useLocalization();
	const isGermany = locale === 'de-DE';
	const queryParameterCampaign = 'utm_source=planity&utm_campaign=menumobile';

	const content = useRef(null);

	const growthData = useMemo(() => Object.values(data), [data]);

	const goTo = useCallback(
		(index = 0) => {
			const total = growthData.length;
			index = ((index % total) + total) % total;
			setCurrent(index);
			setProgress(false);
		},
		[growthData.length]
	);

	const next = useCallback(() => {
		goTo(current + 1);
	}, [goTo, current]);

	const previous = useCallback(() => {
		goTo(current - 1);
	}, [current, goTo]);

	useEffect(() => {
		if (window.innerWidth > 767) return undefined;

		requestAnimationFrame(() => {
			setProgress(true);
		});
		const timer = setTimeout(() => {
			next();
		}, 8000);

		return () => clearTimeout(timer);
	}, [current, next, progress, previous]);

	const cx = classNames.bind(styles);
	const classes = classNames.bind(styles)({
		homeGrowth: true
	});

	return (
		<div className={classes}>
			<Container noStupidMarginBottom>
				<Title
					label={t('landing.headline.growth')}
					title={t('landing.description.pros')}
				/>
				<div className={styles.button}>
					<Button
						label={t('nav.becomePartner')}
						size={'mobile'}
						isFullWidth
						isExternal
						to={
							isGermany
								? `${routes.becomePartnerBasePath}?${queryParameterCampaign}`
								: `${routes.becomePartner}?${queryParameterCampaign}`
						}
						obfuscateLink
					/>
				</div>

				<div
					ref={content}
					className={styles.grid}
					onTouchStart={onTouchStart}
					onTouchMove={onTouchMove}
					onTouchEnd={() => onTouchEnd(next, previous)}
				>
					{data
						? growthData.map(({ title, content }, i) => (
								<div
									key={i}
									className={cx({
										item: true,
										currentItem: i === current
									})}
								>
									<div className={styles.content}>
										<div>
											<span className={styles.number}>{title}</span>
											<p className={styles.text}>{content}</p>
										</div>
										<Button
											className={styles.itemButton}
											label={t('nav.becomePartner')}
											size='large'
											isExternal
											to={
												isGermany
													? `${routes.becomePartnerBasePath}?${queryParameterCampaign}`
													: `${routes.becomePartner}?${queryParameterCampaign}`
											}
											obfuscateLink
										/>
									</div>
								</div>
						  ))
						: null}
				</div>

				<div className={styles.actions}>
					<NavButton
						onClick={previous}
						label={t('common.previous')}
						isMirrored={true}
					/>
					<NavButton
						onClick={next}
						label={t('common.next')}
						progress={progress}
					/>
				</div>
			</Container>
		</div>
	);
}
