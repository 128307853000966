import React from 'react';
import { useCitiesSelection } from '@planity/helpers';
import { useTranslation } from '@planity/localization';
import { BottomLinks } from '../../ui';
import styles from './cities_selection.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';

export const CitiesSelection = ({ baseSearch }) => {
	useStyles(styles);
	const { t } = useTranslation();
	const citiesSelection = useCitiesSelection(baseSearch);

	return (
		<BottomLinks
			className={styles.linkWrapper}
			getPath={link => link.link || ''}
			getText={label => label?.label || ''}
			links={citiesSelection}
			title={t('category.relatedSearchesCity.title', {
				baseCategoryName: baseSearch?.parentCategory?.name
			})}
		/>
	);
};
