// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".appointment_user-module_appointmentUser-Ahb7H{margin-bottom:10px}@media(max-width: 767px){.appointment_user-module_appointmentUser-Ahb7H{margin-top:44px}}.appointment_user-module_loadingContainer-JbFtK{display:flex;justify-self:center;align-items:center;justify-content:center;height:5vh}", "",{"version":3,"sources":["webpack://./../../packages/components/book_appointment/user/appointment_user.module.scss"],"names":[],"mappings":"AAEA,+CACC,kBAAA,CACA,yBAFD,+CAGE,eAAA,CAAA,CAGF,gDACC,YAAA,CACA,mBAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA","sourcesContent":["@use '@planity/ui/styles';\n\n.appointmentUser {\n\tmargin-bottom: 10px;\n\t@media (max-width: #{styles.$tablet - 1px}) {\n\t\tmargin-top: 44px;\n\t}\n}\n.loadingContainer {\n\tdisplay: flex;\n\tjustify-self: center;\n\talign-items: center;\n\tjustify-content: center;\n\theight: 5vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"appointmentUser": "appointment_user-module_appointmentUser-Ahb7H",
	"loadingContainer": "appointment_user-module_loadingContainer-JbFtK"
};
module.exports = ___CSS_LOADER_EXPORT___;
