import { clickAndCollectMachine } from '@planity/components';
import { useMachine } from '@xstate/react';
import React, { createContext, useContext } from 'react';

export const ClickAndCollectContext = createContext({
	functions: {},
	service: null,
	state: null
});
export const useClickAndCollectContext = () =>
	useContext(ClickAndCollectContext);

export function ClickAndCollectProvider({ children }) {
	const [cacState, cacSend, cacService] = useMachine(clickAndCollectMachine, {
		devTools: process.env.PLANITY_ENV === 'lab'
	});

	const addProduct = (item, itemId, businessId) => {
		const products = [
			{
				...item,
				id: itemId,
				quantity: 1,
				stock: item.quantity // To know how much there is in stock inside the flow
			}
		];

		cacSend('ADD_PRODUCT', {
			products,
			businessId
		});
	};

	const authenticated = (flowSend, userId, isClickAndShop = false) => {
		flowSend({
			type: 'AUTHENTICATED',
			userId,
			isClickAndShop
		});
	};

	const saveAddresses = (
		flowSend,
		deliveryAddress = null,
		billingAddress = null
	) => {
		flowSend({
			type: 'UPDATE_ADDRESSES',
			deliveryAddress,
			billingAddress
		});
	};

	const logout = flowSend => {
		flowSend('LOGOUT');
	};

	const updatePaymentMethod = (flowSend, paymentMethod) => {
		flowSend({
			type: 'UPDATE_PAYMENT_METHOD',
			paymentMethod
		});
	};

	const validateCreditCard = flowSend => {
		flowSend('CREDIT_CARD_OK');
	};

	const updatePaymentData = (
		flowSend,
		{ paymentMethod: newPaymentMethodFromElement, paymentIntentId }
	) => {
		flowSend({
			type: 'UPDATE_PAYMENT_DATA',
			paymentMethod: newPaymentMethodFromElement,
			paymentIntentId
		});
	};

	const pay = (flowSend, user) => {
		flowSend({ type: 'PAY', user });
	};

	const deleteArticle = (flowSend, flowState, product) => {
		const { products } = flowState.context.data;

		const newCart = products.filter(article => article.id != product.id);
		flowSend({
			type: 'UPDATE_PRODUCTS',
			products: newCart
		});
	};

	const updateProductInCart = (flowSend, flowState, product, quantity) => {
		if (quantity <= 0) {
			return deleteArticle(flowSend, flowState, product);
		}

		const { products } = flowState.context.data;

		const newCart = products.reduce((list, article) => {
			if (article.id === product.id) {
				// Update its quantity
				const newProduct = { ...product, quantity: quantity };
				return [...list, newProduct];
			}

			return [...list, article];
		}, []);

		flowSend({
			type: 'UPDATE_PRODUCTS',
			products: newCart
		});
	};

	const addProductToCart = (flowSend, flowState, productId, product) => {
		let { products } = flowState.context.data;
		const existingProductIndex = products.findIndex(x => x.id === productId);

		if (existingProductIndex > -1) {
			products = products.map(product => {
				if (product.id === productId) {
					return {
						...product,
						quantity: product.quantity + 1
					};
				}
				return product;
			});
		} else {
			const newProduct = {
				...product,
				id: productId,
				quantity: 1,
				stock: product.quantity
			};

			products = [...products, newProduct];
		}

		const cartWasEmpty = products.length === 1 && products[0].quantity === 1;
		const event = cartWasEmpty ? 'ADD_PRODUCT' : 'UPDATE_PRODUCTS';

		flowSend({
			type: event,
			products
		});
	};

	const updateIsClickAndShop = (flowSend, isClickAndShop) => {
		flowSend({
			type: 'UPDATE_IS_CLICK_AND_SHOP',
			isClickAndShop
		});
	};

	const updateShippingFees = (flowSend, shippingFees) => {
		flowSend({
			type: 'UPDATE_SHIPPING_FEES',
			shippingFees
		});
	};

	const cancelFlow = flowSend => {
		flowSend('CANCEL_FLOW');
	};

	const confirmPaymentRedirectSuccess = (flowSend, paymentIntentId) => {
		flowSend({ type: 'CONFIRM_PAYMENT_REDIRECT_SUCCESS', paymentIntentId });
	};

	const reinjectData = contextData => {
		cacSend('REINJECT_DATA', {
			contextData
		});
	};

	// used in multipage minisite to reset redirect state after redirection
	const resetDisplayConfirmationPage = () => {
		cacSend('RESET_DISPLAY_CONFIRMATION_PAGE');
	};

	const resetPayError = flowSend => {
		flowSend('RESET_PAY_ERROR');
	};
	const setIsRedirect = (flowSend, isRedirectValue) => {
		flowSend({ type: 'SET_IS_REDIRECT', isRedirectValue });
	};

	const functions = {
		addProduct,
		authenticated,
		logout,
		updatePaymentMethod,
		updatePaymentData,
		validateCreditCard,
		pay,
		updateProductInCart,
		deleteArticle,
		addProductToCart,
		cancelFlow,
		confirmPaymentRedirectSuccess,
		saveAddresses,
		updateIsClickAndShop,
		updateShippingFees,
		reinjectData,
		resetPayError,
		resetDisplayConfirmationPage,
		setIsRedirect
	};

	return (
		<ClickAndCollectContext.Provider
			value={{ functions, service: cacService, state: cacState }}
		>
			{children}
		</ClickAndCollectContext.Provider>
	);
}

export const withClickAndCollectMachine = Component => props =>
	(
		<ClickAndCollectContext.Consumer>
			{data => <Component {...props} cacContext={data} />}
		</ClickAndCollectContext.Consumer>
	);
