import React, { useEffect, useState } from 'react';
import {
	ClickAndCollectContext,
	DeliverySentence,
	ProductsList
} from '@planity/components';
import { Radio, BusinessTitle } from '@planity/ui';
import styles from './click_and_collect.module.scss';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation } from '@planity/localization';
import { ONLINE_SHOP_BLOC, useTheme } from '@planity/context/theme_context';

export const ClickAndCollect = ({
	business,
	businessId,
	displayClickAndCollect,
	displayClickAndShop,
	clickAndCollectProducts,
	clickAndShopProducts,
	onSubmit,
	needToFetch = false,
	isAppointmentTitle
}) => {
	const { isDark: hasDarkBackground } = useTheme();
	const isDark = hasDarkBackground?.[ONLINE_SHOP_BLOC];
	const { isDarkThemeWidget } = useTheme();

	const { t } = useTranslation();
	useStyles(styles);
	const classes = classNames.bind(styles)({
		clickAndCollect: true,
		isDark,
		isDarkThemeWidget
	});

	const [isClickAndCollect, setIsClickAndCollect] = useState(
		displayClickAndCollect && displayClickAndShop
			? false
			: displayClickAndCollect
	);
	const [isClickAndShop, setIsClickAndShop] = useState(
		displayClickAndCollect && displayClickAndShop ? true : displayClickAndShop
	);
	const defaultShippingExplanationSentence = t(
		'clickAndCollect.deliverySentence',
		{
			firstDayDeliveryEstimation: '3',
			lastDayDeliveryEstimation: '5',
			parcelCarrier: 'La Poste'
		}
	);
	const shippingExplanationSentence =
		business?.settings?.clickAndShop?.explanationSentence ||
		defaultShippingExplanationSentence;

	useEffect(() => {
		if (displayClickAndCollect !== null && displayClickAndShop !== null) {
			setIsClickAndCollect(
				displayClickAndCollect && displayClickAndShop
					? false
					: displayClickAndCollect
			);
			setIsClickAndShop(
				displayClickAndCollect && displayClickAndShop
					? true
					: displayClickAndShop
			);
		}
	}, [displayClickAndCollect, displayClickAndShop]);

	return (
		<div
			className={`${classes} planity_ui_appointment_background planity_click_and_collect_container`}
		>
			<ClickAndCollectContext.Consumer>
				{cacContext => (
					<>
						{!isAppointmentTitle && (
							<div>
								<BusinessTitle
									Component={'h2'}
									isDark={isDark}
									isDarkThemeWidget={isDarkThemeWidget}
									path={'clickAndCollect.title'}
								/>
								{displayClickAndShop && (
									<span className={styles.subtitle}>
										{t('clickAndCollect.subTitle')}
									</span>
								)}
							</div>
						)}
						{displayClickAndCollect && displayClickAndShop && (
							<div className={styles.options}>
								<div className={styles.option}>
									<Radio
										id='delivery-radio-button'
										isChecked={isClickAndShop}
										label={t('clickAndCollect.clickAndShop.title')}
										name='option'
										text={
											<DeliverySentence
												shippingExplanationSentence={
													shippingExplanationSentence
												}
											/>
										}
										onChange={() => {
											setIsClickAndCollect(false);
											setIsClickAndShop(true);
										}}
									/>
								</div>
								<div className={styles.option}>
									<Radio
										id='click-and-collect-radio-button'
										isChecked={isClickAndCollect}
										label={t('clickAndCollect.clickAndCollect.title')}
										name='option'
										text={t('clickAndCollect.clickAndCollectSubtext')}
										onChange={() => {
											setIsClickAndCollect(true);
											setIsClickAndShop(false);
										}}
									/>
								</div>
							</div>
						)}
						<ProductsList
							business={business}
							businessId={businessId}
							clickAndCollectProducts={clickAndCollectProducts}
							clickAndShopProducts={clickAndShopProducts}
							isClickAndCollect={isClickAndCollect}
							isClickAndShop={isClickAndShop}
							isCollapsed
							isDark={isDark}
							needToFetchProducts={needToFetch}
							onAddProduct={(itemId, item) => {
								cacContext.functions.addProduct(item, itemId, businessId);
								onSubmit({
									isClickAndCollect,
									isClickAndShop,
									shippingExplanationSentence
								});
							}}
						/>
					</>
				)}
			</ClickAndCollectContext.Consumer>
		</div>
	);
};
