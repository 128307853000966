import { Children, Component } from 'react';
import { withRouter } from 'react-router-dom';

export default withRouter(
	class TrackPageviews extends Component {
		componentDidMount() {
			this.log();
		}
		componentDidUpdate(prevProps) {
			if (this.props.location.pathname !== prevProps.location.pathname) {
				this.log();
			}
		}
		render() {
			return Children.only(this.props.children);
		}
		log() {
			if (process.env.BROWSER) {
				if (process.env.USE_GOOGLE_ANALYTICS) {
					if (window.ga) {
						window.ga('set', 'page', this.props.location.pathname);
						window.ga('send', 'pageview');
					}
				} else if (process.env.DEBUG_ANALYTICS) {
					console.log(
						`%c# Tracking google analytics pageview: ${this.props.location.pathname}`,
						'color:#fb8c00;font-weight:bold;'
					);
				}
				if (process.env.USE_GOOGLE_ANALYTICS_V4) {
					if (window.gtag) {
						window.gtag('event', 'page_view');
					}
				}
				if (process.env.USE_FACEBOOK_TRACKING) {
					if (window.fbq) {
						window.fbq('track', 'PageView');
					}
				} else if (process.env.DEBUG_ANALYTICS) {
					console.log(
						`%c# Tracking facebook pageview: ${this.props.location.pathname}`,
						'color:#29487d;font-weight:bold;'
					);
				}
				if (process.env.USE_PINTEREST_TRACKING) {
					if (window.pintrk) {
						window.pintrk('page');
					}
				} else if (process.env.DEBUG_ANALYTICS) {
					console.log(
						`%c# Tracking pinterest pageview: ${this.props.location.pathname}`,
						'color:#29487d;font-weight:bold;'
					);
				}
				if (process.env.USE_GOOGLE_CONVERSION_TRACKING) {
					if (window.google_trackConversion) {
						window.google_trackConversion({
							google_conversion_id: parseInt(
								process.env.GOOGLE_ADWORDS_REMARKETING_CONVERSION_ID
							),
							google_custom_params: window.google_tag_params,
							google_remarketing_only: true
						});
					}
				} else if (process.env.DEBUG_ANALYTICS) {
					console.log(
						'%c# Tracking adwords conversion',
						'color:rgb(42, 146, 94);font-weight:bold;'
					);
				}
			}
		}
	}
);
