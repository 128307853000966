import { Component } from 'react';
import credentials from '@planity/credentials';
import fetchPonyfill from 'fetch-ponyfill';
import isEqual from 'lodash/isEqual';
const { fetch } = fetchPonyfill();

export default class LambdaInvocation extends Component {
	state = {
		isLoading: true,
		error: null,
		response: null
	};
	componentDidMount() {
		this.invokeLambda();
	}
	componentDidUpdate(prevProps) {
		if (
			this.props.lambda !== prevProps.lambda ||
			!isEqual(this.props.payload, prevProps.payload)
		) {
			this.invokeLambda();
		}
	}
	componentWillUnmount() {
		this.cancelInvocation();
	}
	render() {
		return this.props.children(this.state);
	}
	invokeLambda() {
		this.cancelInvocation();
		const { lambda, payload } = this.props;
		this.setState(
			{
				isLoading: true,
				error: null
			},
			() => {
				const invocationContext = { lambda, payload };
				this.invocationContext = invocationContext;
				fetch(`${credentials.AWS_API_ENDPOINT}/${lambda}`, {
					method: 'post',
					mode: 'cors',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(payload)
				})
					.then(res => res.json())
					.then(response => {
						if (this.invocationContext === invocationContext) {
							this.setState({
								isLoading: false,
								error: null,
								response
							});
						}
					})
					.catch(error => {
						if (this.invocationContext === invocationContext) {
							this.setState({
								isLoading: false,
								error,
								response: null
							});
						}
					});
			}
		);
	}
	cancelInvocation() {
		this.invocationContext = null;
	}
}
