import { getStepInfo } from '@planity/helpers';
import { differenceInDays, parseISO } from 'date-fns';

/**
 * Reviews has expired if it's been more than 6 days and if it has not been yet reviewed.
 * @param appointment
 * @return {boolean}
 */
export function reviewHasExpired(appointment) {
	const has6DaysPassed =
		differenceInDays(new Date(), parseISO(appointment.start)) > 6;
	return has6DaysPassed && !appointment.review;
}
/**
 * Creates a string of every step of the appointment.
 * Ex: "Shampoing + coiffure + balayage + Couleur"
 * @param appointment {Object}
 * @param business {Object}
 * @param t {function} translate function
 * @return {string}
 */
export function getAppointmentDetails(appointment, business, t) {
	return (
		[
			...new Set(
				Object.values(appointment.sequence)
					.map(step => getStepInfo(step, business, t)?.serviceName)
					.filter(x => x)
			)
		].join(' + ') || t('business.reviews.itemReviewed')
	);
}
