import credentials from '@planity/credentials';
import classNames from 'classnames/bind';
import React, { useRef, useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import capitalize from 'lodash/capitalize';
import { breakpoints, colors } from '@planity/theme';
import { withGoalEvents } from '@planity/helpers/analytics';
import { AlgoliaSearch } from '../data';
import { Layout, PageSection, SplitPageSearch } from '../layout';
import Metadata from './metadata';
import { SearchPageMap } from './search_page_map';
import Main from './main';
import { Content } from './content';
import { NoResults } from './no_results';
import { useFilters } from '../ui/filter/context';
import { withRouter } from 'react-router-dom';
import {
	FULL_SEARCHBAR_HEADER_HEIGHT,
	OFFSET_SCROLLTO_BUSINESS
} from '../../style';
import {
	safeRead,
	scrollTo,
	usePrevious,
	withHotjarTag
} from '@planity/helpers';
import { useLocalization, useLocalizedRoutes } from '@planity/localization';
import { Title } from './title';
import isEqual from 'lodash/isEqual';
import { AvailabilitiesProvider } from './availabilities_provider';
import { Container, SearchBarHeader, SearchBar } from '@planity/ui';
import stylesScss from './index.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { isNativeApp } from '@planity/webview';

export const SearchPage = withHotjarTag(
	'searchPage',
	withRouter(
		withGoalEvents(({ search, location, publishSearchPageEvent, history }) => {
			useStyles(stylesScss);
			const classes = classNames.bind(stylesScss);
			const { countries, locale } = useLocalization();
			const { routes } = useLocalizedRoutes();
			const { filters: userFilter } = useFilters();
			const [isExpanded, setIsExpanded] = useState(false);
			const date = userFilter.data.date;
			const hasNoIndex = !!(search.googlePlace || search.userLocation);

			const resultsRef = useRef();
			const wrap = useRef();

			const [hoveredBusinessId, setHoveredBusinessId] = useState(null);
			const [algoliaIndex, setAlgoliaIndex] = useState(
				credentials.BUSINESSES_INDEX
			);

			/**
			 *
			 * @param sort {number} uses id in `kindOfFilter` in
			 *   apps/website/app/components/ui/filter/filterHelpers.js
			 * @returns {string} an algolia index name
			 */
			const getAlgoliaIndexName = ({ sort }) => {
				switch (sort) {
					case 1:
						return credentials.BUSINESSES_BY_AVERAGE_DESC_INDEX;
					case 2:
						return credentials.BUSINESSES_BY_AVERAGE_ASC_INDEX;
					case 3:
						return credentials.BUSINESSES_BY_RATING_ASC_INDEX;
					case 0:
					default:
						return credentials.BUSINESSES_INDEX;
				}
			};

			useEffect(() => {
				if (publishSearchPageEvent) {
					publishSearchPageEvent();
				}
			}, []);

			const [pageSearch, setPageSearch] = useState(search.page || 1);
			const [isMap, setIsMap] = useState(false);

			useEffect(() => {
				// reset page if new date, update page with search if no date selected (follow pagination)
				const page = date ? 1 : search.page || 1;
				setPageSearch(page);
			}, [date, search]);

			// need to store previous search value like an old class component because the effect triggers even if search hasn't really changed
			// maybe because the object is reassigned each time but with same values. Old deep object comparing with lodash was then more efficient and closer to truth
			const previousSearch = usePrevious(search);
			useEffect(() => {
				if (search && previousSearch && !isEqual(search, previousSearch)) {
					const node = resultsRef.current;
					if (node) {
						scrollTo({
							node,
							ifNeeded: true,
							offset: FULL_SEARCHBAR_HEADER_HEIGHT + OFFSET_SCROLLTO_BUSINESS
						});
					}
				}
			}, [search, date]);

			// to avoid calling the effect on first render
			const previousFilters = usePrevious(userFilter);
			useEffect(() => {
				if (userFilter && previousFilters) {
					// this is only to reset location and prevent blank page when filter change
					const pathToZero = routes.catchAll({
						search: {
							...search,
							page: 0
						}
					});
					history.push(pathToZero);
				}
			}, [userFilter]);

			useEffect(() => {
				const { sort } = userFilter || {};
				const algoliaIndexName = getAlgoliaIndexName({
					sort
				});
				setAlgoliaIndex(algoliaIndexName);
			}, [userFilter]);
			const handleIsExpanded = value => {
				setIsExpanded(value);
			};
			const toggleMap = () => {
				setIsMap(_isMap => !_isMap);

				if (
					process.env.BROWSER &&
					process.env.USE_GOOGLE_ANALYTICS &&
					!isMap && // isMap here still have its previous value
					window.ga
				) {
					window.ga('send', {
						hitType: 'event',
						eventLabel: 'map',
						eventCategory: 'click_on_map_tab_category',
						eventAction: 'click_on_map_tab'
					});
				}
				if (
					process.env.BROWSER &&
					process.env.USE_GOOGLE_ANALYTICS_V4 &&
					!isMap && // isMap here still have its previous value
					window.gtag
				) {
					window.gtag('event', 'click_on_map_tab', {
						even_label: 'map',
						event_category: 'click_on_map_tab_category'
					});
				}
			};

			const onMarkerClickDesktop = businessId => {
				try {
					const node = document.getElementById(`business-${businessId}`);

					setHoveredBusinessId(businessId);

					if (node) {
						scrollTo({
							node,
							animated: true,
							offset: FULL_SEARCHBAR_HEADER_HEIGHT + OFFSET_SCROLLTO_BUSINESS,
							callback: () => {
								requestAnimationFrame(() => {
									node.focus();
								});
							}
						});
					}
				} catch (e) {
					console.error(e);
				}
			};

			const crumbs = () => {
				return [
					'parentCategory',
					'category',
					'googlePlace',
					'parentPlace',
					'place'
				].reduce(
					(data, key) => {
						const item = search[key];
						if (item) {
							data.search[key] = item;
							data.crumbs.push({
								name: capitalize(item.singular || item.name),
								path: routes.catchAll({
									search: data.search
								})
							});
						}
						return data;
					},
					{ crumbs: [], search: {} }
				).crumbs;
			};

			const { userLocation } = search;

			return (
				<AlgoliaSearch
					attributesToHighlight={[]}
					hitsPerPage={date ? 15 : 20}
					index={algoliaIndex}
					localizeResults
					{...algoliaSearchRefinements(
						search,
						userFilter,
						{ countries },
						pageSearch
					)}
				>
					{({
						data: businessesFromAlgolia,
						isLoading: isLoadingAlgolia,
						pagesCount
					}) => {
						return (
							<AvailabilitiesProvider
								businessesFromAlgolia={businessesFromAlgolia}
								date={date}
								isLoadingAlgolia={isLoadingAlgolia}
								pagesCount={pagesCount}
								pageSearch={pageSearch}
								setPageSearch={setPageSearch}
							>
								{({
									businesses,
									isLoading: isLoadingParent,
									availabilities
								}) => {
									return (
										<IsSearchCategory
											availabilities={availabilities}
											businesses={businesses}
											isLoadingParent={isLoadingParent}
											locale={locale}
											search={search}
											userFilter
										>
											{props => {
												const isLoading = props.isLoading;
												const businessesAreAllDirectories = (
													businesses || []
												).every(
													business =>
														!business.plStatus || business.plStatus < 3
												);
												const showNoResults =
													!isLoading &&
													!(businesses && businesses.length) &&
													!search.bounds;

												return (
													<Layout
														addVirtualHome={true}
														centerNavItems={
															<SearchBar handleIsExpanded={handleIsExpanded} />
														}
														crumbs={crumbs()}
														hasSearchBar
														hasStickyHeader
														headerIsFullWidth
														hideBreadcrumbs={false}
														hideMenu={false}
														hideNavigationItems
														showBottomSearchItems
														skipScrollHandling
													>
														<div css={styles.wrapper}>
															{hasNoIndex && (
																<Helmet>
																	<meta
																		content={'noindex,nofollow'}
																		name={'robots'}
																	/>
																</Helmet>
															)}
															<Metadata search={search} />
															<SearchBarHeader
																businessesAreAllDirectories={
																	businessesAreAllDirectories
																}
																handleIsExpanded={handleIsExpanded}
																isLoading={isLoading}
																isMap={isMap}
																showNoResults={showNoResults}
																toggleMap={toggleMap}
															/>
															{!isMap ? (
																<div
																	className={classes({
																		left: true,
																		isVisible: isMap && !showNoResults,
																		isNativeApp
																	})}
																>
																	{showNoResults ? (
																		<PageSection>
																			<NoResults />
																		</PageSection>
																	) : (
																		<SplitPageSearch.Wrapper>
																			<SplitPageSearch.Section>
																				<div
																					className={classes({
																						resultsContainer: true
																					})}
																				>
																					{businesses?.length > 0 &&
																						businesses?.some(
																							business =>
																								business.plStatus >= 2 ||
																								business._plStatus === 1
																						) && <Title search={search} />}
																					<SplitPageSearch.Main>
																						<Container type={'isSearch'}>
																							<Main
																								availabilities={availabilities}
																								businesses={businesses}
																								date={date}
																								hoveredBusinessId={
																									hoveredBusinessId
																								}
																								isLoading={isLoading}
																								pagesCount={pagesCount}
																								pageSearch={pageSearch}
																								ref={resultsRef}
																								search={search}
																								services={
																									props && props.resultServices
																										? props.resultServices
																										: {}
																								}
																								setPageSearch={setPageSearch}
																								onBusinessHover={
																									setHoveredBusinessId
																								}
																							/>
																							{!date && (
																								<Content
																									isLoading={isLoading}
																									location={location}
																									ref={wrap}
																									search={search}
																								/>
																							)}
																						</Container>
																					</SplitPageSearch.Main>
																				</div>
																			</SplitPageSearch.Section>
																			<SplitPageSearch.Aside>
																				<SearchPageMap
																					businesses={businesses}
																					businessesAreAllDirectories={
																						businessesAreAllDirectories
																					}
																					hoveredBusinessId={hoveredBusinessId}
																					search={search}
																					onBusinessHover={onMarkerClickDesktop}
																				/>
																			</SplitPageSearch.Aside>
																		</SplitPageSearch.Wrapper>
																	)}
																</div>
															) : (
																<div className={stylesScss.right}>
																	<SearchPageMap
																		businesses={businesses}
																		businessesAreAllDirectories={
																			businessesAreAllDirectories
																		}
																		hoveredBusinessId={hoveredBusinessId}
																		isMobile={isMap}
																		search={search}
																		showNoResults={showNoResults}
																		userLocation={userLocation}
																		onBusinessHover={setHoveredBusinessId}
																	/>
																</div>
															)}
														</div>
													</Layout>
												);
											}}
										</IsSearchCategory>
									);
								}}
							</AvailabilitiesProvider>
						);
					}}
				</AlgoliaSearch>
			);
		})
	)
);

const styles = {
	wrapper: {
		backgroundColor: colors.grey.light,
		paddingBottom: 0
	},
	searchBar: {
		marginBottom: '1em',
		[breakpoints.tabletQuery]: {
			'height': '10em',
			'& > div > h1': {
				fontSize: '1.8em'
			}
		}
	},
	tabs: {
		[breakpoints.desktopQuery]: {
			display: 'none'
		}
	},
	title: {
		marginBottom: 0,
		[breakpoints.tabletQuery]: {
			marginBottom: 0
		}
	},
	discreteInput: {
		display: 'none',
		[breakpoints.tabletQuery]: {
			display: 'flex'
		}
	},
	searchMobile: {
		[breakpoints.desktopQuery]: {
			display: 'none'
		},
		[breakpoints.tabletQuery]: {
			display: 'none'
		}
	},
	aroundMeDiv: {
		paddingTop: '15px',
		paddingBottom: '5px'
	}
};

function IsSearchCategory({
	search,
	businesses,
	isLoadingParent,
	children,
	availabilities,
	locale
}) {
	const queries = (businesses || []).reduce((acc, business) => {
		if (!business?.objectID) return acc;

		const businessAvailabilities = availabilities[business.objectID] || {};
		const { firstAvailability } = businessAvailabilities;
		const tag = search?.category?.objectID;

		if (business && !!firstAvailability) {
			if (!tag && ['fr-BE', 'de-DE'].includes(locale)) {
				acc.push(algoliaSearchBusinessServices(business));
			} else if (tag) {
				acc.push(algoliaSearchBusinessServices(business, tag));
			}
		}

		return acc;
	}, []);

	return (
		<AlgoliaSearch format={parseBusinessServices} queries={queries}>
			{data => {
				return children({
					resultServices: data.data || null,
					isLoading:
						data.isLoading && data.isLoading === true ? true : isLoadingParent
				});
			}}
		</AlgoliaSearch>
	);
}

function parseBusinessServices(data) {
	if (data) {
		return Object.keys(data).reduce(function (result, key) {
			if (data[key].data.length > 0) {
				result[data[key].data[0].businessId] = data[key].data;
			}
			return result;
		}, {});
	}
}

function algoliaSearchBusinessServices(business, tag) {
	let filters = `businessId:${business.objectID} AND hidden:false`;

	if (tag) {
		filters = `tags:${tag} AND ${filters}`;
	}

	return {
		index: 'business_search_services',
		filters,
		sortBy: [
			'serviceBookingCount:desc',
			'business_search_services' // Index par défaut si serviceBookingCount n'existe pas
		]
	};
}

function algoliaSearchRefinements(
	{
		parentCategory,
		category,
		googlePlace,
		parentPlace,
		place,
		bounds,
		userLocation
	},
	{ byGlobalRating, data: { date } },
	{ countries },
	pageSearch
) {
	const refinements = {
		page: pageSearch,
		aroundRadius: 10000
	};

	const filterDate = isNativeApp ? 'plStatus = 4' : 'plStatus > 2';
	const filterNoDate = isNativeApp ? 'plStatus = 4' : 'plStatus > 0';
	// we want customers when picking a date
	const filters = [date ? filterDate : filterNoDate];
	if (bounds) {
		refinements.insideBoundingBox = [
			bounds.ne.lat,
			bounds.ne.lng,
			bounds.sw.lat,
			bounds.sw.lng
		].join(',');
	} else if (userLocation) {
		refinements.getRankingInfo = 1;
		refinements.aroundLatLng = [userLocation.lat, userLocation.lng].join(',');
	} else if (googlePlace) {
		refinements.getRankingInfo = 1;
		refinements.aroundLatLng = [
			safeRead(googlePlace, [
				'geometry',
				'location',
				location =>
					typeof location.lat === 'function' ? location.lat() : location.lat
			]),
			safeRead(googlePlace, [
				'geometry',
				'location',
				location =>
					typeof location.lng === 'function' ? location.lng() : location.lng
			])
		].join(',');
	} else {
		if (parentPlace) {
			filters.push(
				`parentPlaceId:${parentPlace.objectID} OR placeId:${parentPlace.objectID}`
			);
			if (!place && !!parentPlace._geoloc) {
				refinements.aroundLatLng = [
					safeRead(parentPlace, ['_geoloc', 'lat']),
					safeRead(parentPlace, ['_geoloc', 'lng'])
				].join(',');
			}
		}
		if (place) {
			filters.push(`placeId:${place.objectID}`);
			if (place._geoloc) {
				refinements.aroundLatLng = [
					safeRead(place, ['_geoloc', 'lat']),
					safeRead(place, ['_geoloc', 'lng'])
				].join(',');
			}
		}
	}
	if (parentCategory && !category) {
		filters.push(`plCategories:${parentCategory.objectID}`);
	}
	if (category) {
		if (category.objectID === category.parentTag) {
			filters.push(
				`plCategories:${category.objectID} OR plCategories:${category.searchTags
					.map(tag => tag)
					.join(' OR plCategories:')}`
			);
		} else {
			filters.push(`plCategories:${category.objectID}`);
		}
	}
	// That's always false, isn't it ?
	if (byGlobalRating) {
		filters.push(`globalRating >= 4`);
	}
	if (countries) {
		filters.push(
			`countryCode:${countries
				.map(country => country.toUpperCase())
				.join(' OR countryCode:')}`
		);
	}
	// Don't show businesses in the search page that have the setting hideFromSearch activated
	filters.push('NOT hideFromSearch:true');
	refinements.filters = filters.map(f => `(${f})`).join(' AND ');
	return refinements;
}
