import React, { useEffect, useRef } from 'react';
import { useTranslation } from '@planity/localization';
import { Input, Spinner } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './gift_voucher_payment.modules.scss';
import { formatPrice, PAYMENT_METHOD } from '@planity/helpers';

const GIFT_VOUCHER_INPUT_MAXIMUM_LENGTH = 300;

/**
 * @param {string} giftVoucherNumber Gift voucher number
 * @param {object} giftVoucherPaymentInfo Gift voucher used for payment appointement
 * @param {string} paymentMethod current payment method
 * @param {number} amount Available amount of money in cents
 * @param {boolean} isLoading Loading state indicator
 * @param {boolean} isValidated Wether the voucher number was successfully validated
 * @param {boolean} error Error state indicator (if true, triggers error message display)
 * @param {string} errorMessage Error message
 * @param {(voucherNumber: string) => void} onVoucherNumberChange Triggered when input changes
 * @param {() => void} onClickValidate Triggered when user wants to perform voucher number validation
 * @param {() => void} onClickDelete Triggered when user wants to delete the voucher number he provided
 */
export const GiftVoucherPayment = ({
	giftVoucherNumber,
	giftVoucherPaymentInfo,
	amount, // should be provided in cents
	isLoading,
	isValidated,
	error,
	errorMessage,
	onFocus,
	onVoucherNumberChange,
	onClickValidate,
	onClickDelete,
	paymentMethod
}) => {
	const { t } = useTranslation();

	const inputRef = useRef();

	useEffect(() => {
		const giftVoucherForMoveAppointmenIsValidated =
			giftVoucherPaymentInfo?.giftVoucherNumber &&
			!giftVoucherPaymentInfo?.isValidated;
		// set the focus after loading and paymentMethod is gift voucher or if no error with move appointement
		if (
			paymentMethod === PAYMENT_METHOD.USE_GIFT_VOUCHER &&
			!isLoading &&
			!giftVoucherForMoveAppointmenIsValidated
		) {
			inputRef.current.focus();
		}
	}, [isLoading, paymentMethod, giftVoucherPaymentInfo]);

	useStyles(styles);
	const classes = classNames.bind(styles)({
		giftVoucher: true
	});

	const handleInputChange = value => {
		// truncate & only keep numbers
		const voucherNumber = value.replace(/\D+/g, '');
		// trigger callback only if the sanitized input value is different from the previous one
		if (voucherNumber !== giftVoucherNumber) {
			onVoucherNumberChange(voucherNumber);
		}
	};

	const handleDeleteClick = () => {
		// before focusing, wait for react state to update so the input state is turned to "enabled"
		setTimeout(() => inputRef.current.focus(), 0);
		inputRef.current.focus();
		onClickDelete();
	};

	const shouldDisplayValidateButton = (giftVoucherNumber, error) =>
		giftVoucherNumber.length > 0 && !error;

	const shouldDisplayEndingNumbers = giftVoucherNumber =>
		giftVoucherNumber.length > 5;

	const innerMessage = (giftVoucherNumber, isValidated) => {
		if (!isValidated) {
			return '';
		} else {
			if (shouldDisplayEndingNumbers(giftVoucherNumber)) {
				return t(
					'onlinePayment.giftVoucher.validVoucherStatement.withVoucherNumbers',
					{
						voucherNumberEnding: giftVoucherNumber.slice(-4),
						amount: formatPrice(amount, true)
					}
				);
			} else {
				return t(
					'onlinePayment.giftVoucher.validVoucherStatement.withoutVoucherNumbers',
					{
						amount: formatPrice(amount, true)
					}
				);
			}
		}
	};

	// input element should be hidden when the gift voucher card infos are displayed, after validation
	const shouldHideInput = innerMessage(giftVoucherNumber, isValidated) !== '';

	// when the users presses Enter key after editing, trigger validation
	const handleKeyPress = (key, giftVoucherNumber, error) => {
		if (
			key === 'Enter' &&
			shouldDisplayValidateButton(giftVoucherNumber, error) &&
			!isValidated
		) {
			onClickValidate();
		}
	};

	return (
		<div className={`${classes}`}>
			<Input
				className={`${error && styles.error}`}
				error={error}
				errorMessage={errorMessage}
				forwardedRef={inputRef}
				icon={'Gift'}
				id={'gift-voucher-payment'}
				inputStyle={shouldHideInput ? { display: 'none' } : {}}
				isDisabled={isLoading || isValidated}
				label={t('onlinePayment.giftVoucher.title')}
				maxlength={GIFT_VOUCHER_INPUT_MAXIMUM_LENGTH}
				name={'gift-voucher-number'}
				placeholder={t('onlinePayment.giftVoucher.placeholder')}
				suffix={
					isLoading ? (
						<div className={styles.spinner}>
							<Spinner />
						</div>
					) : (
						shouldDisplayValidateButton(giftVoucherNumber, error) && (
							<span
								className={styles.suffix}
								id={'git-voucher-payment-validation'}
								onClick={() => {
									isValidated ? handleDeleteClick() : onClickValidate();
								}}
							>
								{isValidated
									? t('onlinePayment.giftVoucher.delete')
									: t('onlinePayment.giftVoucher.validate')}
							</span>
						)
					)
				}
				value={giftVoucherNumber}
				onChange={handleInputChange}
				onFocus={onFocus}
				onKeyPress={event =>
					handleKeyPress(event.key, giftVoucherNumber, error)
				}
			>
				{innerMessage(giftVoucherNumber, isValidated)}
			</Input>
		</div>
	);
};
