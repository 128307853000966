export default {
	FIREBASE_API_KEY: 'AIzaSyAdjthMcd_k-rmg_nCgOHA-SUuPUaj6vI0',
	FIREBASE_AUTH_DOMAIN: 'planity-qa.firebaseapp.com',
	FIREBASE_DATABASE_URL: 'https://planity-qa.firebaseio.com',
	FIREBASE_AVAILABILITIES_SHARD_URL:
		'https://planity-qa-availabilities.firebaseio.com',
	FIREBASE_AVAILABILITIES_SHARD_1_URL:
		'https://planity-qa-availabilities-shard-1.europe-west1.firebasedatabase.app',
	FIREBASE_AVAILABILITIES_SHARD_2_URL:
		'https://planity-qa-availabilities-shard-2.europe-west1.firebasedatabase.app',
	FIREBASE_PUBLIC_SHARD_URL: 'https://planity-qa-publicus.firebaseio.com',
	FIREBASE_STORAGE_BUCKET: 'planity-lab.appspot.com',
	AWS_API_ENDPOINT: 'https://esy58hrhd4.execute-api.eu-west-1.amazonaws.com/qa',
	ALGOLIA_APP_ID: 'JCTHSOFRM2',
	ALGOLIA_API_KEY: '174f878167187e0743af2c7b8e4b4c6c',
	ALGOLIA_PRODUCTION_APP_ID: 'DAY79MUBW3',
	ALGOLIA_PRODUCTION_API_KEY: '8ec84cdda274cec79b9ad155973bc864',
	GOOGLE_API_BROWSER_KEY:
		process.env.NODE_ENV === 'development'
			? 'AIzaSyBvhQQf1t6BHskINw9SyKdI8AE0pW4KTFc'
			: 'AIzaSyDopTlObOWruWzt_1bV1hvXqDzK7wtoU00',
	HOST: 'planityqa.com',
	CLOUD_FUNCTIONS_DOMAIN: 'https://us-central1-planity-qa.cloudfunctions.net',
	MAPBOX_API_KEY:
		'pk.eyJ1IjoicGxhbml0eSIsImEiOiI4QU5kRy1jIn0.suHIBfZHkiiPnuVFhQSRMg',
	USER_APP_LINK: 'https://planity.onelink.me/6GTD/4ef6ae2e',
	PARANOID_AVAILABILITIES: ['saco'],
	STRIPE_API_KEY:
		'pk_test_517CtV2A69XfgDO9EgnwM1lmXWCYiaDscjjzSTaosTngmxavETTOmTtJKGdfVt2VgZ6cGBDoY14xYdPBiThKmn619008hNfQCsH',
	STRIPE_API_KEY_FR:
		'pk_test_517CtV2A69XfgDO9EgnwM1lmXWCYiaDscjjzSTaosTngmxavETTOmTtJKGdfVt2VgZ6cGBDoY14xYdPBiThKmn619008hNfQCsH',
	STRIPE_API_KEY_BE:
		'pk_test_51Lfk9VFCwhxbUJ7mSdwaFCrP38BgelUQPvOjK7zbpZGHIvdyVRkfpvreRVHeoees2df6titaLQEjUAtxQfscNPkc00clFCnAsf',
	STRIPE_API_KEY_DE:
		'pk_test_51L15sZE7ePEuIRmRVnUPGutdLBj8udBvUsP86xlxMvdklBgHOSP2E3b57cz9rAP1wtdYCh4vjFazd49CiqUNwxgU0003HcpCuk',
	DISPLAY_REVIEWS: true,
	HIDE_ESHOP: false,
	HIDE_GIFT_VOUCHER_BUTTON: false,
	ENABLE_FACEBOOK_LOGIN: false,
	ENABLE_GOOGLE_LOGIN: false,
	ENABLE_GITHUB_LOGIN: false,
	// Just in case we enable and then disable a provider, we can filter "not used anymore" providers when getting the user's prefered providers
	AVAILABLE_PROVIDERS: ['password'],
	ENABLE_USER_PAYS_FEES: true,
	PLANITY_FEE: 1.58,
	PLANITY_FLAT: 18,
	STRIPE_FEE: 0,
	STRIPE_FLAT: 0,
	ENABLE_CLICK_AND_COLLECT: 'true',
	ENABLE_CLICK_AND_SHOP: true,
	WOOTRIC_ACCOUNT_TOKEN: 'NPS-593dabed',
	WOOTRIC_NO_SURVEYED_COOKIES: false,
	WOOTRIC_SURVEY_IMMEDIATELY: false,
	ENABLE_NPS: false,
	ENABLE_ENRICHMENT: true,
	CATEGORIES_INDEX: 'categories',
	TAGS_INDEX: 'tags',
	PLACES_INDEX: 'places',
	BUSINESSES_INDEX: 'businesses',
	BUSINESSES_BY_AVERAGE_DESC_INDEX: 'businesses_byAverage_desc',
	BUSINESSES_BY_AVERAGE_ASC_INDEX: 'businesses_byAverage',
	BUSINESSES_BY_RATING_ASC_INDEX: 'businesses_byRating',
	SEO_DATA: 'seo_data_migration',
	LOREAL_BRAND_CONTENT_FRENCH_BALAYAGE_ID:
		'11640417-102f-445b-9080-c99d8764e735',
	RECAPTCHA_SITE_KEY: '6Lc0pY0fAAAAABF-ARe9wyleH8RYEgEB4FI7lZh6',
	RECAPTCHA_MACHINE_ARN:
		'arn:aws:states:eu-west-1:637668567762:stateMachine:sendPhoneNumberVerificationCodeRecaptchaProtectedLab',
	SAVE_CARD_CONTROL_ACTIVATION_DATE: 1723128104,
	GOOGLE_CONVERSION_ENDPOINT:
		'https://www.google.com/maps/conversion/debug/collect',
	GOOGLE_PARTNERID: 20000120,
	USE_GIFT_VOUCHER_FOR_PAYMENTS_ENABLED: true,
	USE_CURE_FOR_PAYMENTS_ENABLED: true
};
