import { PREVIOUSLY_SIGNED_UP_WITH_FACEBOOK_ERROR } from './errors';
import credentials from '@planity/credentials';
import { firebase } from '@planity/datastores';
import { formatPhoneNumber, invokeLambda } from '@planity/helpers';
import { publicShard } from '@planity/datastores/firebase';
import i18n from '@planity/localization/i18n';

const signInWithEmailAndPassword = ({ email, password }) =>
	firebase.auth().signInWithEmailAndPassword(email, password);

const sendPasswordResetEmail = ({ email }) =>
	firebase
		.auth()
		.sendPasswordResetEmail(email.replace(/\s/g, '').toLowerCase());

const createUserWithEmailAndPassword = ({ email, password }) =>
	firebase.auth().createUserWithEmailAndPassword(email, password);

const setUserPhoneInDatabase = ({ userId, phoneNumber, email, isPro }) => {
	const updateObject = email
		? {
				phone: phoneNumber,
				phoneFormatted: formatPhoneNumber(phoneNumber, 'International'),
				email
		  }
		: {
				phone: phoneNumber,
				phoneFormatted: formatPhoneNumber(phoneNumber, 'International')
		  };
	return firebase
		.database()
		.ref(isPro ? `pros/${userId}` : `users/${userId}`)
		.update(updateObject);
};

const updateMissingFields = async ({
	userId,
	firstName,
	lastName,
	email,
	phoneNumber,
	isPro,
	emailHasNotBeenShared,
	fromSocial
}) => {
	if (emailHasNotBeenShared && fromSocial)
		await firebase.auth().currentUser.updateEmail(email);
	return firebase
		.database()
		.ref(isPro ? `pros/${userId}` : `users/${userId}`)
		.update({
			firstName,
			lastName,
			email,
			phone: phoneNumber,
			phoneFormatted: formatPhoneNumber(phoneNumber, 'International'),
			name: `${firstName} ${lastName}`
		});
};

const createUserInDatabase = ({
	userId,
	email,
	firstName,
	lastName,
	phoneNumber,
	isPro
}) => {
	const userData = {
		email,
		phone: phoneNumber,
		phoneFormatted: formatPhoneNumber(phoneNumber, 'International')
	};
	if (firstName && firstName.trim()) userData.firstName = firstName.trim();
	if (lastName && lastName.trim()) userData.lastName = lastName.trim();
	if (firstName && lastName && lastName.trim() && firstName.trim())
		userData.name = `${firstName} ${lastName}`;

	return firebase
		.database()
		.ref(isPro ? `pros/${userId}` : `users/${userId}`)
		.update(userData);
};

const invokePhoneNumberVerificationRecaptchaProtected = async (
	{ phoneNumber, token },
	_,
	{ locale }
) => {
	let sender;
	if (process.env.WIDGET) {
		const senderOnce = await publicShard
			.database()
			.ref(`businesses/${window.planity.key}/campaignSmsSender`)
			.once('value');
		sender = senderOnce.val() || i18n.t('phoneVerificationForm.defaultSender');
	}
	//call step function to verify recaptcha and sendNumberVerificationCode if success
	await invokeLambda('stepfunctions', {
		input: JSON.stringify({
			phoneNumber,
			locale,
			token,
			sender,
			tokenId: getTokenId()
		}),
		stateMachineArn: credentials.RECAPTCHA_MACHINE_ARN
	});
};

function getTokenId() {
	try {
		return window?.planity?.key || null;
	} catch (e) {
		console.warn(e);
		return null;
	}
}

/**
 * Sign in the user with an external provider
 * @param {String} providerId such as 'facebook.com' or 'github.com'
 */
const signInWithProvider = providerId =>
	firebase.auth().signInWithPopup(getProvider(providerId));

const retrieveUserData = ({ userId }) =>
	firebase
		.database()
		.ref(`users/${userId}`)
		.once('value')
		.then(res => res.val());

const retrieveProData = ({ userId }) =>
	firebase
		.database()
		.ref(`pros/${userId}`)
		.once('value')
		.then(res => res.val());

const fetchDifferentAccounts = (event, { data }) =>
	firebase.auth().fetchSignInMethodsForEmail(data.email || event.email);

const mergeEmailAndPasswordWithProvider = ({ pendingCredential }, { data }) =>
	data.user.linkWithCredential(pendingCredential);

const signInWithProviderAndMergeAccount = ({ pendingCredential }, providerId) =>
	firebase
		.auth()
		.signInWithPopup(getProvider(providerId))
		.then(({ user }) =>
			user.linkAndRetrieveDataWithCredential(pendingCredential)
		);

const checkingOtherProviders = ({ code, email }) => {
	return new Promise((resolve, reject) => {
		firebase
			.auth()
			.fetchSignInMethodsForEmail(email)
			.then(providers => {
				if (
					providers.includes('facebook.com') &&
					!providers.includes('password')
				) {
					return resolve({ code: PREVIOUSLY_SIGNED_UP_WITH_FACEBOOK_ERROR });
				} else {
					return reject({ code });
				}
			});
	});
};

/**
 * Retrieves the firebase provider from the providerId
 * @param {String} providerId such as 'facebook.com' or 'github.com'
 */
const getProvider = providerId => {
	switch (providerId) {
		case 'facebook.com':
			return new firebase.auth.FacebookAuthProvider();
		case 'google.com':
			return new firebase.auth.GoogleAuthProvider();
		case 'github.com':
			return new firebase.auth.GithubAuthProvider();
		default:
			throw `Unknown provider id ${providerId}`;
	}
};

export {
	retrieveProData,
	invokePhoneNumberVerificationRecaptchaProtected,
	createUserInDatabase,
	retrieveUserData,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	signInWithProvider,
	createUserWithEmailAndPassword,
	fetchDifferentAccounts,
	mergeEmailAndPasswordWithProvider,
	signInWithProviderAndMergeAccount,
	setUserPhoneInDatabase,
	updateMissingFields,
	checkingOtherProviders
};
