import { isBefore } from 'date-fns';

export const getExpiredCards = paymentMethods => {
	const expired = {};
	const now = new Date();
	(paymentMethods || []).forEach(({ card, id }) => {
		const { exp_month, exp_year } = card ?? {};
		if (exp_month && exp_year) {
			const expirationDate = new Date(exp_year, exp_month);
			if (isBefore(expirationDate, now)) {
				expired[id] = true;
			}
		}
	});
	return expired;
};
