// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".policies-module_text-\\+zvm4{font:var(--regular) var(--body-4);color:var(--grey-600);margin-top:12px}.policies-module_text-\\+zvm4>a{font-weight:var(--semibold);text-decoration:underline;color:var(--grey-600)}", "",{"version":3,"sources":["webpack://./../../packages/components/business_booking/online_payment/policies/policies.module.scss"],"names":[],"mappings":"AAAA,6BACC,iCAAA,CACA,qBAAA,CACA,eAAA,CAEA,+BACC,2BAAA,CACA,yBAAA,CACA,qBAAA","sourcesContent":[".text {\n\tfont: var(--regular) var(--body-4);\n\tcolor: var(--grey-600);\n\tmargin-top: 12px;\n\n\t> a {\n\t\tfont-weight: var(--semibold);\n\t\ttext-decoration: underline;\n\t\tcolor: var(--grey-600);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "policies-module_text-+zvm4"
};
module.exports = ___CSS_LOADER_EXPORT___;
