import React from 'react';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './footer.module.scss';
import { Icon, Logo, FooterItem } from '@planity/ui';
import { useLocalization, useTranslation } from '@planity/localization';
import { fr, be, de, nl } from './rawData';

export function Footer({ isDark = false, hasBreadcrumbsSeparator = false }) {
	useStyles(styles);

	const { t } = useTranslation();
	const { locale } = useLocalization();
	const rawData = getRawData(locale);

	// noinspection JSUnresolvedVariable
	const cookiesPolicy = {
		text: t('footer.cookiesPolicy'),
		// Didomi is loaded in html <script> tag
		// eslint-disable-next-line no-undef
		onClick: () => Didomi.preferences.show()
	};

	const classes = classNames.bind(styles)({
		footer: true,
		dark: isDark,
		hasBreadcrumbsSeparator
	});

	const renderSocialIcons = (
		<>
			<button
				className={styles.socialIcon}
				onClick={() => window.open(t('common.links.facebook'), '_blank')}
			>
				<Icon icon='Facebook' />
			</button>
			<button
				className={styles.socialIcon}
				onClick={() => window.open(t('common.links.instagram'), '_blank')}
			>
				<Icon icon='Instagram' />
			</button>
		</>
	);

	return (
		<footer
			className={classes}
			itemScope
			itemType={'https://schema.org/Organization'}
		>
			<div className={styles.container}>
				<nav className={styles.nav}>
					<div className={styles.left}>
						<Logo className={styles.logo} />
						<div className={styles.topIcons}>{renderSocialIcons}</div>
					</div>
					{rawData && (
						<>
							<FooterItem data={rawData.about} dataToAppend={[cookiesPolicy]} />
							<FooterItem data={rawData.categories} />
							<FooterItem data={rawData.oftenSearch} />
						</>
					)}
				</nav>
				<hr className={styles.separator} />
				<div className={styles.bottom}>
					<div className={styles.bottomIcons}>{renderSocialIcons}</div>
					<span className={styles.copyright}>
						{t('nav.copyright')} {t('punctuation.copyright')}{' '}
						{new Date().getFullYear()} {t('nav.planity')}
					</span>
				</div>
			</div>
		</footer>
	);
}

function getRawData(locale) {
	switch (locale) {
		default:
		case 'fr-FR':
			return fr;
		case 'fr-BE':
			return be;
		case 'de-DE':
			return de;
		case 'nl-BE':
			return nl;
	}
}
