import React from 'react';
import { Form, PhoneInput } from '@planity/components';
import { useTranslation } from '@planity/localization';
import { Button, InlineLink } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './missing_phone_number_form.module.scss';
import { useFormHooks } from './useFormHooks';

export const MissingPhoneNumberForm = props => {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		missingPhoneNumber: true
	});
	const { defaultCountry, phoneInput, phoneNumber, pending } = props;

	const { t } = useTranslation();

	const localizedText = {
		id: 'missing_phone_number_form',
		title: t(`auth.texts.fillMissingData`),
		labels: {
			button: t(`auth.form.buttons.acceptAndContinue`),
			phoneNumber: t('auth.form.labels.phone'),
			link: t(`auth.form.links.BACK_TO_SIGN_IN`)
		},
		placeholders: {
			phoneNumber: t(`auth.form.placeholders.phoneNumber`)
		}
	};
	const { handleDispatchEvent, handleDispatchInputs } = useFormHooks();
	return (
		<Form className={classes} id={localizedText.id}>
			<div className={`${styles.title}  ${styles.title__update}`}>
				{localizedText.title}
			</div>
			<PhoneInput
				data={null}
				defaultCountry={defaultCountry}
				forwardedRef={phoneInput}
				id={`${localizedText.id}__phone`}
				localizedPlaceholder={localizedText.placeholders.phoneNumber}
				localizedText={localizedText}
				value={phoneNumber}
				onChange={(phoneNumberInput, value) =>
					handleDispatchInputs({
						type: 'PHONE_NUMBER_CHANGE',
						phoneNumber: phoneNumberInput,
						phoneNumberFormat: value
					})
				}
			/>
			<Button
				className={`${styles.btn} ${styles.btn__submit}`}
				id={`${localizedText.id}__submit`}
				isDisabled={pending}
				isLoading={pending}
				label={localizedText.labels.button}
				onClick={event =>
					handleDispatchEvent({ event: event, type: 'updatePhoneNumber' })
				}
			/>
			<InlineLink
				className={`${styles.btn}  ${styles.btn__signOut}`}
				id={`${localizedText.id}__signOut`}
				label={localizedText.labels.link}
				onClick={event => {
					handleDispatchEvent({ event: event, type: 'signOut' });
				}}
			/>
		</Form>
	);
};
