import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './breadcrumbs.module.scss';
import { Link } from 'react-router-dom';

export const BreadCrumbLink = ({
	to,
	text,
	isLast,
	childItemProp,
	microdata
}) => {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		breadcrumbs: true
	});

	return (
		<Link
			to={to}
			className={`${classes} ${styles.link}`}
			aria-current={!!isLast}
			{...microdata}
		>
			<span itemProp={childItemProp}>{text}</span>
		</Link>
	);
};
