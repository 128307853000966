import React, { useState, useEffect } from 'react';
import { BookAppointmentTitle, StripePayment } from '@planity/components';
import { firebase } from '@planity/datastores';
import { Localize, useStripeElements } from '@planity/components';
import { useTranslation } from '@planity/localization';
import { Button, Spinner } from '@planity/ui';
import styles from './appointment_payment.modules.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import {
	centsToPrice,
	formatPrice,
	isStripeError,
	PAYMENT_TYPE_CARD
} from '@planity/helpers';
import { ONLINE_SHOP_BLOC, useTheme } from '@planity/context/theme_context';
import { Policies } from './policies';

export const EshopPayment = props => {
	const {
		paymentContainerRef,
		title,
		contextData,
		customerID,
		clientSecret,
		user,
		paymentMethods,
		intentID,
		hideMobileTitles,
		style,
		index,
		totalPrice,
		onSubmit,
		isLoading,
		isCanPayMachineStep,
		isClickAndShop,
		products,
		shippingFees,
		cartIsEmpty,
		selectedPaymentMethod,
		setIsLoading,
		business,
		setError,
		locale,
		doesSelectedPaymentMethodNeedConsent,
		hasAcceptedSaveCard,
		isSelectedPaymentLinkedToActiveAppointments,
		paymentMethodType,
		setIsRedirect
	} = props;

	useStyles(styles);
	const classes = classNames.bind(styles);
	const { isDark: hasDarkBackground } = useTheme();
	const isDark = hasDarkBackground?.[ONLINE_SHOP_BLOC];
	const [componentUser, setComponentUser] = useState();
	const [displayAddCard, setDisplayAddCard] = useState(false);
	const { t } = useTranslation();
	const { stripe } = useStripeElements();

	useEffect(() => {
		setComponentUser({
			...user,
			email: firebase.auth().currentUser
				? firebase.auth().currentUser.email
				: null
		});

		if (paymentMethods && paymentMethods.length === 0) {
			setDisplayAddCard(true);
		}
	}, [paymentMethods]);

	useEffect(() => {
		if (isCanPayMachineStep) {
			setIsRedirect(false);
		}
	}, [isCanPayMachineStep]);

	const stripeCardListConfirm = async () => {
		setIsLoading(true);
		const result = await stripe.confirmCardPayment(clientSecret, {
			payment_method: selectedPaymentMethod.id
		});
		if (result.error) {
			setIsLoading(false);
			return isStripeError(result)
				? setError(
						result.error.decline_code?.toUpperCase() ||
							result.error.code.toUpperCase()
				  )
				: setError('defaultError');
		}
		onSubmit({
			paymentIntentId: result.paymentIntent.id
		});
	};

	const hasPassedCardConsentRequirement =
		!!selectedPaymentMethod &&
		(!doesSelectedPaymentMethodNeedConsent || // we don't need consent verification -> see the function .does_payment_method_need_consent.js
			isSelectedPaymentLinkedToActiveAppointments || // the card is still linked to future appointments (with imprint)
			hasAcceptedSaveCard !== null); // the user has replied to consent verification

	const isSubmitEnabled =
		paymentMethodType !== PAYMENT_TYPE_CARD || hasPassedCardConsentRequirement;

	return (
		<div
			className={classes({
				payment: true
			})}
			css={style}
			ref={paymentContainerRef}
		>
			<BookAppointmentTitle
				hideMobileTitles={hideMobileTitles}
				index={index}
				isDark={isDark}
				text={title ? title : 'bookAppointment.onlinePaymentTitle'}
			/>
			{componentUser && customerID ? (
				<div className={styles.card}>
					<div className={styles.productInfoContainer}>
						{products?.map((product, index) => (
							<div key={index}>
								<p id={`item-name-product-${index}`}>{product.name}</p>
								<span id={`item-price-product-${index}`}>
									{formatPrice(product.price, true)}
								</span>
							</div>
						))}

						<div className={styles.productShippingFees}>
							<p id='products-shipping-title'>
								{isClickAndShop
									? t('clickAndCollect.clickAndShop.title')
									: t('clickAndCollect.clickAndCollectShippingPrice')}
							</p>
							<span id='products-shippingFees'>
								{formatPrice(shippingFees, true)}
							</span>
						</div>

						<div className={styles.totalPrice}>
							{!cartIsEmpty && (
								<>
									<p id='total-title'>{t('onlinePayment.total.toPay')}</p>
									<span id='total-title-price'>{centsToPrice(totalPrice)}</span>
								</>
							)}
						</div>
					</div>
					<span className={styles.paymentMethod}>
						<Localize text={'onlinePayment.moyenPaiement'} />
						<Localize text='punctuation.colon' />
					</span>
					<StripePayment
						{...props}
						amount={totalPrice}
						confirm={onSubmit}
						contextData={contextData}
						countryCode={business.countryCode}
						displayAddCard={displayAddCard}
						intentID={intentID}
						isDisabled={!isSubmitEnabled}
						isLoading={isLoading}
						isOnlineShop={true}
						isPrepayment={true}
						setDisplayAddCard={displayAddCard =>
							setDisplayAddCard(displayAddCard)
						}
						setError={setError}
						setIsLoading={setIsLoading}
						totalPrice={formatPrice(totalPrice)}
					/>
					{!displayAddCard && (
						<div className={styles.payButtonContainer}>
							<Button
								id='pay-button-clickAndCollect'
								isDisabled={!isSubmitEnabled}
								isFullWidth
								isLoading={isLoading}
								label={
									<Localize
										args={{ price: centsToPrice(totalPrice) }}
										text={'clickAndCollect.pay'}
									/>
								}
								onClick={stripeCardListConfirm}
							/>
						</div>
					)}
					{paymentMethodType === PAYMENT_TYPE_CARD && (
						<Policies isEshop locale={locale} />
					)}
				</div>
			) : (
				<div>
					<Spinner />
				</div>
			)}
		</div>
	);
};
