import { useLocalizedRoutes, useTranslation } from '@planity/localization';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMachine } from '@xstate/react';
import { useHistory, useLocation } from 'react-router-dom';
import { giftVoucherMachine } from './machine';
import Personalization from './personalization';
import { AppointmentUser } from '../book_appointment/user';
import {
	businessSortedWebVisibleServiceSets,
	formatPrice,
	getServicesWithGiftVouchersEnabled,
	safeRead,
	scrollToNode,
	computeFees,
	getFinalFees
} from '@planity/helpers';
import ServicesList from './services_list/services_list';
import GiftVoucher from './gift_voucher';
import { breakpoints } from '@planity/theme';
import { AppointmentPayment } from '../business_booking/online_payment';
import { OnlinePaymentConsumer } from '../business_booking/online_payment/providerComponent';
import { GiftVoucherAnalyticContext } from '@planity/helpers/analytics';
import credentials from '@planity/credentials';
import {
	ErrorMessage,
	FirebaseSubscription,
	useAuth,
	WaitingPage
} from '@planity/components';
import variables from '@planity/ui/styles/utilities/variables.scss';
import { useStripeFees } from '../app_settings/stripeFeesProvider';
import { GIFT_VOUCHER_BLOC, useTheme } from '@planity/context/theme_context';

export const GiftVoucherFlow = OnlinePaymentConsumer(function (props) {
	const {
		business,
		getCustomerStripe,
		customerID,
		businessId,
		selectedPaymentMethod,
		newPaymentMethodFromElement,
		onSuccess,
		giftCards,
		resetPaymentMethodDatas,
		data,
		resetPaymentIntent,
		intentID,
		updatePaymentIntent,
		isPaymentIntentUpdating,
		paymentMethodType
	} = props;
	const { userId, user, isPro } = useAuth();
	const history = useHistory();
	const location = useLocation();
	const { routes } = useLocalizedRoutes();
	const { allStripeFees } = useStripeFees();
	const { isDark: hasDarkBackground, singlePageApp } = useTheme();
	const isDark = hasDarkBackground?.[GIFT_VOUCHER_BLOC];
	const ctx = useContext(GiftVoucherAnalyticContext);
	const giftData = location?.state?.giftData || data;
	const redirectUserId = location?.state?.userId || null;
	const [hasTokenStatus, setHasTokenStatus] = useState(null);
	const giftVoucherMachineWithContext = giftVoucherMachine.withContext({
		data: {
			giftVoucherPrice: giftData?.giftVoucherPrice || 0,
			selectedServices: giftData?.selectedServices || [],
			totalPrice: giftData?.totalPrice || 0,
			totalPriceInCents: giftData?.totalPriceInCents || 0,
			typeSelected: giftData?.typeSelected || null,
			businessId,
			singlePageApp,
			selectedTemplate: giftData?.selectedTemplate,
			pricesCatalog: giftData?.pricesCatalog || null,
			targetMail: giftData?.targetMail || '',
			message: giftData?.message || '',
			userId: redirectUserId || userId,
			variant: giftData?.variant
		},
		completedSteps: ['INIT']
	});

	const [state, send] = useMachine(giftVoucherMachineWithContext, {
		devTools: process.env.PLANITY_ENV === 'lab'
	});
	const [servicesListVisible, setServicesListVisible] = useState(false);
	const [error, setError] = useState(location?.state?.error || null);
	const [errorIsOnlinePayment, setErrorIsOnlinePayment] = useState(null);
	const [isLoading, setIsLoading] = useState(
		isPaymentIntentUpdating || state.matches('paying')
	);

	const { completedSteps } = state.context;
	const selectedServices = state.context.data.selectedServices;
	const giftVoucherPrice = state.context.data.giftVoucherPrice;
	const services = businessSortedWebVisibleServiceSets(business);
	const servicesGiftVouchersEnabled =
		getServicesWithGiftVouchersEnabled(services);
	const { t } = useTranslation();

	const isMobile = breakpoints.isMobile();

	useEffect(() => {
		if (safeRead(location, ['state', 'isRedirect'])) {
			send({ type: 'REINJECT_DATA', giftData });
		}
	}, []);

	useEffect(() => {
		setIsLoading(isPaymentIntentUpdating || state.matches('paying'));
	}, [isPaymentIntentUpdating, state.matches('paying')]);

	useEffect(() => {
		if (intentID && state.context.data?.totalPriceInCents > 0) {
			const amount = state.context.data?.totalPriceInCents;
			const stripeFees = getFinalFees(
				allStripeFees,
				business?.countryCode,
				paymentMethodType
			);
			const application_fee_amount = computeFees({ amount, stripeFees });
			updatePaymentIntent({
				intentID,
				amount,
				application_fee_amount,
				metadata: { amountWithoutFees: amount },
				countryCode: business?.countryCode,
				businessId
			});
		}
	}, [state.context.data?.totalPriceInCents, paymentMethodType]);

	useEffect(() => {
		if (state.matches('confirmed')) {
			onSuccess();
		}
		if (
			!state.matches('servicesSelection') &&
			state.context.data.selectedServices.length <= 0 &&
			state.context.data.giftVoucherPrice <= 0
		) {
			send('RESET');
		}

		const isVariable =
			state.context.data.selectedServices &&
			state.context.data.selectedServices[0] &&
			state.context.data.selectedServices[0].isVariableAmount;

		//Check if selected service is variable gift card
		if (state.context.data.selectedServices) {
			if (
				(selectedServices.length > 0 || giftVoucherPrice > 0) &&
				!isVariable &&
				!completedSteps.includes('SERVICES_SELECTED')
			) {
				send('SERVICES_SELECTED');
			}
		}

		//Si carte cadeau variable / pas de prix / étape avancé
		if (
			isVariable &&
			(isNaN(giftVoucherPrice) || !giftVoucherPrice) &&
			completedSteps.includes('SERVICES_SELECTED')
		) {
			send('SOFT_RESET');
		}

		//Call when adding a new card
		if (state.matches('pay.normal') && newPaymentMethodFromElement) {
			submitForm({
				paymentIntentId: intentID,
				newPaymentMethodFromElement
			});
		}
		if (state.matches('pay.error')) {
			resetPaymentData();
			send('RESET_PAYMENT_ERROR');
		}
	}, [state, completedSteps, newPaymentMethodFromElement]);

	useEffect(() => {
		if (
			state.context.errors &&
			!error &&
			typeof state.context.errors === 'string'
		) {
			const errorMessage = {
				message: `gift.errors.${state.context.errors}`
			};

			setError(errorMessage);

			setTimeout(() => {
				setError(null);
			}, 10000);
		}
	}, [state.context.errors]);

	useEffect(() => {
		if (
			state.matches('creditCard') &&
			!!(selectedPaymentMethod || newPaymentMethodFromElement)
		) {
			send('CREDIT_CARD_SUCCESS');
		}

		if (selectedPaymentMethod) {
			ctx.USER_SELECTED_PAYMENT_METHOD();
		}
	}, [selectedPaymentMethod, newPaymentMethodFromElement, state]);

	useEffect(() => {
		if (userId && user) {
			getCustomerStripe({
				userId,
				user,
				paymentMethod: location?.state?.paymentMethod || null,
				countryCode: business.countryCode
			});
			ctx.USER_SIGNED_IN();
		}
		customerID && !userId && resetPaymentMethodDatas();
	}, [userId, user]);

	const popupGiftService = () => {
		selectedServices.length <= 0 &&
			history.replace({
				pathname: `/${business.slug}`
			});
		setServicesListVisible(!servicesListVisible);
	};

	const popupGiftServiceSubmit = giftData => {
		setServicesListVisible(!servicesListVisible);
		send({
			type: 'ON_CHANGE',
			data: {
				giftVoucherPrice: parseFloat(giftData.giftVoucherPrice),
				selectedServices: giftData.selectedServices,
				typeSelected: giftData.typeSelected,
				totalPrice: giftData.totalPrice,
				totalPriceInCents: giftData.totalPriceInCents
			}
		});
	};
	const offset = parseInt(variables.headerHeight.replace(/\D+/, '')) + 24;

	const templateContainerRef = useCallback(
		node => {
			node &&
				completedSteps.includes('SERVICES_SELECTED') &&
				scrollToNode(node, {
					animated: true,
					animationDuration: 500,
					offset
				});
		},
		[completedSteps]
	);

	const continueButtonRef = useCallback(
		node => {
			node &&
				completedSteps.includes('SERVICES_SELECTED') &&
				scrollToNode(node, {
					animated: true,
					animationDuration: 500,
					offset
				});
		},
		[completedSteps]
	);

	const personalizationContainerRef = useCallback(
		node => {
			node &&
				completedSteps.includes('SERVICES_SELECTED') &&
				scrollToNode(node, {
					animated: true,
					animationDuration: 500,
					offset
				});
		},
		[completedSteps]
	);

	const userContainerRef = useCallback(
		node => {
			node &&
				completedSteps.includes('TEMPLATE_CHOSE') &&
				scrollToNode(node, {
					animated: true,
					offset
				});
		},
		[completedSteps]
	);

	const paymentContainerRef = useCallback(
		node => {
			node &&
				(completedSteps.includes('AUTH_SUCCESS') ||
					completedSteps.includes('REINJECT_DATA')) &&
				scrollToNode(node, { animated: true, offset });
		},
		[completedSteps]
	);

	const tempSelectedServices = state.context.data.selectedServices;

	const submitForm = ({ paymentIntentId, newPaymentMethodFromElement }) => {
		const { selectedPaymentMethod } = props;
		const savedPaymentMethod =
			newPaymentMethodFromElement || selectedPaymentMethod;
		const { last4 } = savedPaymentMethod.card;

		setError(null);
		send({
			type: 'SUBMIT',
			data: {
				businessId,
				customerID,
				userId,
				savedPaymentMethod,
				paymentMethod: savedPaymentMethod.id,
				last4,
				paymentIntentId
			}
		});
	};

	const resetPaymentData = () => {
		//verify to avoid spam reset
		if (props.clientSecret) {
			send({
				type: 'RESET_PAYMENT_INTENT_ID',
				data: {
					paymentIntentId: null
				}
			});
			resetPaymentIntent();
		}
	};

	/**
	 * get the templates' colors allowed by the pro for the gift voucher
	 */
	function getAvailableGiftCards() {
		if (state.context.data.typeSelected !== 'gift_card') {
			return;
		}
		// for the old grumpy gift vouchers that wouldn't have any color set yet
		const newDefaultColors = [
			'variant_1',
			'variant_2',
			'variant_3',
			'variant_4',
			'variant_5',
			'variant_6',
			'variant_7',
			'variant_8',
			'variant_9',
			'variant_10',
			'variant_11',
			'variant_12',
			'variant_13',
			'variant_14',
			'variant_15',
			'variant_16'
		];

		const hasPreviousTemplates =
			state.context.data.selectedServices[0].templates !== undefined;

		const hasNewVariant =
			state.context.data.selectedServices[0].variant !== undefined;

		const voucherTemplates =
			hasPreviousTemplates && !hasNewVariant
				? newDefaultColors
				: Object.values(
						state.context.data.selectedServices[0].variant || newDefaultColors
				  );

		return voucherTemplates;
	}

	const cardColors = getAvailableGiftCards();

	if (
		!!location?.state &&
		!!location?.state.isRedirect &&
		!location?.state?.error &&
		!hasTokenStatus &&
		(!singlePageApp || location?.state?.isFrom === GIFT_VOUCHER_BLOC)
	) {
		return (
			<FirebaseSubscription
				path={`${isPro ? 'pros' : 'users'}/${userId}/redirect_tokens/${
					location.state.tokenId
				}/response`}
			>
				{({ data: redirectLambdaResponse, isLoading }) => {
					const { status, data } = redirectLambdaResponse || {};
					if (status === 'succeed') {
						if (onSuccess) {
							ctx.USER_BOUGHT_GIFT_VOUCHER();
							onSuccess();
						}
					} else if (status === 'failed' && data) {
						const error = data.error?.code || data.error;
						setHasTokenStatus(true);
						setError(error);
						resetPaymentData();
						send('RESET_PAYMENT_ERROR');
					}
					return <WaitingPage />;
				}}
			</FirebaseSubscription>
		);
	}

	return (
		<div css={styles.wrapper}>
			{error && (
				<ErrorMessage
					defaultMessage={t('gift.errors.CRITICAL_ERROR')}
					error={t([
						`stripe.errors.${error}`,
						`gift.errors.${error}`,
						`gift.errors.CRITICAL_ERROR`
					])}
				/>
			)}
			{completedSteps.includes('INIT') && (
				<div>
					{tempSelectedServices.length > 0 && (
						<ServicesList
							{...props}
							changeVariablePrice={value => {
								const totalPriceInCents = parseInt(value) * 100;

								send({
									type: 'ON_CHANGE',
									data: {
										giftVoucherPrice: parseInt(value),
										totalPrice: formatPrice(totalPriceInCents),
										totalPriceInCents
									}
								});
							}}
							giftVoucherPrice={state.context.data.giftVoucherPrice}
							hasSelectedVariableVoucher={(
								state.context.data.selectedServices || []
							).some(s => s.isVariableAmount)}
							hideChose={true}
							isPending={isLoading}
							popupGiftService={popupGiftService}
							reinjectData={completedSteps.includes('REINJECT_DATA')}
							selectedServices={state.context.data.selectedServices}
							totalPrc={
								state.context.data.giftVoucherPrice > 0
									? state.context.data.giftVoucherPrice
									: state.context.data.totalPriceInCents / 100
							}
							typeSelected={state.context.data.typeSelected}
							variablePriceValidation={() => {
								if (state.context.data.giftVoucherPrice > 0) {
									ctx.USER_DEFINED_VARIABLE_GIFT_VOUCHER_AMOUNT();
									send('SERVICES_SELECTED');
								}
							}}
							onRemove={({ index, giftVoucherPrice, price }) => {
								tempSelectedServices.splice(index, 1);
								if (tempSelectedServices.length <= 0) {
									setServicesListVisible(true);
									send('RESET');
								} else {
									const totalPriceInCents = Math.round(
										state.context.data.totalPriceInCents - parseInt(price) * 100
									);
									send({
										type: 'ON_CHANGE',
										data: {
											selectedServices: tempSelectedServices,
											giftVoucherPrice,
											totalPrice: formatPrice(totalPriceInCents),
											totalPriceInCents
										}
									});
								}
							}}
						/>
					)}
					{servicesListVisible && (
						<GiftVoucher
							business={business}
							businessId={businessId}
							giftCards={giftCards}
							selectedServ={state.context.data.selectedServices}
							selectedType={state.context.data.typeSelected}
							services={servicesGiftVouchersEnabled}
							totalPrc={
								state.context.data.giftVoucherPrice > 0
									? state.context.data.giftVoucherPrice
									: state.context.data.totalPriceInCents / 100
							}
							onSubmit={popupGiftServiceSubmit}
						/>
					)}
				</div>
			)}
			{(completedSteps.includes('SERVICES_SELECTED') ||
				completedSteps.includes('REINJECT_DATA')) && (
				<Personalization
					{...props}
					colors={cardColors}
					continueButtonRef={continueButtonRef}
					giftMessage={state.context.data.message || ''}
					isPending={isLoading}
					personalizationContainerRef={personalizationContainerRef}
					reinjectData={completedSteps.includes('REINJECT_DATA')}
					selectedTemplate={state.context.data.selectedTemplate}
					templateContainerRef={templateContainerRef}
					templateWasChosen={
						completedSteps.includes('TEMPLATE_CHOSE') ||
						completedSteps.includes('REINJECT_DATA')
					}
					variant={state.context.data.variant}
					onComplete={query => {
						send({ type: 'ON_CHANGE', data: query });
						send({ type: 'PERSONALIZATION_DONE', query });
					}}
					onTemplateChose={data => {
						ctx.USER_CHOSE_GIFT_VOUCHER_TEMPLATE();
						send({ type: 'ON_CHANGE', data });
					}}
					onTemplateValidated={() => {
						if (!completedSteps.includes('TEMPLATE_CHOSE'))
							send({ type: 'TEMPLATE_CHOSE', data });
					}}
				/>
			)}
			{(completedSteps.includes('PERSONALIZATION_DONE') ||
				completedSteps.includes('REINJECT_DATA')) && (
				<AppointmentUser
					appointment={null}
					business={business}
					conditionsLink={`https://www.${credentials.HOST + routes.legal}`}
					isDark={isDark}
					isPending={isLoading}
					selectedUser={user}
					setHasConfirmableUser={hasConfirmableUser => {
						if (hasConfirmableUser) send('AUTH_SUCCESS');
					}}
					style={{ marginBottom: isMobile ? 100 : 150 }}
					userContainerRef={userContainerRef}
					userId={userId}
					onSignOut={() => send('LOGOUT')}
					onSignUp={() => send('AUTH_SUCCESS')}
				/>
			)}
			{(completedSteps.includes('AUTH_SUCCESS') ||
				completedSteps.includes('REINJECT_DATA')) && (
				<AppointmentPayment
					{...props}
					completedSteps={completedSteps}
					confirmGiftVoucher={({
						paymentIntentId,
						newPaymentMethodFromElement
					}) => {
						ctx.USER_BOUGHT_GIFT_VOUCHER();
						//The function is already called in the useEffect if the newPaymentMethodFromElement is defined
						!newPaymentMethodFromElement &&
							submitForm({ paymentIntentId, newPaymentMethodFromElement });
					}}
					contextData={state.context.data}
					isDark={isDark}
					isGiftVoucher
					isLoading={isLoading}
					onlinePaymentServices={{
						isOnline: selectedServices
					}}
					paymentContainerRef={paymentContainerRef}
					setError={error => {
						setErrorIsOnlinePayment(true);
						setError(error);
					}}
					setIsLoading={newIsLoading => setIsLoading(newIsLoading)}
					title={'bookAppointment.giftPaymentTitle'}
					totalPrice={state.context.data.totalPrice}
					totalPriceInCents={state.context.data.totalPriceInCents}
					user={user}
					userId={userId}
				/>
			)}
		</div>
	);
});

const styles = {
	wrapper: {
		width: '100%',
		maxWidth: 976,
		margin: '22px 0 120px 0',
		[breakpoints.desktopQuery]: {
			margin: '22px 0 170px 0'
		}
	}
};
