// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title-module_title-hSgJT{font:var(--semibold) var(--title-3);margin:0;padding:0}.title-module_isDark-fJ8e7{color:var(--white) !important}", "",{"version":3,"sources":["webpack://./../../packages/ui/components/business/business_title/title.module.scss"],"names":[],"mappings":"AAEA,0BACC,mCAAA,CACA,QAAA,CACA,SAAA,CAMD,2BACC,6BAAA","sourcesContent":["@use '@planity/ui/styles';\n\n.title {\n\tfont: var(--semibold) var(--title-3);\n\tmargin: 0;\n\tpadding: 0;\n\t/*@media (max-width: #{styles.$tablet - 1px}) {\n\t\tfont: var(--medium) var(--body-1);\n\t}*/\n}\n\n.isDark {\n\tcolor: var(--white) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"title": "title-module_title-hSgJT",
	"isDark": "title-module_isDark-fJ8e7"
};
module.exports = ___CSS_LOADER_EXPORT___;
