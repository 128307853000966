import React from 'react';
import { useLocalization } from '@planity/localization';
import { cloudinaryURL } from '@planity/components';
import credentials from '@planity/credentials';

export function RatingsMicroDataJson({ business, reviews = [] }) {
	const { localizedBasePath } = useLocalization();
	const formattedReviews = Object.values(reviews).map(review => ({
		'@type': 'Review',
		'author': {
			'@type': 'Person',
			'name': review.author?.name || review.author?.pseudo || 'Anonyme'
		},
		'datePublished': review.ratedAt.slice(0, 10),
		'reviewRating': {
			'@type': 'Rating',
			'bestRating': '5',
			'worstRating': '1',
			'ratingValue': review.reviewRating
		}
	}));

	const picture = business.pictures?.[0];
	const pictureUrl = picture ? cloudinaryURL(picture?.id, picture) : '';
	const aggregateRating =
		business.rating?.globalRating && business.rating?.ratingsCount
			? {
					'@type': 'AggregateRating',
					'ratingValue': +business.rating?.globalRating.toFixed(1),
					'reviewCount': business.rating?.ratingsCount
			  }
			: null;

	const payload = {
		'@context': 'https://schema.org',
		'@type': 'HealthAndBeautyBusiness',
		'name': business.name,
		'image': pictureUrl,
		'description': business.description?.text?.[0],
		'address': {
			'@type': 'PostalAddress',
			'streetAddress': business.address.street,
			'addressLocality': business.address.locality,
			'postalCode': business.address.postalCode,
			'addressCountry': business.countryCode
		},
		'review': formattedReviews,
		'aggregateRating': aggregateRating,
		'telephone': business.phoneNumber,
		'url': `https://www.${credentials.HOST}${localizedBasePath}/${business.slug}` //'https://www.planity.com/revolvhair-chez-y-75004-paris'
	};

	return (
		<script
			dangerouslySetInnerHTML={{
				__html: JSON.stringify(
					payload,
					null,
					process.env.NODE_ENV === 'development' ? 2 : 0
				)
			}}
			type='application/ld+json'
		/>
	);
}
