import React from 'react';
import { CrashHandler, MapMarker, MapboxMap } from '@planity/components';
import { staticMapboxMap } from '@planity/helpers';
import { breakpoints } from '@planity/theme';
import { isNativeApp, sendToUserApp } from '@planity/webview';

// In px - we need this for SSR and CSR to match
const DEFAULT_MAP_HEIGHT = 200;
const DEFAULT_MAP_WIDTH = 760;

export default function Map({
	showInteractiveMap,
	business,
	height,
	width,
	customColor,
	customTheme
}) {
	const { address } = business;
	if (!address) return null;
	const location = businessLocation(business);

	return showInteractiveMap ? (
		<div css={styles.interactiveMap}>
			<CrashHandler errorComponent={<div />}>
				<MapboxMap
					customColor={customColor}
					customTheme={customTheme}
					height={height}
					latitude={location.lat}
					longitude={location.lng}
					width={Number.isNaN(width) ? null : width}
					zoom={14}
				>
					<MapMarker
						color={'var(--grey-700)'}
						latitude={location.lat}
						longitude={location.lng}
						size={44}
						onClick={null}
						onTop
					/>
				</MapboxMap>
			</CrashHandler>
		</div>
	) : (
		<a
			css={[styles.map, { width, height }]}
			href={`https://maps.google.com/maps?daddr=${business?.location?.lat},${business?.location?.lng}`}
			rel='noreferrer'
			style={{
				backgroundImage: `url('${staticMapboxMap(location, {
					height: height || DEFAULT_MAP_HEIGHT,
					width: width || DEFAULT_MAP_WIDTH
				})}')`
			}}
			target={'_blank'}
			onClick={e => {
				if (!isNativeApp) return;
				e.preventDefault();
				sendToUserApp({
					type: 'OPEN_MAP',
					payload: {
						longitude: location?.lng,
						latitude: location?.lat,
						title: business.name
					}
				});
			}}
		/>
	);
}

function businessLocation(business) {
	return (
		business.location || business._geoloc || { lat: 48.8757113, lng: 2.3321877 }
	);
}

const styles = {
	map: {
		position: 'relative',
		display: 'block',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		[breakpoints.tabletQuery]: {
			borderRadius: 'var(--border-radius-medium)',
			overflow: 'hidden'
		}
	},
	interactiveMap: {
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		width: '100%',
		height: '500px',
		[breakpoints.smallerThanTablet]: {
			height: '200px'
		}
	}
};
