import { useEffect, useMemo, useState } from 'react';
import { useFirebaseSubscriptions } from '@planity/datastores';
import {
	formatAvailabilities,
	getServices,
	getShardName,
	parseAvailabilities,
	withStaleAvailabilities
} from './helpers';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

/**
 * New hook to read the availabilities by services in availabailitiesShard
 * and format them into readable data for date pickers
 * @param {*} param0
 * @returns {boolean} param.isLoading loading state when reading from firebase
 * @returns {Error} param.error error when reading from firebase
 * @returns {Error} param.availabilities availabilities formatted from firebase including those which are not "available" anymore since they were first displayed (crossed out)
 * @returns {boolean} param.availabilitiesWithoutStale availabilities formated from firebase
 */
export function useAvailabilities({
	businessId,
	business,
	steps,
	isPreview,
	isAllowedToFetchData = true
}) {
	const [availabilities, setAvailabilities] = useState(null);
	const shard = getShardName(businessId);
	const { ica: hasAppointments } = business;

	const params = {};

	const services = getServices(steps).filter(item => !!item);
	for (const serviceId of services) {
		if (!isPreview && hasAppointments) {
			params[serviceId] = {
				path: `${businessId}/${serviceId}-str`,
				shard
			};
		}
	}

	const {
		data,
		areLoading: isLoading,
		errors: error
	} = useFirebaseSubscriptions(isAllowedToFetchData ? params : {});

	// availabilities from firebase are unreadable cached data so we need to parse them
	const parsedAvailabilities = useMemo(() => {
		if (!Object.keys(data).length) return null; // data is not yet present (loading status)
		const parsed = {};
		for (const key in data) {
			parsed[key] = parseAvailabilities(data[key]);
		}
		return parsed;
	}, [data]);

	// fresh up to date formatted availabilities
	const are = useMemo(() => {
		if (!parsedAvailabilities) return null;
		const _steps = steps.filter(x => !!x.serviceId);
		if (!_steps.length) return null;
		const today = new Date();
		return formatAvailabilities({
			steps: _steps,
			availabilities: parsedAvailabilities,
			today,
			business
		});
	}, [steps, business, parsedAvailabilities]);

	useEffect(() => {
		if (!are) {
			if (!isEmpty(availabilities)) setAvailabilities(null);
			return;
		}
		// up to date availabitities  those which are not "available" anymore since they were first displayed (crossed out)
		const newAvailabilities = withStaleAvailabilities(are, availabilities);
		if (!isEqual(newAvailabilities, availabilities))
			setAvailabilities(newAvailabilities);
	}, [are, availabilities]);

	return {
		isLoading,
		error,
		availabilities,
		availabilitiesWithoutStale: are
	};
}
