import memoize from 'fast-memoize';
import {
	addMinutes,
	format,
	isAfter,
	isBefore,
	isSameMinute,
	parseISO
} from 'date-fns';
import { filter, map, reduce, safeRead } from '../functions';
import { businessServicesById, canChooseServiceSubsteps } from './services';
import { expandFormula } from './skills';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import config from '@planity/credentials';

/**
 * All this logic is copied from the old website
 * in order to avoid rewriting all the fulfill sequence logic
 * TODO: better :)
 */

const DEFAULT_SLOT_VALIDITY = 30;

/**
 * sequence is an array of steps, with a start, a duration, a serviceId and, optionnally, calendarIds
 * [
 *    {start: '2016-11-02 12:00', serviceId: 'X', duration: 15},
 *    {start: '2016-11-02 12:15', serviceId: 'Y', duration: 30}
 * ]
 *
 * availabilities is an object mapping serviceIds to days → hours → calendarId → array of arrays of calendarIds
 * {
 *   $serviceId: {
 *     '2016-11-02': {
 *       '12:00': {
 *          A: [['A']],
 *          B: [['B']]
 *       }
 *     }
 *   }
 * }
 *
 * the fulfillSequence function returns an array of arrays of available calendars
 * if it can fulfill the sequence
 * and null if not
 */

export function fulfillSequence({ sequence, availabilities, business }) {
	const actAsParanoid = (config.PARANOID_AVAILABILITIES || []).includes(
		business.businessId
	);
	const slotValidity =
		safeRead(business, ['settings', 'timeslots', 'intervals']) ||
		DEFAULT_SLOT_VALIDITY;

	let cumulatedDuration = 0;

	return sequence.reduce((fulfillingSequences, step) => {
		if (!fulfillingSequences) return null; // the sequence is already invalidated, skip further computations

		// Add the current step's duration to the cumulated duration
		cumulatedDuration += step.duration;

		const stepStart = step.start;
		const stepEnd = addMinutes(stepStart, cumulatedDuration);
		const formattedDay = format(stepStart, 'yyyy-MM-dd');
		const dayAvailabilities = safeRead(availabilities, [
			step.serviceId,
			formattedDay
		]);
		if (!Object.keys(dayAvailabilities || {}).length) return null; // we don't have timeslots for that service/day
		let availableResources = dayAvailabilities[format(stepStart, 'HH:mm')];
		if (!availableResources) {
			/**
			 * there is no exact match
			 * if there was one - we could be guaranteed that it fits the duration
			 * as it is an availability for that given service
			 * so we have to find a timeslot such that:
			 * - (stepStart >= slotStart) AND (stepStart < slotEnd)
			 * - (stepStart + duration <= slotEnd) OR !!nextSlot
			 * where nextSlot must be such that:
			 * - nextSlotStart === slotEnd
			 * - there is at least a common set of ressources between slot and nextSlot
			 * nextSlot being for the same service, we are guaranteed that nextSlotEnd will be after stepStart + duration
			 *
			 ****
			 *
			 * The whole thing is buggy, as a sequence of appointments with a duration less than timeslotValidity each
			 * could be mistakenly counted as available
			 * Fixing this bug would require changing the way availabilities are stored
			 */

			if (actAsParanoid && step.duration < slotValidity) {
				// Adopting a paranoid attitude for now
				return null;
			}
			const availableTimeslot = Object.keys(dayAvailabilities).find(hour => {
				const start = parseISO(`${formattedDay} ${hour}`);
				const stop = addMinutes(start, slotValidity);
				return isSameOrBefore(start, stepStart) && isAfter(stop, stepStart);
			});
			if (!availableTimeslot) return null;
			const slotStart = parseISO(`${formattedDay} ${availableTimeslot}`);
			const slotEnd = addMinutes(slotStart, slotValidity);
			const slotResources = dayAvailabilities[availableTimeslot];
			if (isSameOrAfter(slotEnd, stepEnd)) {
				availableResources = slotResources;
				// the slot covers the service so we're good
			} else {
				const formattedSlotEnd = format(slotEnd, 'HH:mm');
				// is the next slot available ?
				if (!dayAvailabilities.hasOwnProperty(formattedSlotEnd)) return null; // next slot is not available
				let nextSlotResources = dayAvailabilities[formattedSlotEnd];
				let commonResources;
				if (step.isComplex) {
					// keep for each step, the sets that are both present in slot and nextSlot resources
					commonResources = map(slotResources, (stepResources, index) => {
						return stepResources.filter(set => {
							return setsHaveSet(nextSlotResources[index], set);
						});
					});
				} else {
					commonResources = slotResources.filter(set => {
						return !!nextSlotResources.find(otherSet => {
							return !set.find(resource => !otherSet.includes(resource));
						});
					});
					if (commonResources.find(s => !s)) {
						commonResources = [];
					}
				}
				if (!commonResources.length) return null; // no calendarSet spanning the whole required time
				availableResources = commonResources;
			}
		}
		if (step.calendarIds) {
			// if calendarIds is given, it is an array of arrays of calendarIds
			// [['a', 'b'], ['a', 'c']] is to be interpreted as
			// (a AND b) OR (a AND c)
			availableResources = availableResources.filter(resourceSet => {
				return !!step.calendarIds.find(set => {
					// all of set is in resourceSet
					return !set.find(calendarId => !resourceSet.includes(calendarId));
				});
			});
		}
		if (step.sequence) {
			const cantBeFulfilled = step.sequence.find((calendarIds, index) => {
				const relevantCalendarIds =
					calendarIds && calendarIds.filter(set => !set.includes('ANY'));
				if (relevantCalendarIds && relevantCalendarIds.length) {
					return !relevantCalendarIds.find(idsAsSet => {
						const calendarId = idsAsSet[0];
						const sets = availableResources[index];
						return (
							sets &&
							sets.find(set => {
								return set.indexOf(calendarId) !== -1;
							})
						);
					});
				} else {
					return false;
				}
			});
			const canBeFulfilled = !cantBeFulfilled;
			return canBeFulfilled ? [] : null;
		} else {
			if (!availableResources || !availableResources.length) return null; // safecheck
			fulfillingSequences.push(availableResources);
			return fulfillingSequences; // our resources
		}
	}, []);
}

export const legacyFormatAvailabilities = memoize(
	(_availabilities, business) => {
		if (!Object.keys(_availabilities || {}).length) return _availabilities;
		const availabilities = legacyFormatResourceSets(_availabilities);
		const services = businessServicesById(business);
		return map(availabilities, (serviceAvailabilities, serviceId) => {
			const service = services[serviceId];
			const isComplex = !!service.sequence;
			if (serviceAvailabilities && isComplex) {
				if (canChooseServiceSubsteps(service, business)) {
					return map(serviceAvailabilities, dayAvailabilities =>
						map(dayAvailabilities, hourAvailabilities =>
							filter(
								hourAvailabilities,
								step => step[0] !== 'PAUSE_PLACEHOLDER'
							)
						)
					);
				} else {
					let skilledCalendars;
					if (service.formula) {
						skilledCalendars = Array.from(
							expandFormula(safeRead(services, [serviceId, 'formula'])).reduce(
								(all, set) => {
									set.forEach(calendarId => {
										all.add(calendarId);
									});
									return all;
								},
								new Set()
							)
						);
					} else {
						skilledCalendars = Array.from(
							(service.sequence || []).reduce((all, step) => {
								if (step.serviceId === 'PAUSE') return all;
								let stepSkilledCalendars = expandFormula(
									safeRead(services, [step.serviceId, 'formula'])
								);
								if (!stepSkilledCalendars) return all;
								stepSkilledCalendars = stepSkilledCalendars.reduce(
									(_all, set) => {
										set.forEach(calendarId => {
											_all.add(calendarId);
										});
										return _all;
									},
									new Set()
								);
								stepSkilledCalendars.forEach(calendarId => {
									all.add(calendarId);
								});
								return all;
							}, new Set())
						);
					}
					const relevantCalendars = skilledCalendars.reduce(
						(all, calendarId) => {
							// we want a map calendarId -> steps of which it has the skill
							const relevantSteps = (service.sequence || []).reduce(
								(steps, step, stepIndex) => {
									let isRelevant;
									if (step.serviceId === 'PAUSE') {
										if (step.calendars) {
											isRelevant =
												step.calendars.split(',').indexOf(calendarId) !== -1;
										} else {
											isRelevant = false;
										}
									} else {
										isRelevant = expandFormula(
											safeRead(services, [step.serviceId, 'formula'])
										).find(set => set.includes(calendarId));
									}
									if (isRelevant) {
										steps.push(stepIndex);
									}
									return steps;
								},
								[]
							);
							all[calendarId] = relevantSteps;
							return all;
						},
						{}
					);
					return map(serviceAvailabilities, dayAvailabilities => {
						return map(dayAvailabilities, timeAvailabilities => {
							/**
							 * we consider as available the calendars
							 * that have the skill
							 * and that are available in all of the substeps of which they have the skill
							 * so we want to mimic resource sets including those calendars
							 */
							return reduce(
								relevantCalendars,
								(all, relevantSteps, calendarId) => {
									const isAvailable =
										relevantSteps.find(index => {
											if (!timeAvailabilities[index]) return true;
											return !timeAvailabilities[index].find(set =>
												set.includes(calendarId)
											);
										}) === undefined;
									if (isAvailable) {
										all.push([calendarId]);
									}
									return all;
								},
								[]
							);
						});
					});
				}
			} else {
				return serviceAvailabilities;
			}
		});
	}
);

function legacyFormatResourceSets(availabilities) {
	return map(availabilities, serviceAvailabilities =>
		map(serviceAvailabilities, dayAvailabilities =>
			map(dayAvailabilities, timeAvailabilities =>
				legacyFormatResourceSet(timeAvailabilities)
			)
		)
	);
}

function legacyFormatResourceSet(sets) {
	if (Array.isArray(sets)) {
		return sets.map(step => legacyFormatResourceSet(step));
	} else {
		return uniqWith(Object.values(sets).flat(), isEqual);
	}
}

function isSameOrAfter(x, y) {
	return isSameMinute(x, y) || isAfter(x, y);
}

function isSameOrBefore(x, y) {
	return isSameMinute(x, y) || isBefore(x, y);
}

function setsHaveSet(sets, set) {
	return (
		sets &&
		sets.find(ownSet => {
			return isEqual(ownSet, set);
		})
	);
}
