import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Localize } from '@planity/components';
import { useTranslation } from '@planity/localization';
import { Radio } from '@planity/ui';
import styles from './accept_save_card_selector.module.scss';
import { PURCHASE_TYPES } from '../stripe_payment';

export function AcceptSaveCardSelector({
	onChange,
	hasAcceptedSaveCard,
	isDisabled,
	purchaseType
}) {
	const { t } = useTranslation();
	useStyles(styles);
	const phraseKey =
		purchaseType === PURCHASE_TYPES.GIFT_VOUCHER ||
		purchaseType === PURCHASE_TYPES.ONLINE_SHOP
			? 'purchase'
			: 'text';

	const changeHandler = value => {
		!isDisabled && onChange(value);
	};

	return (
		<form className={styles.saveCard}>
			<span className={styles.label}>
				<Localize text={`onlinePayment.saveCardForFutureReuse.${phraseKey}`} />
			</span>
			<div className={styles.inline}>
				<Radio
					id='radio-save-card-accept'
					isChecked={hasAcceptedSaveCard === true}
					name={'partial-payment'}
					text={t('onlinePayment.saveCardForFutureReuse.yes')}
					textClassName={styles.radioLabel}
					onChange={() => changeHandler(true)}
				/>
				<Radio
					id='radio-save-card-deny'
					isChecked={hasAcceptedSaveCard === false}
					name={'partial-payment'}
					text={t('onlinePayment.saveCardForFutureReuse.no')}
					textClassName={styles.radioLabel}
					onChange={() => changeHandler(false)}
				/>
			</div>
		</form>
	);
}
