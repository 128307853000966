import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import { TextSection } from './text_section';
import credentials from '@planity/credentials';
import { safeRead, useHotjarTag } from '@planity/helpers';
import { useLocalizedRoutes, useTranslation } from '@planity/localization';
import capitalize from 'lodash/capitalize';
import renderTextSections from './render_text_sections';
import { AlgoliaSearch } from '../data';
import { Layout, PageSection, Breadcrumbs } from '../layout';
import { BottomLinks } from '../ui';
import Metadata from './metadata';
import { SearchBar } from './search_bar';
import { Places } from './places';
import { Highlight } from './highlight';
import { AroundMe } from './around_me';
import styles from './category_page.module.scss';
import { Container, Faq } from '@planity/ui';

export const CategoryPage = ({ category, location }) => {
	/**
	 show only well_being feature if process.env.WELL_BEING is true
	 */
	const { routes } = useLocalizedRoutes();
	useHotjarTag('SearchPage');
	const classes = classNames.bind(styles)({
		categoryPage: true
	});
	useStyles(styles);
	const { t } = useTranslation();
	/**
	 * Production places length is known in advances as it is set in the
	 * cacheSEOData lambda, and we know there are enough places.
	 * Non-productive places length is a "best guess" based on what has
	 * been commonly observed.
	 */
	const fallbackPlacesLength =
		process.env.PLANITY_ENV !== 'production'
			? 3
			: category.objectID === 'hair_care'
			? 9
			: 6;

	const fallbackPlaces = Array(fallbackPlacesLength)
		.fill()
		.map((_, i) => ({
			objectID: `placeholder_${i}`,
			name: '',
			preposition: ''
		}));

	return (
		<div className={classes}>
			<AlgoliaSearch
				attributesToHighlight={[]}
				filters={`objectID:${location.pathname}`}
				index={credentials.SEO_DATA}
				localizeResults
			>
				{({ data }) => {
					const seoData = (data && data[0]) || {};
					const children =
						(seoData && seoData.places && seoData.places.children) ||
						fallbackPlaces;
					return (
						<Layout
							hasSearchBar
							headerIsCollapsible
							hideBreadcrumbs
							isDark
							showAppTeaser
							skipCoronavirusModal
						>
							<PageSection className={styles.pageSection}>
								<div className={styles.categoryHeader}>
									<Metadata category={category} />
									<div className={styles.placeInput}>
										<SearchBar search={{ parentCategory: category }} />
										<AroundMe category={category} />
									</div>
								</div>
								<Breadcrumbs
									className={styles.breadcrumbs}
									crumbs={[
										{
											name: capitalize(category.singular || category.name),
											path: routes.catchAll({ category })
										}
									]}
									removePadding
								/>
								<Container
									className={styles.citiesContainer}
									noStupidMarginBottom
								>
									<Places category={category} places={children} />
								</Container>
								{category.parentIsBusinessCategory ? (
									<>
										<Container className={styles.textContainer}>
											<div className={styles.textsWrapper}>
												<Highlight
													className={styles.highlight}
													highlight={category?.texts?.highlight}
												/>
												{renderTextSections(category)}
											</div>
										</Container>
										<Faq
											pageKey={`${category.objectID}`}
											translationKey={`${category.objectID}.faq`}
										/>
									</>
								) : (
									<Container className={styles.textContainer}>
										<div className={styles.textsWrapper}>
											<Highlight
												className={styles.highlight}
												highlight={category?.texts?.highlight}
											/>
											<TextSection key={0} {...texts(category)[0]} />
											<BottomLinks
												className={styles.bottomLinks}
												getPath={service =>
													routes.catchAll({
														search: {
															parentCategory: category,
															category: {
																...service,
																parentId: category.objectID
															}
														}
													})
												}
												getText={getServiceTitle}
												links={seoData.refiners}
												title={t('category.frequentSearches')}
											/>
											<TextSection key={1} {...texts(category)[1]} />
											<BottomLinks
												getPath={parentPlace =>
													routes.catchAll({
														search: {
															parentCategory: category,
															parentPlace
														}
													})
												}
												getText={place => getPlaceTitle(place, category)}
												links={safeRead(seoData, ['places', 'other'])}
												title={t('category.departments')}
											/>
											<BottomLinks
												getPath={business => routes.catchAll({ business })}
												getText={business => business.name}
												isLast={true}
												links={seoData.businesses}
												title={
													category
														? t('category.lastBusinesses', {
																name: category.name
														  })
														: ''
												}
											/>
										</div>
									</Container>
								)}
							</PageSection>
						</Layout>
					);
				}}
			</AlgoliaSearch>
		</div>
	);
};

const texts = category =>
	safeRead(category, [`texts`, 'sections'], {
		value: []
	});

const getServiceTitle = service => service.name || service.slug || '';

export const getPlaceTitle = (place, category) =>
	`${category.name} ${place.preposition} ${place.name}`;
