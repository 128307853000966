import { Machine, assign } from 'xstate';
import { cacFlowMachine } from './cacFlowMachine';
import { CLICK_AND_COLLECT_MACHINE_STATES } from './clickAndCollectMachineStates';

export const clickAndCollectMachine = Machine(
	{
		id: 'clickAndCollect',
		initial: CLICK_AND_COLLECT_MACHINE_STATES.IDLE,
		context: {
			data: {
				products: [],
				businessId: null
			}
		},
		states: {
			[CLICK_AND_COLLECT_MACHINE_STATES.IDLE]: {
				on: {
					ADD_PRODUCT: {
						target: CLICK_AND_COLLECT_MACHINE_STATES.CAC_FLOW_MACHINE,
						actions: ['updateCart', 'addBusinessId']
					}
				}
			},
			[CLICK_AND_COLLECT_MACHINE_STATES.CAC_FLOW_MACHINE]: {
				invoke: {
					id: 'cacFlow',
					src: cacFlowMachine,
					data: {
						data: (ctx, { products, businessId }) => {
							return {
								paymentMethod: ctx.data.paymentMethod || null,
								paymentIntentId: ctx.data.paymentIntentId || null,
								userId: ctx.data.userId || null,
								error: null,
								products: ctx.data.products || products || null,
								businessId: ctx.data.businessId || businessId || null,
								totalPrice: ctx.data.totalPrice || products?.[0]?.price || null,
								isRedirect: ctx.data.isRedirect || false,
								isClickAndCollect: ctx.data.isClickAndCollect || null,
								isClickAndShop: ctx.data.isClickAndShop || null,
								deliveryAddress: ctx.data.deliveryAddress || {},
								billingAddress: ctx.data.billingAddress || {},
								shippingFees: ctx.data.shippingFees || 0
							};
						}
					},
					onDone: {
						target: CLICK_AND_COLLECT_MACHINE_STATES.IDLE,
						actions: ['reset']
					},
					onError: CLICK_AND_COLLECT_MACHINE_STATES.IDLE
				}
			}
		},
		on: {
			REINJECT_DATA: {
				target: CLICK_AND_COLLECT_MACHINE_STATES.CAC_FLOW_MACHINE,
				actions: ['reinjectData']
			},
			RESET_DISPLAY_CONFIRMATION_PAGE: {
				target: CLICK_AND_COLLECT_MACHINE_STATES.IDLE,
				actions: ['resetDisplayConfirmationPage']
			}
		}
	},
	{
		actions: {
			reinjectData: assign({
				data: (ctx, event) => {
					return {
						...ctx.data,
						...event.contextData,
						isRedirect: true
					};
				}
			}),
			updateCart: assign({
				data: (ctx, { products }) => {
					return {
						...ctx.data,
						products
					};
				}
			}),
			addBusinessId: assign({
				data: (ctx, { businessId }) => ({
					...ctx.data,
					businessId
				})
			}),
			reset: assign({
				data: () => ({
					products: [],
					businessId: null,
					isDisplayConfirmationPage: true
				})
			}),
			resetDisplayConfirmationPage: assign({
				data: () => ({
					products: [],
					businessId: null,
					isDisplayConfirmationPage: false
				})
			})
		}
	}
);
