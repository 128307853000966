import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import { formatPhoneNumber } from '@planity/helpers';
import { Button } from '@planity/ui';
import { useTranslation } from '@planity/localization';
import styles from './selected_user.scss';

export const SelectedUser = props => {
	useStyles(styles);

	const { t } = useTranslation();
	const {
		bookedByToDelete,
		isBookAppointment,
		isPending,
		isVerified,
		selectedUser,
		setIsEditingProfile,
		setHasEditedProfile,
		setSelectedUser,
		hasChildren,
		user,
		canAddFirstChild
	} = props;
	const classes = classNames.bind(styles)({
		selectedUser: true
	});

	const canChangeSelectedUser =
		isBookAppointment && hasChildren && !bookedByToDelete;

	const formatUserProfile = () => {
		const phoneNumber =
			selectedUser.phone && formatPhoneNumber(selectedUser.phone);
		return (
			<div className={styles.info}>
				<span className={styles.name}>{selectedUser.name}</span>
				{selectedUser.email && (
					<span className={styles.email} id='selected-user-mail'>
						{selectedUser.email}
					</span>
				)}
				{phoneNumber && (
					<span className={styles.phone} id='selected-user-phone'>
						{phoneNumber}
					</span>
				)}
				{(!isBookAppointment || selectedUser === user) && !isVerified && (
					<span>{` ${t('bookAppointment.userProfile.notVerified')}`}</span>
				)}
			</div>
		);
	};

	return (
		<div className={classes}>
			{
				<div
					className={`${styles.description} ${
						canAddFirstChild ? styles.bottomLine : ''
					}`}
				>
					{formatUserProfile()}
					<div className={styles.modifyButton}>
						<Button
							id='select-user-edit-button'
							isLoading={isPending}
							label={t('common.edit')}
							type={'underlined'}
							onClick={() => {
								if (canChangeSelectedUser) {
									setSelectedUser(null);
								} else {
									setIsEditingProfile(true);
									setHasEditedProfile(true);
								}
							}}
						/>
					</div>
				</div>
			}
		</div>
	);
};
