import React from 'react';

export function CheckCircle({ color }) {
	return (
		<svg
			width='10'
			height='10'
			viewBox='0 0 10 10'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M5 0C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 5 0ZM5 9C2.795 9 1 7.205 1 5C1 2.795 2.795 1 5 1C7.205 1 9 2.795 9 5C9 7.205 7.205 9 5 9ZM7.295 2.79L4 6.085L2.705 4.795L2 5.5L4 7.5L8 3.5L7.295 2.79Z'
				fill={color}
			/>
		</svg>
	);
}
