export default {
	FIREBASE_API_KEY: 'AIzaSyDrSE1PzMwLKyhEvh2x8eV7s1NYwKGRC5Q',
	FIREBASE_AUTH_DOMAIN: 'planity-production.firebaseapp.com',
	FIREBASE_DATABASE_URL: 'https://planity-production.firebaseio.com',
	FIREBASE_AVAILABILITIES_SHARD_URL:
		'https://planity-production-availabilities.firebaseio.com',
	FIREBASE_PUBLIC_SHARD_URL: 'https://planity-production-public.firebaseio.com',
	FIREBASE_AVAILABILITIES_SHARD_1_URL:
		'https://planity-production-availabilities-1.europe-west1.firebasedatabase.app',
	FIREBASE_AVAILABILITIES_SHARD_2_URL:
		'https://planity-production-availabilities-2.europe-west1.firebasedatabase.app',
	FIREBASE_STORAGE_BUCKET: 'planity-production.appspot.com',
	AWS_API_ENDPOINT:
		'https://hupf8gmctj.execute-api.eu-west-1.amazonaws.com/prod',
	ALGOLIA_APP_ID: 'DAY79MUBW3',
	ALGOLIA_API_KEY: '8ec84cdda274cec79b9ad155973bc864',
	ALGOLIA_PRODUCTION_APP_ID: 'DAY79MUBW3',
	ALGOLIA_PRODUCTION_API_KEY: '8ec84cdda274cec79b9ad155973bc864',
	ALGOLIA_PLACES_APP_ID: 'pl574WJIL1Q2',
	ALGOLIA_PLACES_API_KEY: '719fde67c8532799b0f99a4d2b454daa',
	GOOGLE_API_BROWSER_KEY:
		process.env.NODE_ENV === 'development'
			? 'AIzaSyBvhQQf1t6BHskINw9SyKdI8AE0pW4KTFc'
			: 'AIzaSyBWRZvP8-9YfNXbPU_UEQM2i4WHigq3t8s',
	GOOGLE_API_SERVER_KEY: 'AIzaSyBADSy0MTRG83rTYztP2na1XFPKpAMBONU',
	HOST: 'planity.com',
	CLOUD_FUNCTIONS_DOMAIN:
		'https://us-central1-planity-production.cloudfunctions.net',
	GOOGLE_ANALYTICS_TRACKING_CODE: 'UA-63406788-1',
	GOOGLE_ANALYTICS_V4_TRACKING_CODE: 'G-QD7EHY9J3J',
	GOOGLE_ADWORDS_REMARKETING_CONVERSION_ID: '948911493',
	MAPBOX_API_KEY:
		'pk.eyJ1IjoicGxhbml0eSIsImEiOiI4QU5kRy1jIn0.suHIBfZHkiiPnuVFhQSRMg',
	USER_APP_LINK: 'https://planity.onelink.me/kxeJ/krrk0yft',
	PARANOID_AVAILABILITIES: ['-KkFHFf846NkpecOChCw'],
	STRIPE_API_KEY: 'pk_live_OWXlY0lqVRPZlv3mVDhyVXNW',
	STRIPE_API_KEY_FR: 'pk_live_OWXlY0lqVRPZlv3mVDhyVXNW',
	STRIPE_API_KEY_BE:
		'pk_live_51Lfk9VFCwhxbUJ7mJfTebHtMjLfH0O3UWN5DPFSY2aSwE4EmgF40uzVZpUzd0yVsMXu2iKUwEdnukcNHnX0SYljc00sGSMOKBe',
	STRIPE_API_KEY_DE:
		'pk_live_51L15sZE7ePEuIRmRhmU66ATcQvfQ2rSVzpwMCv09FZcFHyGh2n68yhJH8Qm6AaDMBGeGrHTnO8sqvcN2M0dVpyu700DfrvNqRe',
	DISPLAY_REVIEWS: true,
	ENABLE_FACEBOOK_LOGIN: false,
	ENABLE_GOOGLE_LOGIN: false,
	ENABLE_GITHUB_LOGIN: false,
	AVAILABLE_PROVIDERS: ['password'],
	HIDE_GIFT_VOUCHER_BUTTON: false,
	ENABLE_USER_PAYS_FEES: true,
	PLANITY_FEE: 1.58,
	PLANITY_FLAT: 18,
	STRIPE_FEE: 0,
	STRIPE_FLAT: 0,
	HIDE_ESHOP: false,
	ENABLE_CLICK_AND_COLLECT: 'true',
	ENABLE_CLICK_AND_SHOP: true,
	WOOTRIC_ACCOUNT_TOKEN: 'NPS-593dabed',
	WOOTRIC_NO_SURVEYED_COOKIES: false,
	WOOTRIC_SURVEY_IMMEDIATELY: false,
	ENABLE_NPS: true,
	ENABLE_ENRICHMENT: true,
	CATEGORIES_INDEX: 'categories',
	TAGS_INDEX: 'tags',
	PLACES_INDEX: 'places',
	BUSINESSES_INDEX: 'businesses',
	BUSINESSES_BY_AVERAGE_DESC_INDEX: 'businesses_byAverage_desc',
	BUSINESSES_BY_AVERAGE_ASC_INDEX: 'businesses_byAverage',
	BUSINESSES_BY_RATING_ASC_INDEX: 'businesses_byRating',
	SEO_DATA: 'seo_data_migration',
	RECAPTCHA_SITE_KEY: '6LdTyTYgAAAAAIl3AqUaGHD61m3kxMAmqRPKkBHY',
	RECAPTCHA_MACHINE_ARN:
		'arn:aws:states:eu-west-1:637668567762:stateMachine:sendPhoneNumberVerificationCodeRecaptchaProtected',
	DIDOMI_PUBLIC_KEY: '13d3ec9d-8fde-4b8f-a007-4e60d6965caf',
	DIDOMI_NOTICE_ID: '6frjhVpH',
	DIDOMI_NOTICE_ID_WHITE_LABEL: 'EJimbWgx',
	SAVE_CARD_CONTROL_ACTIVATION_DATE: 1694609216,
	IOS_USER_APP_ID: 'E3KBT754EQ.com.planity.user.mobile',
	ANDROID_USER_APP_ID: 'com.planitypublic',
	ANDROID_SHA256_CERT_FINGERPRINTS: [
		'29:8A:0F:CC:D5:0D:97:60:E8:A6:9C:78:07:14:E6:9F:7D:89:71:23:B8:10:E0:30:E0:82:E7:BA:2B:6C:2C:87', // Certificate release
		'37:97:1B:34:DD:A4:CA:7E:D8:FD:DE:91:80:14:B8:2E:39:39:DB:E1:2B:54:6E:D0:22:54:EF:36:0A:B5:E9:A0' // Certificate debug
	],
	GOOGLE_CONVERSION_ENDPOINT: 'https://www.google.com/maps/conversion/collect',
	GOOGLE_PARTNERID: 20000120,
	USE_GIFT_VOUCHER_FOR_PAYMENTS_ENABLED: true,
	USE_CURE_FOR_PAYMENTS_ENABLED: false
};
