import React from 'react';

export function CloseCircle({ color }) {
	return (
		<svg
			width='10'
			height='10'
			viewBox='0 0 10 10'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M5 0C2.235 0 0 2.235 0 5C0 7.765 2.235 10 5 10C7.765 10 10 7.765 10 5C10 2.235 7.765 0 5 0ZM5 9C2.795 9 1 7.205 1 5C1 2.795 2.795 1 5 1C7.205 1 9 2.795 9 5C9 7.205 7.205 9 5 9ZM6.795 2.5L5 4.295L3.205 2.5L2.5 3.205L4.295 5L2.5 6.795L3.205 7.5L5 5.705L6.795 7.5L7.5 6.795L5.705 5L7.5 3.205L6.795 2.5Z'
				fill={color}
			/>
		</svg>
	);
}
