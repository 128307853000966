import { useLocalizedRoutes, useTranslation } from '@planity/localization';
import React, { useState, useEffect } from 'react';
import { ErrorMessage, useErrorMessages } from '@planity/components';
import styles from './reset_password_page.module.scss';
import classNames from 'classnames/bind';
import { firebase } from '@planity/datastores';
import { Layout } from '../layout';
import { Image } from '@planity/ui';
import { ResetPasswordForm } from './reset_password_form';
import { CancelEmailModification } from './cancel_email_modification';
import { useRandomImages } from '@planity/helpers/useRandomImages';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useHistory, useLocation } from 'react-router-dom';
import { invokeLambda } from '@planity/helpers';
import Helmet from 'react-helmet';

const MISSING_PASSWORD_ERROR = 'MISSING_PASSWORD_ERROR';
const INVALID_PASSWORD_ERROR = 'auth/weak-password';
const NETWORK_ERROR = 'auth/network-request-failed';
const INVALID_ACTION_CODE_ERROR = 'auth/invalid-action-code';
const EXPIRED_ACTION_CODE_ERROR = 'auth/expired-action-code';

const WHITELISTED_ERRORS = [
	MISSING_PASSWORD_ERROR,
	INVALID_PASSWORD_ERROR,
	NETWORK_ERROR,
	INVALID_ACTION_CODE_ERROR,
	EXPIRED_ACTION_CODE_ERROR
].map(e => `resetPassword.errors.${e}`);

export function ResetPasswordPage() {
	const [password, setPassword] = useState('');
	const [error, setError] = useState(null);
	const [isPending, setIsPending] = useState(false);
	const [isPro, setIsPro] = useState(null);
	const location = useLocation();
	const history = useHistory();
	useStyles(styles);
	const classes = classNames.bind(styles);
	const { routes } = useLocalizedRoutes();
	const imageUrl = useRandomImages();
	const { pushMessage } = useErrorMessages();
	const { t } = useTranslation();

	const errorPassword = error
		? error.toLowerCase().indexOf('password') > -1
		: false;

	const searchParams = new URLSearchParams(location?.search);
	const mode = searchParams.get('mode');
	const oobCode = searchParams.get('oobCode');

	useEffect(() => {
		invokeLambda('checkUserStatus', { oobCode })
			.then(res => {
				setIsPro(res.isPro);
			})
			.catch(e => {
				console.error(e);
			});
	}, []);

	const onSubmit = async e => {
		try {
			if (e && e.preventDefault) e.preventDefault();
			if (!isPending && (!error || error === NETWORK_ERROR)) {
				if (password) {
					setIsPending(true);
					setError(null);

					const email = await firebase.auth().verifyPasswordResetCode(oobCode);
					if (email) {
						await firebase.auth().confirmPasswordReset(oobCode, password);
						await firebase.auth().signInWithEmailAndPassword(email, password);
						pushMessage({
							message: t('userProfileForm.errors.updatePasswordSuccess'),
							type: 'success',
							createdAt: Date.now()
						});
						return history.push(routes.myAccount);
					}
				} else {
					setError(MISSING_PASSWORD_ERROR);
				}
			}
		} catch (error) {
			setError(error.code);
		} finally {
			setIsPending(false);
		}
	};

	const handleDispatchInputs = password => {
		setPassword(password);
		setError(prevError => {
			if (prevError) {
				return null;
			}
			return prevError;
		});
	};

	return (
		<div
			className={classes({
				resetPassword: true
			})}
		>
			<Helmet>
				<meta content={'noindex, nofollow'} name={'robots'} />
			</Helmet>
			<Layout
				hideBreadcrumbs
				hideFooter
				isDark
				skipCoronavirusModal
				withHeaderMarginTop
			>
				<ErrorMessage
					defaultMessage={'resetPassword.errors.defaultError'}
					error={
						error &&
						`resetPassword.errors.${
							errorPassword ? 'MISSING_ALL_INPUTS_ERROR' : error
						}`
					}
					whitelist={WHITELISTED_ERRORS}
				/>
				<div className={classes({ columns: true })}>
					{mode === 'recoverEmail' ? (
						<CancelEmailModification />
					) : (
						<ResetPasswordForm
							error={error}
							errorPassword={errorPassword}
							handleDispatchEvents={onSubmit}
							handleDispatchInputs={handleDispatchInputs}
							isPending={isPending}
							isPro={isPro}
							password={password}
						/>
					)}
					<div className={classes({ image: true })}>
						<Image fit='cover' isCloudinary url={imageUrl} />
					</div>
				</div>
			</Layout>
		</div>
	);
}
