import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { capitalize } from 'lodash';
import credentials from '@planity/credentials';
import { useHotjarTag } from '@planity/helpers';
import { useLocalizedRoutes } from '@planity/localization';
import { AlgoliaSearch } from '../data';
import { Layout, PageSection, Breadcrumbs } from '../layout';
import Metadata from './metadata';
import { SearchBar } from './search_bar';
import { BusinessCategories } from './business_categories';
import { AroundMe } from './around_me';
import { Highlight } from './highlight';
import renderTextSections from './render_text_sections';
import styles from './business_categories_page.module.scss';
import { Container } from '@planity/ui';

export const BusinessCategoriesPage = ({ category }) => {
	/**
	 show only well_being feature if process.env.WELL_BEING is true
	 */
	const { routes } = useLocalizedRoutes();
	const isBusinessCategory =
		category.isBusinessCategory || category.parentIsBusinessCategory;
	if (isBusinessCategory && !process.env.WELL_BEING) {
		return <Redirect to={routes.home} />;
	}
	useHotjarTag('SearchPage');
	const classes = classNames.bind(styles)({
		businessCategoryPage: true
	});
	useStyles(styles);
	return (
		<div className={classes}>
			<AlgoliaSearch
				attributesToHighlight={[]}
				filters={`parentId:${category.objectID}`}
				index={credentials.CATEGORIES_INDEX}
				localizeResults
			>
				{({ data }) => {
					const childrenCategoriesData = data || [];
					return (
						<Layout
							hasSearchBar
							headerIsCollapsible
							hideBreadcrumbs
							isDark
							showAppTeaser
							skipCoronavirusModal
						>
							<PageSection className={styles.pageSection}>
								<div className={styles.categoryHeader}>
									<Metadata category={category} />
									<div className={styles.placeInput}>
										<SearchBar search={{ parentCategory: category }} />
										<AroundMe category={category} />
									</div>
								</div>
								<Breadcrumbs
									className={styles.breadcrumbs}
									crumbs={[
										{
											name: capitalize(category.singular || category.name),
											path: routes.catchAll({ category })
										}
									]}
									removePadding
								/>
								<Container
									className={styles.citiesContainer}
									noStupidMarginBottom
								>
									<BusinessCategories categories={childrenCategoriesData} />
								</Container>
								<Container className={styles.textContainer}>
									<div className={styles.textsWrapper}>
										<Highlight
											className={styles.highlight}
											highlight={category?.texts?.highlight}
										/>
										{renderTextSections(category)}
									</div>
								</Container>
							</PageSection>
						</Layout>
					);
				}}
			</AlgoliaSearch>
		</div>
	);
};
