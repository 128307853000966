import sortBy from 'lodash/sortBy';
import { getSEOCatToPushFromCountryCode } from '../config/seoTags';

const buildPath = ({
	category,
	parentCategory,
	parentPlace,
	place,
	usePlace,
	routes
}) =>
	routes.catchAll({
		search: {
			category,
			parentCategory,
			parentPlace: parentPlace?.type === 'department' ? null : parentPlace,
			place: usePlace ? place : null
		}
	});

const buildName = ({ category, parentPlace, place, usePlace }) => {
	const categoryName = category.seoName || category.name;

	if (!parentPlace || !place) {
		return categoryName;
	}

	return categoryName + ' ' + (usePlace ? place?.name : parentPlace?.name);
};

const addSEOTagFunctionBuilder =
	({ parentPlace, place, routes, existingPathOnPage, seoCatIds, language }) =>
	({ seoTagsMap, tagId, score, category, parentCategory, usePlace }) => {
		if (!seoCatIds.includes(category.objectID)) {
			return;
		}

		const path = buildPath({
			category,
			parentCategory,
			parentPlace,
			place,
			usePlace,
			routes
		});

		if (existingPathOnPage.includes(path.pathname)) {
			return;
		}

		seoTagsMap[tagId] = {
			tagId,
			score: seoTagsMap[tagId] ? seoTagsMap[tagId].score + score : score,
			name: buildName({
				category,
				language,
				parentPlace,
				place,
				usePlace
			}),
			path
		};
	};

// if we have a tag for "hair_care" in a city with districts --> "Salon de coiffure Paris"
// Then we want to add an extra tag like "Coiffeurs Paris 2ème"
const addSpecificHairCareTag = ({
	seoTagsMap,
	categories,
	parentPlace,
	language,
	addSEOTag
}) => {
	const hairCareTag = Object.values(seoTagsMap).find(
		({ tagId }) => tagId === 'hair_care'
	);

	const hairCareCategory = categories.find(
		({ objectID }) => objectID === 'hair_care'
	);

	const shouldAddDistrictTag =
		hairCareTag !== undefined &&
		hairCareCategory !== undefined &&
		parentPlace?.type === 'locality' &&
		language === 'fr';

	if (!shouldAddDistrictTag) {
		return;
	}

	addSEOTag({
		seoTagsMap,
		tagId: 'hair_care_district',
		score: hairCareTag.score,
		category: hairCareCategory,
		parentCategory: null,
		usePlace: true
	});
};

export const buildSEOTags = ({
	categories,
	countryCode,
	crumbs,
	language,
	parentPlace,
	place,
	routes,
	seoTagsScores
}) => {
	const seoTagsMap = {};
	const existingPathOnPage = crumbs.map(crumb => crumb.path.pathname);
	const seoCatIds = getSEOCatToPushFromCountryCode(countryCode);
	const addSEOTag = addSEOTagFunctionBuilder({
		parentPlace,
		place,
		routes,
		existingPathOnPage,
		seoCatIds,
		language
	});

	seoTagsScores.forEach(({ score, tagId }) => {
		const category = categories.find(({ objectID }) => objectID === tagId);

		if (!category) {
			return;
		}

		const parentCategory = categories.find(
			({ objectID }) => objectID === category.parentId
		);

		addSEOTag({
			seoTagsMap,
			tagId: category.objectID,
			score,
			category,
			parentCategory,
			usePlace: parentPlace?.type === 'department'
		});

		if (parentCategory !== undefined) {
			addSEOTag({
				seoTagsMap,
				tagId: parentCategory.objectID,
				score,
				category:
					parentCategory.objectID === 'hair_care' && language === 'fr'
						? { ...parentCategory, name: 'Salon de coiffure' }
						: parentCategory,
				parentCategory: null,
				usePlace: parentPlace?.type === 'department'
			});
		}
	});

	addSpecificHairCareTag({
		seoTagsMap,
		categories,
		parentPlace,
		language,
		addSEOTag
	});

	return sortBy(Object.values(seoTagsMap), ['score']).reverse().slice(0, 10);
};
