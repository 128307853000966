import React from 'react';
import { Form, Link, SocialButton } from '@planity/components';
import { RESET_MACHINE, SIGN_IN_WITH_GOOGLE } from '../../auth_actions';
import { Localize } from '@planity/components';
import { useFormHooks } from '../useFormHooks';
export const LoginWithGoogle = () => {
	const { dispatch, formValues } = useFormHooks();
	const { pending, children } = formValues;
	return (
		<Form>
			{children}
			<p>
				<Localize text={'auth.texts.useGoogleAuth'} />
			</p>
			<SocialButton
				css={[css.button, css.socialButton]}
				isAlone
				isDisabled={pending}
				provider={'google'}
				onPress={() => dispatch({ type: SIGN_IN_WITH_GOOGLE })}
			/>
			<div css={css.bottomLink}>
				<Link
					localizedText={`auth.form.links.BACK_TO_SIGN_IN`}
					onPress={() => dispatch({ type: RESET_MACHINE })}
				/>
			</div>
		</Form>
	);
};
const css = {
	button: {
		marginBottom: 18,
		width: '100%'
	},
	bottomLink: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		alignContent: 'center',
		fontSize: 16,
		lineHeight: 1.13
	},
	socialButton: {
		flex: 1,
		marginBottom: 6
	}
};
