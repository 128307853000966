import { useLocalizedRoutes } from '@planity/localization';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { breakpoints } from '@planity/theme';
import {
	LngLat,
	LngLatBounds,
	fitBounds,
	MAX_MEMOIZED_BUSINESSES_COUNT,
	USE_MEMOIZED_BUSINESS,
	DEFAULT_MAP_ZOOM,
	useIsomorphicLayoutEffect
} from '@planity/helpers';
import {
	CrashHandler,
	MapMarker,
	MapboxMap,
	MapUserMarker
} from '@planity/components';
import WindowSize from '@reach/window-size';
import { InfoWindow } from './info_window';
import {
	ASIDE_WIDTH_DESKTOP,
	MAIN_SIZE_LARGE_DESKTOP,
	FULL_SEARCHBAR_HEADER_HEIGHT
} from '../../style';
import isEqual from 'lodash/isEqual';

export function SearchPageMap({
	businesses,
	hoveredBusinessId,
	isMobile,
	search,
	showNoResults,
	userLocation,
	businessesAreAllDirectories,
	onBusinessHover
}) {
	const history = useHistory();
	const { routes } = useLocalizedRoutes();
	const [containerRef, mapTop] = usePinMapToViewport(isMobile, showNoResults);
	const wrapperStyle = [
		styles.mapWrapper(isMobile ? mapTop : FULL_SEARCHBAR_HEADER_HEIGHT),
		isMobile && styles.mobileMap
	];

	const updateMapLocations = useCallback(
		bounds => {
			if (!bounds) {
				return;
			}
			const ne = bounds.getNorthEast();
			const sw = bounds.getSouthWest();
			history.replace(
				routes.catchAll({
					search: {
						...search,
						bounds: { ne, sw },
						page: 1
					}
				})
			);
		},
		[history, search]
	);

	const memoizedBusinesses = useRef({});
	useEffect(() => {
		Object.assign(
			memoizedBusinesses.current,
			Object.fromEntries(
				(businesses || [])
					.map(b => [b.objectID, b])
					.slice(0, MAX_MEMOIZED_BUSINESSES_COUNT)
			)
		);
	}, [businesses]);

	if (!process.env.BROWSER) {
		return <div css={wrapperStyle} />;
	}

	return (
		<WindowSize>
			{windowSize => {
				const [mapWidth, mapHeight] = getMapDimensions(
					windowSize,
					isMobile,
					mapTop
				);
				if (!mapWidth) {
					return <div css={wrapperStyle} ref={containerRef} />;
				}
				const { center, zoom } = searchBounds(businesses, search.bounds, {
					width: mapWidth,
					height: mapHeight
				});
				if (!center || zoom < 0) {
					return null;
				}
				return (
					<div css={wrapperStyle} ref={containerRef}>
						<CrashHandler errorComponent={<div />}>
							<MapboxMap
								boundsContext={search.bounds}
								height={mapHeight}
								isMobile={isMobile}
								latitude={center.lat}
								longitude={center.lng}
								width={mapWidth}
								zoom={zoom}
								onViewportChange={updateMapLocations}
							>
								{(
									(isMobile && USE_MEMOIZED_BUSINESS
										? Object.values(memoizedBusinesses.current || {})
										: businesses) || []
								).map(business => {
									const isHovered = hoveredBusinessId === business.objectID;
									const color = isHovered
										? 'var(--primary-200)'
										: 'var(--white)';
									const coords = businessLocation(business);
									if (!business.location && !business._geoloc) return;
									if (
										(!business.plStatus || business.plStatus < 3) &&
										!businessesAreAllDirectories
									) {
										return null;
									}
									return (
										<MapMarker
											color={color}
											key={business.objectID}
											latitude={coords.lat}
											longitude={coords.lng}
											popup={
												isMobile && (
													<InfoWindow
														business={business}
														history={history}
														localizedRoutes={routes}
														location={coords}
													/>
												)
											}
											size={44}
											onClick={() =>
												onBusinessHover && onBusinessHover(business.objectID)
											}
											onTop={isHovered}
										/>
									);
								})}
								{userLocation && (
									<MapUserMarker
										latitude={userLocation.lat}
										longitude={userLocation.lng}
									/>
								)}
							</MapboxMap>
						</CrashHandler>
					</div>
				);
			}}
		</WindowSize>
	);
}

function usePinMapToViewport(isMobile, showNoResults) {
	const container = useRef(null);
	// top = la hauteur du reste header + tabs, pour determiner la hauteur restant dispo pour la map
	const [top, setTop] = useState(null);
	const [isMounted, setIsMounted] = useState(false);
	useEffect(() => {
		setIsMounted(true);
	}, []);
	useIsomorphicLayoutEffect(() => {
		if (isMobile && isMounted && !showNoResults) {
			const overflow = document.body.style.overflow;
			const position = document.body.style.position;
			const width = document.body.style.width;
			document.body.style.overflow = 'hidden';
			document.body.style.position = 'fixed';
			document.body.style.width = '100%';
			if (container.current) {
				setTop(container.current.getBoundingClientRect().top);
			}
			return () => {
				document.body.style.overflow = overflow;
				document.body.style.position = position;
				document.body.style.width = width;
			};
		} else if (isMounted) {
			if (container.current) {
				setTop(container.current.getBoundingClientRect().top);
			}
		}
	}, [isMobile, isMounted, container.current, showNoResults]);
	return [container, top];
}

function getMapDimensions(windowSize, isMobile, top) {
	// mobile map
	if (isMobile) {
		if (top === null) {
			return [0];
		}
		return [windowSize.width, windowSize.height - (top || 0)];
	}
	// breakpoint mobile => don't display map
	if (windowSize.width < breakpoints.desktop) {
		return [0];
	}
	// breakpoint desktop
	if (windowSize.width < breakpoints.largeDesktop) {
		return [
			(windowSize.width * ASIDE_WIDTH_DESKTOP) / 100,
			windowSize.height - FULL_SEARCHBAR_HEADER_HEIGHT
		];
	}
	// breakpoint large desktop
	return [
		windowSize.width - MAIN_SIZE_LARGE_DESKTOP,
		windowSize.height - FULL_SEARCHBAR_HEADER_HEIGHT
	];
}

const styles = {
	button: {
		position: 'relative'
	},
	image: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		zIndex: -1
	},
	mapWrapper: top => ({
		'display': 'none',
		'overflow': 'hidden',
		[breakpoints.desktopQuery]: {
			display: 'block',
			position: 'sticky',
			top: top || 0 // height of the header + searchbar + tags
		},
		'& > div >div > canvas': {
			outline: 'none !important',
			height: '100% !important'
		}
	}),
	mapBackground: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100vh'
	},
	mobileMap: {
		display: 'block !important',
		minHeight: '100%',
		height: '100%'
	}
};

function searchBounds(businesses, bounds, mapDimensions) {
	// If businesses all have the same geoloc, the map has no zoom (you see the whole planet)
	// Being optimistic that there will not be thousands of businesses at the exact same location
	const hasDifferentLocation = businesses.some(_business => {
		if (!_business.location && !_business._geoloc) return false;
		return businesses.find(business => {
			const _lngLat = businessLocation(_business);
			const lngLat = businessLocation(business);
			return !isEqual(_lngLat, lngLat);
		});
	});

	if (bounds) {
		return fitBounds(bounds, mapDimensions);
	} else if (!businesses || !businesses.length) {
		return {};
	} else if (businesses.length === 1 || !hasDifferentLocation) {
		return {
			zoom: DEFAULT_MAP_ZOOM,
			center: businessLocation(businesses[0])
		};
	} else {
		const businessesBounds = businesses.reduce((allBounds, business) => {
			if (!business.location && !business._geoloc) return allBounds;
			const { lng, lat } = businessLocation(business);
			return allBounds.extend(new LngLat(lng, lat));
		}, new LngLatBounds());
		const ne = businessesBounds.getNorthEast();
		const sw = businessesBounds.getSouthWest();
		return fitBounds(
			{
				ne: {
					lat: ne.lat,
					lng: ne.lng
				},
				sw: {
					lat: sw.lat,
					lng: sw.lng
				}
			},
			mapDimensions
		);
	}
}

export function businessLocation(business) {
	return (
		business.location || business._geoloc || { lat: 48.8757113, lng: 2.3321877 }
	);
}
