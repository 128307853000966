import {
	useAuth,
	useLastSuccessfulAppointment,
	Tabs,
	Localize,
	FirebaseSubscription
} from '@planity/components';
import {
	scrollTo,
	clickAndCollectAndShopFilter,
	giftVouchersFilter,
	MY_APPOINTMENTS_TAB,
	MY_PERSONAL_DATA_TAB,
	MY_GIFT_VOUCHERS_TAB,
	MY_CURES_TAB,
	MY_ORDERS_TAB,
	MY_CHILDREN_TAB
} from '@planity/helpers';
import credentials from '@planity/credentials';
import { useLocalizedRoutes, useTranslation } from '@planity/localization';
import {
	AccountTabs,
	AppointmentEmpty,
	Container,
	Spinner,
	useModal
} from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect, useMemo, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { OnlinePaymentProvider } from '../business_booking/online_payment/providerComponent';
import { ErrorMessage } from '../error_message';
import { PhoneVerificationForm } from '../phone_verification_form';
import { UserBuyingsList } from '../user_buying/';
import { CuresList } from '../user_buying/cures_list';
import { AppointmentConfirmation } from './appointment_confirmation';
import { AppointmentsList } from './appointments_list';
import { MyProfile } from './my_profile';
import { MyChildren } from '../my_account_page/my_children';
import { PastAppointments } from './past_appointments';
import styles from './signed_in.module.scss';
import { MY_ACCOUNT_BLOC, useTheme } from '@planity/context/theme_context';
import { getAppointmentDetails } from './appointment_preview/review';
import ReviewForm from './appointment_preview/review/review_form';
import { ThemeContext } from '@planity/context/theme_context';
import { useLocalizedSinglePageRoutes } from '@planity/localization';
import { useUserData } from '@planity/context';
import { format } from 'date-fns';
import { useAllChildren } from '../../helpers/hooks/use_all_children';

const now = format(new Date(), 'yyyy-MM-dd HH:mm');
const { USE_CURE_FOR_PAYMENTS_ENABLED } = credentials;

export const SignedIn = ({
	linkToBusiness,
	appointment,
	appointmentId,
	hasReviewError,
	customBookAnAppointment,
	linkToBookAppointment,
	linkToClickAndCollect,
	selectedTab: _selectedTab,
	reviewDataIsLoading
}) => {
	const businessId = window.planity.key;
	const { user, userId, isVerified } = useAuth();
	const { singlePageApp } = useContext(ThemeContext);
	const { routes: singlePageRoutes } =
		process.env.WHITE_LABEL_WEBSITE && useLocalizedSinglePageRoutes();
	const { lastSuccessfulAppointmentId, appointmentMoved } =
		useLastSuccessfulAppointment();
	const { routes } = useLocalizedRoutes();
	const history = useHistory();
	const { t } = useTranslation();
	useStyles(styles);
	const { isDark: hasDarkBackground } = useTheme();
	const isDark = hasDarkBackground?.[MY_ACCOUNT_BLOC];
	const { setModal } = useModal();

	const [selectedTab, setSelectedTab] = useState(_selectedTab);
	const [refetchChildren, setRefetchChildren] = useState(true);

	const { children, setChildren, loading } = useAllChildren(
		userId,
		user?.hasChildren && refetchChildren
	);

	// When parent update his profile info, it might have an impact on children info too
	// therefore we need to load again all of his children with a delay because DB is slow to udpate :(
	useEffect(() => {
		if (refetchChildren) return;
		const timer = setTimeout(() => {
			setRefetchChildren(true);
		}, 5000);
		return () => clearTimeout(timer);
	}, [refetchChildren]);

	const {
		hasLoaded,
		businessesAreLoading,
		loadMoreAppointments,
		allFutureAppointments: _allFutureAppointments,
		futureAppointments: _futureAppointments,
		pastAppointments,
		pastAppointmentsLoading,
		confirmedAppointment,
		userBuyings,
		userCures: _userCures,
		fetchUserData,
		pageSize
	} = useUserData();

	useEffect(() => {
		fetchUserData();
	}, []);
	const userCures = Object.values(_userCures || {}).sort(
		(a, b) => b.boughtAt - a.boughtAt
	);
	const hasCures = USE_CURE_FOR_PAYMENTS_ENABLED && userCures.length > 0;
	const _pastAppointments = Object.entries(pastAppointments || {})
		?.map(([id, appointment]) => ({ id, ...appointment }))
		?.sort((a, b) => new Date(b.start) - new Date(a.start));

	const hasPastAppointments = !!Object.keys(pastAppointments || {}).length;

	const futureAppointments = formatAppointments(_futureAppointments);
	const allFutureAppointments = formatAppointments(_allFutureAppointments);
	const userBuyingsFiltered = Object.entries(userBuyings || {}).reduce(
		(filtered, [id, buying]) => {
			if (buying.businessId === businessId) filtered[id] = buying;
			return filtered;
		},
		{}
	);
	const hasGiftUserBuyings =
		Object.keys(giftVouchersFilter(userBuyings))?.length > 0;
	const hasGiftUserBuyingsFiltered =
		Object.keys(giftVouchersFilter(userBuyingsFiltered))?.length > 0;
	const hasGiftVouchers =
		hasGiftUserBuyings || (process.env.WIDGET && hasGiftUserBuyingsFiltered);
	const hasOrdersUserBuyings =
		Object.keys(clickAndCollectAndShopFilter(userBuyings))?.length > 0;
	const hasOrdersUserBuyingsFiltered =
		Object.keys(clickAndCollectAndShopFilter(userBuyingsFiltered))?.length > 0;
	const hasOrders =
		hasOrdersUserBuyings ||
		(process.env.WIDGET && hasOrdersUserBuyingsFiltered);
	useEffect(() => {
		if (process.env.WHITE_LABEL_WEBSITE) return;
		if (!process.env.WIDGET) return window.scrollTo(0, 0);
		scrollTo({
			node:
				window.document.getElementById('planity-account') ||
				window.document.getElementById('planity')
		});
	}, []);

	useEffect(() => {
		if (!process.env.BROWSER || !process.env.WHITE_LABEL_WEBSITE || !hasLoaded)
			return;
		// used in single page minisite redirection - navigation to a tab
		const routeHashs = window.location?.hash.split('&');
		// for multi-page, we need the _selectedTab

		if (
			!hasOrdersUserBuyings &&
			[routeHashs[1], _selectedTab].includes(MY_ORDERS_TAB)
		) {
			setSelectedTab(MY_APPOINTMENTS_TAB);
			return;
		}
		if (
			!hasGiftUserBuyings &&
			[routeHashs[1], _selectedTab].includes(MY_GIFT_VOUCHERS_TAB)
		) {
			setSelectedTab(MY_APPOINTMENTS_TAB);
			return;
		}
		if (!hasCures && [routeHashs[1], _selectedTab].includes(MY_CURES_TAB)) {
			setSelectedTab(MY_APPOINTMENTS_TAB);
			return;
		}
		setSelectedTab(routeHashs[1] || _selectedTab);
	}, [hasLoaded, hasOrdersUserBuyings, hasGiftUserBuyings, _selectedTab]);

	useEffect(() => {
		if (
			!process.env.BROWSER ||
			!process.env.WIDGET ||
			process.env.WHITE_LABEL_WEBSITE
		)
			return;

		const tab = sessionStorage.getItem('userIsBuying') || MY_APPOINTMENTS_TAB;
		setSelectedTab(tab);
		sessionStorage.clear();
	}, []);

	useEffect(() => {
		if (!process.env.BROWSER || process.env.WIDGET || !hasLoaded) return;
		const sessionStorageTab = sessionStorage.getItem('tab');

		// We check if the user has the gift voucher tab or orders tab - if not, we set the appointments tab instead
		if (
			!hasOrdersUserBuyings &&
			[_selectedTab, sessionStorageTab].includes(MY_ORDERS_TAB)
		) {
			setSelectedTab(MY_APPOINTMENTS_TAB);
			return;
		}
		if (
			!hasGiftUserBuyings &&
			[_selectedTab, sessionStorageTab].includes(MY_GIFT_VOUCHERS_TAB)
		) {
			setSelectedTab(MY_APPOINTMENTS_TAB);
			return;
		}
		setSelectedTab(_selectedTab || sessionStorageTab);
	}, [hasGiftUserBuyings, hasOrdersUserBuyings, hasLoaded]);

	useEffect(() => {
		if (!reviewDataIsLoading && appointment && appointmentId) {
			setModal({
				key: appointment.key,
				content: (
					<FirebaseSubscription
						key={appointment.businessId}
						path={`businesses/${appointment.businessId}`}
						shard={'public'}
					>
						{({ data: business }) => (
							<ReviewForm
								appointmentId={appointmentId}
								appointmentInfo={appointment}
								business={business}
								itemReviewed={getAppointmentDetails(appointment, business, t)}
								pastReview={appointment?.review || null}
							/>
						)}
					</FirebaseSubscription>
				),
				isFullHeight: true,
				showBackBtnOnMobile: true,
				title: appointment?.review ? (
					<Localize text={'myAccount.appointment.updateReview'} />
				) : (
					<Localize text={'myAccount.appointment.leaveReview'} />
				)
			});
		}
	}, [reviewDataIsLoading, JSON.stringify(appointment), appointmentId]);

	const tabs = useMemo(
		() => [
			{
				tabName: MY_APPOINTMENTS_TAB,
				localizedText: 'myAccount.tabs.myAppointments'
			},
			{
				tabName: MY_PERSONAL_DATA_TAB,
				localizedText: 'myAccount.tabs.myPersonalData'
			},
			{
				tabName: MY_CHILDREN_TAB,
				localizedText: 'myAccount.tabs.myChildren'
			},
			...(hasGiftVouchers
				? [
						{
							tabName: MY_GIFT_VOUCHERS_TAB,
							localizedText: 'myAccount.tabs.myGiftCards'
						}
				  ]
				: []),
			...(hasCures
				? [
						{
							tabName: MY_CURES_TAB,
							localizedText: 'myAccount.tabs.myCures'
						}
				  ]
				: []),
			...(hasOrders
				? [
						{
							tabName: MY_ORDERS_TAB,
							localizedText: 'myAccount.tabs.myOrders'
						}
				  ]
				: [])
		],
		[hasCures, hasGiftVouchers, hasOrders]
	);

	function onAppointmentEmptyPress() {
		if (!process.env.WHITE_LABEL_WEBSITE) return history.push(routes.home);
		if (singlePageApp) return history.push(singlePageRoutes.bookAppointment);
		return history.push(routes.bookAppointment);
	}

	if (!hasLoaded || reviewDataIsLoading) {
		return (
			<div className={styles.spinner}>
				<Spinner />
			</div>
		);
	}

	return (
		<Container className={process.env.WIDGET ? undefined : 'columns'}>
			<Tabs.Wrapper currentId={selectedTab}>
				<AccountTabs isDark={isDark} tabs={tabs} />

				{hasReviewError && (
					<ErrorMessage error={'reviewForm.reviewDateHasPassed'} />
				)}
				<div className={process.env.WIDGET ? styles.widget : styles.right}>
					<Tabs.Panel id={MY_APPOINTMENTS_TAB}>
						<div className={styles.appointmentsContainer}>
							{(isVerified === null || isVerified === false) && (
								<PhoneVerificationForm />
							)}
							{!futureAppointments?.length &&
								!hasPastAppointments &&
								!Object.keys(confirmedAppointment || {})?.length && (
									<AppointmentEmpty
										emptyText={t('myAccount.noFutureAppointments')}
										localizedText={t('myAccount.bookAppointment')}
										title={t('myAccount.futurAppointmentTitle')}
										onPress={onAppointmentEmptyPress}
									/>
								)}
							<AppointmentConfirmation
								appointment={confirmedAppointment}
								appointmentId={lastSuccessfulAppointmentId}
								appointmentMoved={appointmentMoved}
								hasFutureAppointments={!!futureAppointments?.length}
								isDark={isDark}
								linkToBookAppointment={linkToBookAppointment}
								linkToBusiness={linkToBusiness}
								linkToClickAndCollect={linkToClickAndCollect}
							/>
							<AppointmentsList
								appointments={
									process.env.WIDGET
										? futureAppointments.filter(
												({ businessId: bId }) => businessId === bId
										  )
										: futureAppointments
								}
								customBookAnAppointment={customBookAnAppointment}
								linkToBookAppointment={linkToBookAppointment}
								linkToBusiness={linkToBusiness}
							/>
							<PastAppointments
								businessesAreLoading={businessesAreLoading}
								customBookAnAppointment={customBookAnAppointment}
								hasPastAppointments={hasPastAppointments}
								isDark={isDark}
								linkToBookAppointment={linkToBookAppointment}
								linkToBusiness={linkToBusiness}
								loadMoreAppointments={loadMoreAppointments}
								now={now}
								pageSize={pageSize}
								pastAppointments={_pastAppointments}
								pastAppointmentsLoading={pastAppointmentsLoading}
							/>
						</div>
					</Tabs.Panel>
					<Tabs.Panel id={MY_PERSONAL_DATA_TAB}>
						<OnlinePaymentProvider>
							<MyProfile
								allFutureAppointments={allFutureAppointments}
								futureAppointments={futureAppointments}
								isDark={isDark}
								isVerified={isVerified}
								key={userId}
								mode={'EDIT_PROFILE'}
								pastAppointments={_pastAppointments}
								setRefetchChildren={setRefetchChildren}
								user={user}
								userId={userId}
							/>
						</OnlinePaymentProvider>
					</Tabs.Panel>
					<Tabs.Panel id={MY_CHILDREN_TAB}>
						<MyChildren
							isLoadingChildren={loading}
							parentId={userId}
							setChildren={setChildren}
							userChildren={children}
						/>
					</Tabs.Panel>
					{!!hasGiftUserBuyings && (
						<Tabs.Panel id={MY_GIFT_VOUCHERS_TAB}>
							<UserBuyingsList
								filterByBusiness={process.env.WIDGET}
								linkToBusiness={linkToBusiness}
								userBuyings={
									businessId
										? giftVouchersFilter(userBuyingsFiltered)
										: giftVouchersFilter(userBuyings)
								}
							/>
						</Tabs.Panel>
					)}
					{hasCures && (
						<Tabs.Panel id={'myCures'}>
							<CuresList
								linkToBusiness={linkToBusiness}
								userCures={userCures}
							/>
						</Tabs.Panel>
					)}
					{!!hasOrdersUserBuyings && (
						<Tabs.Panel id={MY_ORDERS_TAB}>
							<UserBuyingsList
								filterByBusiness={process.env.WIDGET}
								linkToBusiness={linkToBusiness}
								userBuyings={
									businessId
										? clickAndCollectAndShopFilter(userBuyingsFiltered)
										: clickAndCollectAndShopFilter(userBuyings)
								}
							/>
						</Tabs.Panel>
					)}
				</div>
			</Tabs.Wrapper>
		</Container>
	);
};

function formatAppointments(appointments) {
	return Object.entries(appointments || {}).map(([id, appointment]) => ({
		id,
		...appointment
	}));
}
