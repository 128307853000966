import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './radio.module.scss';
import { noop } from '@planity/helpers';

export function Radio({
	label = '',
	text = noop,
	labelClassName,
	textClassName,
	type = 'radio',
	name = '',
	onChange = noop,
	isChecked,
	rightRadio,
	id
}) {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		radio: true,
		rightRadio
	});

	return (
		<label className={classes} onChange={onChange}>
			<input
				className={styles.input}
				type={type}
				name={name}
				checked={isChecked}
				onChange={onChange}
				id={id}
			/>
			<span id={`${id}-span`}></span>
			<div className={styles.content}>
				<span className={labelClassName ? labelClassName : styles.label}>
					{label}
				</span>
				{text && (
					<span className={textClassName ? textClassName : styles.text}>
						{text && typeof text === 'function' ? text() : text}
					</span>
				)}
			</div>
		</label>
	);
}
