import React from 'react';
import VoucherItem from './services_list/voucher_item';
import ServiceSet from '../book_appointment/steps/service_set';
import styles from './services_list/service_item.module.scss';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Localize } from '@planity/components';
import { useTheme } from '@planity/context';

export const ServiceSetDisplay = ({
	giftVouchers,
	business,
	servicesList,
	selectedServices,
	onSelectGiftVoucher,
	onSelectService,
	isDark
}) => {
	useStyles(styles);
	const cx = classNames.bind(styles);
	const classes = cx({
		bookingCart: true
	});
	const { isDarkThemeWidget } = useTheme();

	return (
		<div className={classes}>
			{giftVouchers && Object.keys(giftVouchers).length > 0 && (
				<>
					<span
						className={`${cx('title', {
							isDark,
							isDarkThemeWidget
						})}`}
					>
						<Localize text={'gift.selectGiftVoucher'} />
					</span>
					<div className={styles.card}>
						{Object.keys(giftVouchers).map((voucherId, index) => {
							const voucher = giftVouchers[voucherId];
							voucher.id = voucherId; // needed for buyGiftVoucher lambda
							return (
								<div className={`${styles.service}`} key={voucherId}>
									<VoucherItem
										business={business}
										index={index}
										isDark={isDark}
										selectedServices={selectedServices}
										voucher={voucher}
										onSelect={onSelectGiftVoucher}
									/>
								</div>
							);
						})}
					</div>
				</>
			)}
			{servicesList &&
				servicesList.length > 0 &&
				servicesList.map((serviceSet, index) => {
					return (
						<ServiceSet
							business={business}
							dispatch={onSelectService}
							initCollapseMobile
							isDark={isDark}
							isGiftVoucher
							key={serviceSet.id}
							serviceSet={serviceSet}
							setIndex={index}
						/>
					);
				})}
		</div>
	);
};
