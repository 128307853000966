import React, { forwardRef, useEffect, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import { BookAppointmentTitle } from '@planity/components';
import {
	BOOK_APPOINTMENT_BLOC,
	useTheme
} from '@planity/context/theme_context';
import AppointmentChosenDate from './chosen_date';
import AppointmentAvailabilities from './availabilities';
import scssStyles from './step.module.scss';
import { Notifications } from './notification';
import { useAvailabilities } from './use_availabilities';
import { checkHasAvailabilitiesBeforeAppointment } from './helpers';

function AppointmentDate(
	{
		appointment,
		business,
		businessId,
		dispatch,
		isPending,
		isPreview,
		userFilter,
		moveAppointment = false,
		location,
		onCalendarReady
	},
	ref
) {
	const { isDark: hasDarkBackground } = useTheme();
	const isDark = hasDarkBackground?.[BOOK_APPOINTMENT_BLOC];
	useStyles(scssStyles);
	const classes = classNames.bind(scssStyles)({
		date: true,
		topMarginTablet: true
	});

	const { steps, date } = appointment;

	const canChooseDate = !date && !steps.find(step => !step.serviceId);

	// !! Important hook to read and format availabilities from firebase !!
	const {
		availabilities,
		isLoading: isAvailabilitiesLoading,
		availabilitiesWithoutStale
	} = useAvailabilities({
		businessId,
		business,
		steps,
		isPreview,
		isAllowedToFetchData: appointment && !date
	});

	/**
	 * /!\ ⬇️START: WAITING LIST LOGIC FOR MOVE APPOINTMENT⬇️ /!\
	 */
	// appointment to move information
	const {
		hasWaitingListActivated,
		start: startDateToDelete,
		fromWaitingListMail
	} = location.state?.veventToDelete ?? {};

	/**
	 * check that there is availability before the date of the appointment
	 */
	const hasAvailabilitiesBeforeAppointment = useMemo(() => {
		return checkHasAvailabilitiesBeforeAppointment(
			availabilitiesWithoutStale,
			startDateToDelete
		);
	}, [availabilitiesWithoutStale, startDateToDelete]);

	/**
	 * /!\ ⬆️END: WAITING LIST LOGIC FOR MOVE APPOINTMENT⬆️ /!\
	 */

	useEffect(() => {
		if (onCalendarReady && availabilitiesWithoutStale !== null) {
			onCalendarReady();
		}
	}, [availabilitiesWithoutStale, onCalendarReady]);

	return date || canChooseDate ? (
		<div className={classes} ref={ref}>
			<BookAppointmentTitle
				args={{ date }}
				index={'2.'}
				isDark={isDark}
				text={'bookAppointment.dateTitle.'.concat(date ? 'date' : 'noDate')}
			/>
			{!date && (
				<Notifications
					fromWaitingListMail={fromWaitingListMail}
					isLoading={
						availabilitiesWithoutStale === null || isAvailabilitiesLoading
					} // to avoid glitch
					isMoveAppointment={moveAppointment}
					shouldDisplayNoAvailabilities={
						hasWaitingListActivated
							? !hasAvailabilitiesBeforeAppointment
							: false
					}
					startDateToDelete={startDateToDelete}
				/>
			)}
			{date ? (
				<AppointmentChosenDate
					date={date}
					dispatch={dispatch}
					isPending={isPending}
				/>
			) : (
				<AppointmentAvailabilities
					areLoading={isAvailabilitiesLoading}
					availabilities={availabilities}
					business={business}
					dispatch={dispatch}
					key={steps.map(s => s.serviceId).join(',')}
					userFilter={userFilter}
				/>
			)}
		</div>
	) : null;
}

// eslint-disable-next-line import/no-default-export
export default withRouter(forwardRef(AppointmentDate));
