// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gift_voucher_payment-modules_giftVoucher-i3bdI{margin-top:15px}.gift_voucher_payment-modules_giftVoucher-i3bdI .gift_voucher_payment-modules_error-csc5J{color:var(--danger-200)}.gift_voucher_payment-modules_giftVoucher-i3bdI .gift_voucher_payment-modules_spinner-rNvlj{display:flex;top:0;bottom:0;right:0;align-items:center}.gift_voucher_payment-modules_giftVoucher-i3bdI .gift_voucher_payment-modules_suffix-VWIgb{text-decoration:underline;text-underline-offset:5px;text-decoration-color:var(--grey-400);text-decoration-thickness:2px;font-weight:500}", "",{"version":3,"sources":["webpack://./../../packages/components/business_booking/online_payment/gift_voucher_payment.modules.scss"],"names":[],"mappings":"AAEA,gDACC,eAAA,CAEA,0FACC,uBAAA,CAGD,4FACC,YAAA,CACA,KAAA,CACA,QAAA,CACA,OAAA,CACA,kBAAA,CAGD,2FACC,yBAAA,CACA,yBAAA,CACA,qCAAA,CACA,6BAAA,CACA,eAAA","sourcesContent":["@use '@planity/ui/styles';\n\n.giftVoucher {\n\tmargin-top: 15px;\n\n\t.error {\n\t\tcolor: var(--danger-200);\n\t}\n\n\t.spinner {\n\t\tdisplay: flex;\n\t\ttop: 0;\n\t\tbottom: 0;\n\t\tright: 0;\n\t\talign-items: center;\n\t}\n\n\t.suffix {\n\t\ttext-decoration: underline;\n\t\ttext-underline-offset: 5px;\n\t\ttext-decoration-color: var(--grey-400);\n\t\ttext-decoration-thickness: 2px;\n\t\tfont-weight: 500;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"giftVoucher": "gift_voucher_payment-modules_giftVoucher-i3bdI",
	"error": "gift_voucher_payment-modules_error-csc5J",
	"spinner": "gift_voucher_payment-modules_spinner-rNvlj",
	"suffix": "gift_voucher_payment-modules_suffix-VWIgb"
};
module.exports = ___CSS_LOADER_EXPORT___;
