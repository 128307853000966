import { decodeMessageFromUserApp, isNativeApp } from '@planity/webview';
import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout } from '../layout';
import { withGoalEvents } from '@planity/helpers/analytics';
import {
	Faq,
	HomeCover,
	HomeGrowth,
	HomePress,
	HomeSlideshow,
	NativeAppLandingPage,
	Offers,
	Suggestions,
	RenderCountrySelectorModal,
	useModal
} from '@planity/ui';
import { useErrorMessages } from '@planity/components';
import { useTranslation, useLocalizedRoutes } from '@planity/localization';
import { Metadata } from './metadata';

export const LandingPage = withGoalEvents(
	({ publishLandingPageEvent, location }) => {
		const { t } = useTranslation();
		const { search } = location;
		const { routes } = useLocalizedRoutes();
		const history = useHistory();
		const { pushMessage } = useErrorMessages();
		const { setModal } = useModal();
		useEffect(() => {
			const searchParams = new URLSearchParams(search);
			const error = searchParams.get('error');
			if (error) {
				history.replace(routes.home, { error });
			}
		}, [search]);

		useEffect(() => {
			if (publishLandingPageEvent) {
				publishLandingPageEvent();
			}
		}, []);

		useEffect(() => {
			location?.state?.error &&
				pushMessage({
					message: t('gift.errors.CRITICAL_ERROR'),
					type: 'error'
				});
		}, [location?.state?.error]);

		const categoriesRef = useRef(null);
		const scrollToCategories = () => {
			if (categoriesRef.current && categoriesRef.current.focus) {
				categoriesRef.current.scrollIntoView({
					behavior: 'smooth',
					block: 'nearest'
				});
			}
		};

		useEffect(() => {
			if (isNativeApp) {
				const handleMessage = event => {
					const decodedMessage = decodeMessageFromUserApp(event.data);
					if (!decodedMessage) return;
					const { type } = decodedMessage;
					if (type === 'OPEN_COUNTRY_SELECTOR_MODAL') {
						return setModal({
							content: <RenderCountrySelectorModal />,
							title: null,
							isFullHeight: false
						});
					}
				};

				window.addEventListener('message', handleMessage);
				return () => window.removeEventListener('message', handleMessage);
			}
		}, []);

		return (
			<Layout
				headerIsCollapsible
				hideBreadcrumbs
				hideNavigationItems={isNativeApp}
				id={'home-container'}
				isDark
				isHomePage={!isNativeApp}
				showAppTeaser
				skipCoronavirusModal
			>
				<div>
					<Metadata />
					{isNativeApp ? (
						<NativeAppLandingPage categoriesRef={categoriesRef} />
					) : (
						<>
							<HomeCover scrollToCategories={scrollToCategories} />
							<HomeSlideshow categoriesRef={categoriesRef} />
							<HomePress />
							<HomeGrowth />
							<Offers />
							<Suggestions />
							<Faq />
						</>
					)}
				</div>
			</Layout>
		);
	}
);
