import { signOut } from '@planity/components';
import classNames from 'classnames/bind';
import React, { useContext, useEffect } from 'react';
import TabsContext from '@planity/components/tabs/context';
import { AccountTab } from '@planity/ui';
import styles from './account_tabs.module.scss';
import { useLocalizedRoutes, useTranslation } from '@planity/localization';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@planity/context';

export function AccountTabs({ tabs, isDark }) {
	useStyles(styles);
	const cx = classNames.bind(styles);
	const { current, setCurrent } = useContext(TabsContext);
	const { t } = useTranslation();
	const { routes } = useLocalizedRoutes();
	const history = useHistory();
	const { isDarkThemeWidget } = useTheme();

	useEffect(() => {
		if (process.env.BROWSER && !process.env.WIDGET) {
			sessionStorage.setItem('tab', current);
		}
	}, [current]);
	const signOutCallback = async () => {
		await signOut();
		// if the user is on the path myAccount/review-key and decides to log out,
		// we have to clear the path because if the user tries to log back in with another account,
		// the review-key will log him out of his account again to reconnect it to the one of the review-key
		history.replace(routes.myAccount);
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	return (
		<div
			className={cx({
				tabsWidget: process.env.WIDGET,
				isDark: isDark && process.env.WIDGET,
				tabs: !process.env.WIDGET,
				isDarkThemeWidget
			})}
		>
			<h2 className={styles.title} id={'navMyAccount'}>
				{t('nav.myAccount')}
			</h2>
			<nav>
				<ul className={`${styles.nav} planity_myAccount_nav`}>
					{tabs.map((tab, i) => (
						<AccountTab
							key={`tab-${i}`}
							{...tab}
							isCurrent={tab?.tabName === current}
							setCurrent={() => setCurrent(tab.tabName)}
							isDark={isDark}
							isDarkThemeWidget={isDarkThemeWidget}
						/>
					))}
					<div className={styles.separator} />
					<AccountTab
						key={`tab-${tabs.length}`}
						tabName={'signOut'}
						localizedText={'myAccount.myProfile.signOut'}
						onClick={signOutCallback}
						id='signOutNavButton'
					/>
				</ul>
			</nav>
		</div>
	);
}
