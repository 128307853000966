import { useTranslation } from '@planity/localization';
import { Card } from '@planity/ui';
import React from 'react';
import { AppointmentPreview } from './appointment_preview';
import chunk from 'lodash/chunk';

export function AppointmentsList({
	appointments,
	linkToBusiness,
	linkToBookAppointment,
	order = 'asc',
	isPast = false
}) {
	const { t } = useTranslation();

	if (!appointments?.length) return null;
	return (
		<Card
			hasTitleInside
			id={isPast ? 'past-appointments-title' : 'futur-appointment-title'}
			isVisibleMobile={false}
			title={
				isPast
					? t('myAccount.pastAppointments')
					: t('myAccount.futurAppointmentTitle')
			}
		>
			{chunk(
				appointments.sort((appointmentX, appointmentY) => {
					if (!appointmentY || !appointmentY.start) {
						return -1;
					}
					if (!appointmentX || !appointmentX.start) return 1;
					// If 2 appointments with exact same date, sort by key. Stupid, but deterministic
					if (order === 'asc') {
						return (
							appointmentX.start.localeCompare(appointmentY.start) ||
							appointmentX.id.localeCompare(appointmentY.id)
						);
					} else {
						return (
							appointmentY.start.localeCompare(appointmentX.start) ||
							appointmentY.id.localeCompare(appointmentX.id)
						);
					}
				}),
				5
			).map(page =>
				page.map((appointment, index) => (
					<AppointmentPreview
						appointment={appointment}
						appointmentId={appointment.id}
						appointmentIndex={index}
						bookingMethods={appointment?.bm}
						collapseAppointmentSteps
						disableOnlinePaymentInfo={
							Object.keys(appointment?.bm ?? {}).length > 0
						}
						isPast={isPast}
						key={appointment.id}
						linkToBookAppointment={linkToBookAppointment}
						linkToBusiness={linkToBusiness}
					/>
				))
			)}
		</Card>
	);
}
