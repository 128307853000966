import React, { useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './reset_password.module.scss';
import { Button, InputPassword, Icon, Spinner } from '@planity/ui';
import { useUikit } from '@planity/ui/shared/useUikit';
import { useTranslation } from '@planity/localization';
import { colors } from '@planity/theme';

export function ResetPassword({
	error,
	password,
	errorPassword,
	localizedText,
	pending,
	handleDispatchEvent,
	handleDispatchInputs,
	isPro
}) {
	const [passwordContainsLetter, setPasswordContainsLetter] = useState(false);
	const [
		passwordContainsNumberOrSpecialChar,
		setPasswordContainsNumberOrSpecialChar
	] = useState(false);
	const [passwordIsMinLength, setPasswordIsMinLength] = useState(false);

	const { t } = useTranslation();

	const validatePassword = password => {
		const containsLetter = /[a-zA-Z]/.test(password);
		const containsNumberOrSpecialChar = /[^a-zA-Z]/.test(password);
		const isMinLength = password.length >= 8;
		setPasswordContainsLetter(containsLetter);
		setPasswordContainsNumberOrSpecialChar(containsNumberOrSpecialChar);
		setPasswordIsMinLength(isMinLength);
	};

	const isPasswordValid = !isPro
		? true
		: passwordContainsLetter &&
		  passwordContainsNumberOrSpecialChar &&
		  passwordIsMinLength;

	useStyles(styles);

	const { toggle, toggleIcon } = useUikit();
	const classes = classNames.bind(styles)({
		resetPassword: true
	});

	const renderForm = (
		<div>
			{isPro === null ? (
				<Spinner className={styles.formLoader} />
			) : (
				<form className={styles.container} onSubmit={e => e.preventDefault()}>
					<h1 className={styles.title}>{localizedText.title}</h1>
					<p className={styles.text}>{localizedText.alert}</p>
					<div className={styles.fields}>
						<InputPassword
							id={'reset-password-comp__input-password'}
							autoFocus
							label={localizedText.label}
							isRequired
							name='password'
							icon={toggle ? 'Eye' : 'EyeOff'}
							value={password || ''}
							placeholder={localizedText.placeholder}
							onChange={password => {
								handleDispatchInputs(password);
								validatePassword(password);
							}}
							onToggleIcon={toggleIcon}
							toggle={toggle}
							error={errorPassword && error}
							errorMessage={errorPassword && localizedText.errorMessage}
						/>
						{isPro && (
							<ul>
								<li className={styles.passwordValidationItem}>
									<Icon
										color={
											passwordContainsLetter ? colors.success : colors.error
										}
										icon={
											passwordContainsLetter ? 'CheckCircle' : 'CloseCircle'
										}
										size={16}
										className={styles.passwordValidationIcon}
									/>
									{t('auth.passwordValidation.passwordContainsLetterMessage')}
								</li>
								<li className={styles.passwordValidationItem}>
									<Icon
										color={
											passwordContainsNumberOrSpecialChar
												? colors.success
												: colors.error
										}
										icon={
											passwordContainsNumberOrSpecialChar
												? 'CheckCircle'
												: 'CloseCircle'
										}
										size={16}
										className={styles.passwordValidationIcon}
									/>
									{t(
										'auth.passwordValidation.passwordContainsNumberOrSpecialCharMessage'
									)}
								</li>
								<li className={styles.passwordValidationItem}>
									<Icon
										color={passwordIsMinLength ? colors.success : colors.error}
										icon={passwordIsMinLength ? 'CheckCircle' : 'CloseCircle'}
										size={16}
										className={styles.passwordValidationIcon}
									/>
									{t('auth.passwordValidation.passwordIsMinLengthMessage')}
								</li>
							</ul>
						)}
					</div>
					<div className={styles.actions}>
						<Button
							id={'reset-password-comp__submit'}
							isFullMobile
							label={localizedText.submit}
							onClick={event =>
								handleDispatchEvent({ event: event, type: 'resetPassword' })
							}
							isFullWidth
							isLoading={pending}
							isDisabled={pending || !isPasswordValid}
						/>
					</div>
				</form>
			)}
		</div>
	);

	return (
		<div id='reset-password-comp' className={classes}>
			{renderForm}
		</div>
	);
}
