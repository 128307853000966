// noinspection SpellCheckingInspection

// 🇫🇷
export const frFRGlobalRoutes = {
	becomePartner: 'https://info.planity.com/devenez-partenaire',
	complainRejectedUserReview: '/contestation-avis-client',
	home: '/',
	myAccount: '/mon-compte',
	myAccountPastAppointments: '/mon-compte/mes-rdv-passes',
	myAccountOrders: '/mon-compte/commandes',
	myAccountGiftCards: '/mon-compte/cartes-cadeau',
	myAccountCures: '/mon-compte/cures',
	myAccountInformation: '/mon-compte/informations',
	myAccountChildren: '/mon-compte/mes-proches',
	searchBusiness: '/search-business',
	searchPlace: '/search-place',

	unmovableAppointment: '/rendez-vous-non-modifiable',
	resetPassword: '/reset-password',
	// Acutally, it's useless, if you follow the props, you'll see that it's totally ignored
	forgottenPassword: '/forgotten-password',
	offer: '/offrir',
	giftVoucher: '/cartes-cadeaux',
	onlineShopSlug: '/boutique-en-ligne',
	buyProducts: '/commander', // White label website,
	careers: 'https://careers.planity.com/',
	legal: '/mentions-legales',
	cookies: '/cookies',
	cookiesManagement: '/gestion-des-cookies',
	// those routes exist only for /de-DE, you can find some information here 👇
	// https://www.notion.so/planity/Pages-annexes-mais-quand-m-me-importantes-2629414f0b2e4d2da83011cf3cf800b2
	impressum: null,
	cookiesPolicy: null,
	privacyPolicy: '/politique-de-confidentialite',
	privacyPolicyAsHTML: '/politique-de-confidentialite-html',
	illicitContentPolicy: '/politique-de-contenu-illicite',
	bookAppointment: '/réserver' // White label website
};

// 🇩🇪
export const deDEGlobalRoutes = {
	becomePartner: 'https://info.planity.com/de-de/partner',
	becomePartnerBasePath: 'https://info.planity.com/de-de/partner',
	complainRejectedUserReview: '/de-DE/einwand-kundenbewertung',
	mag: 'https://www.mag.planity.de/',
	home: '/de-DE',
	myAccount: '/de-DE/mein-konto',
	myAccountPastAppointments: '/de-DE/mein-konto/meine-letzten-termine',
	myAccountOrders: '/de-DE/mein-konto/bestellt',
	myAccountGiftCards: '/de-DE/mein-konto/geschenkkarte',
	myAccountCures: '/de-DE/mein-konto/heilmittel',
	myAccountInformation: '/de-DE/mein-konto/information',
	myAccountChildren: '/de-DE/mein-konto/meine-angehoerigen',
	searchBusiness: '/de-DE/search-business',
	searchPlace: '/de-DE/search-place',

	unmovableAppointment: '/de-DE/nicht-veranderbarer-Termin',
	resetPassword: '/de-DE/reset-password',
	// Acutally, it's useless, if you follow the props, you'll see that it's totally ignored
	forgottenPassword: '/de-DE/forgotten-password',
	offer: '/de-DE/offrir',
	giftVoucher: '/de-DE/cartes-cadeaux',
	onlineShopSlug: '/de-DE/boutique-en-ligne',
	buyProducts: '/de-DE/commander',
	careers: 'https://careers.planity.com/de-inf',
	legal: '/de-DE/legal',
	cookies: '/de-DE/cookies',
	cookiesManagement: '/de-DE/cookie-verwaltung',
	impressum: '/de-DE/impressum',
	cookiesPolicy: '/de-DE/cookies-policy',
	privacyPolicy: '/de-DE/privacy-policy',
	bookAppointment: '/online-terminbuchung', // White label website
	confidentialityPolicy: '/de-DE/datenschutzerklärung'
};

// 🇧🇪🇫🇷
export const frBEGlobalRoutes = {
	becomePartner: 'https://info.planity.com/fr-be/devenez-partenaire',
	complainRejectedUserReview: '/fr-BE/contestation-avis-client',
	home: '/fr-BE',
	myAccount: '/fr-BE/mon-compte',
	myAccountPastAppointments: '/fr-BE/mon-compte/mes-rdv-passes',
	myAccountOrders: '/fr-BE/mon-compte/commandes',
	myAccountGiftCards: '/fr-BE/mon-compte/cartes-cadeau',
	myAccountCures: '/fr-BE/mon-compte/cures',
	myAccountInformation: '/fr-BE/mon-compte/informations',
	myAccountChildren: 'fr-BE/mon-compte/mes-proches',
	cookies: '/fr-BE/cookies',
	cookiesManagement: '/fr-BE/gestion-des-cookies',
	searchBusiness: '/fr-BE/search-business',
	searchPlace: '/fr-BE/search-place',

	unmovableAppointment: '/fr-BE/rendez-vous-non-modifiable',
	resetPassword: '/fr-BE/reset-password',
	// Acutally, it's useless, if you follow the props, you'll see that it's totally ignored
	forgottenPassword: '/fr-BE/forgotten-password',
	offer: '/fr-BE/offrir',
	giftVoucher: '/fr-BE/cartes-cadeaux',
	onlineShopSlug: '/fr-BE/boutique-en-ligne',
	buyProducts: '/fr-BE/commander', // White label website,
	careers: 'https://careers.planity.com/',
	legal: '/fr-BE/mentions-legales',
	// those routes exist only for /de-DE, you can find some information here 👇
	// https://www.notion.so/planity/Pages-annexes-mais-quand-m-me-importantes-2629414f0b2e4d2da83011cf3cf800b2	impressum: null,
	impressum: null,
	cookiesPolicy: null,
	privacyPolicy: '/fr-BE/politique-de-confidentialite',
	illicitContentPolicy: '/fr-BE/politique-de-contenu-illicite',
	bookAppointment: '/réserver' // White label website
};

// 🇧🇪🇳🇱
export const nlBEGlobalRoutes = {
	becomePartner: 'https://info.planity.com/nl-be',
	complainRejectedUserReview: '/nl-BE/klacht-klantenbeoordeling',

	home: '/nl-BE',

	myAccount: '/nl-BE/mijn-account',
	myAccountPastAppointments: '/nl-BE/mijn-account/mijn-afgelopen-afspraken',
	myAccountOrders: '/nl-BE/mijn-account/bestellingen',
	myAccountGiftCards: '/nl-BE/mijn-account/cadeaubonnen',
	myAccountCures: '/nl-BE/mijn-account/geneest',
	myAccountInformation: '/nl-BE/mijn-account/informaties',
	myAccountChildren: '/nl-BE/mijn-account/mijn-gezinsleden',
	cookies: '/nl-BE/cookies',
	cookiesManagement: '/nl-BE/beheer-van-cookies',
	searchBusiness: '/nl-BE/search-business',
	searchPlace: '/nl-BE/search-place',

	unmovableAppointment: '/nl-BE/afspraak-niet-wijzigbaar',
	resetPassword: '/nl-BE/reset-password',
	// Acutally, it's useless, if you follow the props, you'll see that it's totally ignored
	forgottenPassword: '/nl-BE/forgotten-password',
	offer: '/nl-BE/offrir', // ???
	giftVoucher: '/nl-BE/cartes-cadeaux', // ???
	onlineShopSlug: '/nl-BE/boutique-en-ligne', // ???
	buyProducts: '/nl-BE/commander', // White label website, // ???

	careers: 'https://careers.planity.com/nl', // ???

	legal: '/nl-BE/wettelijk-vermeldingen',

	// those routes exist only for /de-DE, you can find some information here 👇
	// https://www.notion.so/planity/Pages-annexes-mais-quand-m-me-importantes-2629414f0b2e4d2da83011cf3cf800b2
	impressum: null,
	cookiesPolicy: null,
	privacyPolicy: '/nl-BE/privacybeleid',
	bookAppointment: 'reserveren'
};

export const globalRoutes = {
	'fr-BE': frBEGlobalRoutes,
	'fr-FR': frFRGlobalRoutes,
	'de-DE': deDEGlobalRoutes,
	'nl-BE': nlBEGlobalRoutes
};
